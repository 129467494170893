<template>
  <div class="w-full pa-0 flex items-center">
    <template v-if="!editing">
       <div class="w-full flex items-center" @click="toggleEdit">
        <!-- <slot name="renderer" v-bind:props="{ on: toggleEdit.bind(this), text }"> -->
          {{ text }}
        <!-- </slot> -->
      </div>
    </template>
    <template v-else>
      <v-text-field
        v-model="tempText"
        autofocus
        placeholder="Type something..."
        :style="inputStyles"
        @blur="editing = false"
        @keypress.esc="editing = false"
        @keypress.enter="onSave">
      </v-text-field>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EditableSimpleText',

  props: {
    text: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      editing: false,
      tempText: this.text
    }
  },

  computed: {
    inputStyles() {
      return {
        minWidth: `${this.tempText.length}ch`
      }
    }
  },

  methods: {
    onCancel() {
      // this.text = this.tempText;
      this.editing = false;
    },

    onSave() {
      this.editing = false;
      this.$emit('save', this.tempText);
    },

    toggleEdit() {
      this.editing = true
    }
  },

  watch: {
    editing: function(newValue) {
      if (newValue) {
        this.tempText = this.text;
      }
    }
  }
}
</script>


<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :min-width="380"
    :max-height="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          small
          icon
          outlined
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-menu-down-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <note-folder-selector
      @close="close"
      @select-folder="$emit('select-folder', $event)">
    </note-folder-selector>
  </v-menu>
</template>

<script>
import NoteFolderSelector from './NoteFolderSelector.vue'

export default {
  name: 'NoteFolderSelectorMenu',

  components: { NoteFolderSelector },

  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    close() {
      this.showMenu = false
    }
  }
}
</script>
<template>
  <div
    class="mockup-element absolute flex items-center"
    :style="boxStyles"
    >
    <div
      ref="element"
      v-html="html"
      >
    </div>
    <div
      :class="[
        'absolute top-0 bottom-0 left-0 right-0 w-full h-full',
        selected ? 'bg-blue-100/50' : 'bg-transparent'
        ]"
        @click.stop.prevent="onSelect"
        @dblclick.stop.prevent="onTriggerEdit">
    </div>
  </div>
</template>

<script>
import 'wired-elements'
import Vue from 'vue'
import { getWidgetType } from './WidgetTypes'

export default {
  name: 'MockupElement',

  props: {
    element: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      selected: false,
      html: ''
    }
  },

  computed: {
    boxStyles() {
      let geom = this.element.geom
      return {
        top: `${geom.y}px`,
        left: `${geom.x}px`
      }
    },

    elStyles() {
      let geom = this.element.geom
      return {
        width: `${geom.w}px`,
        height: `${geom.h}px` 
      }
    },

    widgetType() {
      return getWidgetType(this.element.type)
    },

    // html() {
    //   let holder = document.createElement('div')
    //   let widgetType = this.widgetType
    //   let Comp = Vue.extend({
    //     props: widgetType.props,
    //     render: function(h) { return widgetType.render(h, this) }
    //   })
    //   let inst = new Comp({
    //     propsData: {
    //       width: this.element.geom.w,
    //       height: this.element.geom.h
    //     }
    //   })
    //   inst.$mount()
    //   holder.appendChild(inst.$el)
    //   return holder.innerHTML
    // }
  },

  methods: {
    onSelect(event) {
      this.$emit('select', this.element, this, !!event.ctrlKey)
    },

    onTriggerEdit() {
      this.$emit('edit', {
        element: this.element,
        el: this.$el
      })
    },

    renderHTML() {
      let holder = document.createElement('div')
      let widgetType = this.widgetType
      let Comp = Vue.extend({
        props: widgetType.props,
        render: function(h) { return widgetType.render(h, this) }
      })
      let inst = new Comp({
        propsData: {
          width: this.element.geom.w,
          height: this.element.geom.h
        }
      })
      inst.$mount()
      holder.appendChild(inst.$el)
      Vue.set(this, 'html', holder.innerHTML)
    }
  },

  mounted() {
    this.$emit('bind', this.element, this)
  },

  watch: {
    element: {
      deep: true,
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.renderHTML()
        }
      }
    }
  }
}
</script>
<template>
  <v-menu
    ref="menu"
    v-model="showMenu"
    :close-on-content-click="false"
    :min-width="300"
    :max-width="500">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          icon
          small
          outlined
          v-on="on">
          <v-icon>mdi-menu-down-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <label-selector
      @close="close"
      v-model="selectedLabels"
      >
    </label-selector>
  </v-menu>
</template>

<script>
import LabelSelector from "./LabelSelector.vue"

export default {
  name: 'LabelSelectorMenu',

  components: { LabelSelector },

  props: {
    selectedLabels: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    close() {
      this.showMenu = false
      this.$emit('close')
    }
  }
}
</script>
<template>
  <div
    id="page-canvas"
    class="overflow-auto w-full h-full relative p-0"
    v-click-outside="clearRubberBand"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
    @mouseup="onMouseUp"
    >
    <page
      ref="page"
      id="mockup-page"
      :page="page"
      @select-element="selectElement"
      @edit-element="editElement"
      @bind-element="bindElement"
      @bind-page="bindPage">
    </page>
    <rubber-band
      :rubber-band="rubberBand"
      :scale="scale"
      >
    </rubber-band>
    <selection-box
      ref="selectionBox"
      :selected-elements="selectedElementRecords"
      :scale="scale"
      @move-elements="$emit('move-elements', $event)"
      @resize-elements="$emit('resize-elements', $event)"
      @start-move-elements="movingElements = true"
      @stop-move-elements="movingElements = false"
      >
    </selection-box>
    <text-edit
      ref="textEdit"
      :editing-element="editingElement"
      @done-edit="doneEditText"
      >
    </text-edit>
  </div>
</template>

<script>
import _ from 'lodash'
import Page from './Page.vue'
import SelectionBox from './SelectionBox.vue'
import RubberBand from './RubberBand.vue'
import TextEdit from './TextEdit.vue'

const MousePosKeys = [
  'clientX', 'clientY',
  'pageX', 'pageY',
  'offsetX', 'offsetY',
  'screenX', 'screenY'
]

export default {
  name: 'PageCanvas',

  components: {
    Page,
    SelectionBox,
    RubberBand,
    TextEdit
  },

  props: {
    page: {
      type: Object,
      default: () => {
        return null
      }
    },
    scale: {
      type: Number,
      default: 1.0
    }
  },

  data() {
    return {
      rubberBand: {
        active: false,
        rect: {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        },
        boundingRect: null
      },
      editingElement: null,
      selectedElementRecords: [],
      mousePos: {
        down: {
          clientX: 0,
          clientY: 0,
          offsetX: 0,
          offsetY: 0,
          pageX: 0,
          pageY: 0,
          screenX: 0,
          screenY: 0
        }
      },
      moveBox: null,
      movingElements: false,
      bindings: {
        page: null,
        elements: {}
      }
    }
  },

  methods: {
    selectElement(element, el, multiple = false) {
      let existed = _.findIndex(this.selectedElementRecords, x => x.element.id == element.id) > -1
      if (multiple) {
        // multiple selection
        if (!existed) {
          this.selectedElementRecords.push({
            element,
            el
          })
        }
      } else {
        if (!existed) {
          this.clearSelection()
          this.selectedElementRecords.push({
            element,
            el
          })
        }
      }
    },

    editElement(event) {
      let { element, el } = event
      this.editingElement = {
        element,
        el
      }
    },

    doneEditText() {
      this.editingElement = null
    },

    clearSelection(event) {
      console.log('clear selection')
      if (!this.movingElements) {
        this.selectedElementRecords.forEach(record => {
          record.el.selected = false
        })
        this.selectedElementRecords.splice(0, this.selectedElementRecords.length)
      }
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
    },

    clearRubberBand() {
      this.rubberBand.active = false
    },

    onMouseDown(event) {
      MousePosKeys.forEach(key => {
        this.mousePos.down[key] = event[key]
      })
      this.clearSelection()
      this.rubberBand.active = true
      this.rubberBand.boundingRect = this.$el.getBoundingClientRect()
    },

    onMouseMove(event) {
      if (!this.movingElements && this.rubberBand.active) {
        let clientX = event.clientX
        let clientY = event.clientY
        Object.assign(this.rubberBand.rect, {
          top: Math.min(this.mousePos.down.clientY, clientY) - this.rubberBand.boundingRect.top,
          left: Math.min(this.mousePos.down.clientX, clientX) - this.rubberBand.boundingRect.left,
          width: Math.abs( this.mousePos.down.clientX - clientX ),
          height: Math.abs( this.mousePos.down.clientY - clientY )
        })
      }
    },

    onMouseUp() {
      if (!this.movingElements && this.rubberBand.active) {
        let rect = this.rubberBand.rect
        let rubberBandClientRect = {
          top: rect.top + this.rubberBand.boundingRect.top,
          left: rect.left + this.rubberBand.boundingRect.left,
          bottom: rect.top + this.rubberBand.boundingRect.top + rect.height,
          right: rect.left + this.rubberBand.boundingRect.left + rect.width
        }
        let elementBindings = this.bindings.elements
        Object.values(elementBindings).forEach(binding => {
          let { element, el } = binding
          let r = el.$el.getBoundingClientRect()
          if (r.top > rubberBandClientRect.top &&
            r.left > rubberBandClientRect.left &&
            r.right < rubberBandClientRect.right && 
            r.bottom < rubberBandClientRect.bottom ) {
              this.selectElement(element, el, true)
            }
        })
        this.rubberBand.active = false
        this.rubberBand.rect.width = 0
        this.rubberBand.rect.height = 0
      }
    },

    bindPage(el) {
      this.bindings.page = el
    },

    bindElement(element, el) {
      this.bindings.elements[element.id] = {
        element,
        el
      }
    }
  }
}
</script>

<style scoped>

::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #bbb;
  width: 6px;
}

</style>
<template>
  <v-list class="pl-4">
    <v-list-item
      dense
      link
      class="pl-4"
      @click.stop.prevent="$emit('select', 'document')">
      <v-list-item-icon>
        <v-icon>mdi-note-multiple-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Create Full Document Note</v-list-item-title>
    </v-list-item>
    <v-subheader class="py-0 my-0">
      Create Snippet Note
    </v-subheader>
    <v-container class="pt-0 pb-8">
      <v-row>
        <v-col cols="6">
          <template v-for="(item, index) in noteTypes">
            <v-list-item
              :key="index"
              dense
              class="pl-2"
              v-if="index % 2 == 0"
              @click.stop.prevent="$emit('select', item.key)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-col>
        <v-col cols="6">
          <template v-for="(item, index) in noteTypes">
            <v-list-item
              :key="index"
              dense
              class="pl-2"
              v-if="index % 2 == 1"
              @click.stop.prevent="$emit('select', item.key)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-list>
</template>

<script>
import NoteTypes from './NoteTypes'

export default {
  name: 'NoteNewSelector',

  computed: {
    noteTypes() {
      return NoteTypes
    }
  }
}
</script>

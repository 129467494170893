<template>
  <div class="relative flex flex-col space-y-0 w-full h-full px-2">
    <draggable
      v-model="blocks"
      tag="div"
      handle=".block-handle"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false">
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <template v-for="(block, index) in blocks">
          <block
            :key="block.id"
            :index="index"
            :block="block"
            v-on="{
              toggleBlockActions: toggleBlockActions,
              toggleBlockSelector: toggleBlockSelector
            }">
          </block>
        </template>
      </transition-group>
    </draggable>
    <div
      ref="blockInput"
      tabindex="0"
      contenteditable
      data-placeholder="Type / to add new block"
      class="block-indicator w-full outline-none px-14"
      @keydown="onIndicatorKeyDown">
    </div>
    <div
      v-if="selectedText"
      class="absolute p-1 bg-white rounded-lg shadow-md flex flex-row items-center space-x-1"
      :style="{
        top: `${selectedTextPosition.top}px`,
        left: `${selectedTextPosition.left}px`
      }">
      <v-btn icon small @click.stop.prevent="formatSelectedText('bold')">
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('italic')">
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('linethrough')">
        <v-icon>mdi-format-strikethrough-variant</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('underline')">
        <v-icon>mdi-format-underline</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('background')">
        <v-icon>mdi-format-color-fill</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('text')">
        <v-icon>mdi-format-color-highlight</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('subscript')">
        <v-icon>mdi-format-subscript</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('superscript')">
        <v-icon>mdi-format-superscript</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('link')">
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="formatSelectedText('code')">
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
      <v-btn icon small>
        <v-icon>mdi-format-annotation-plus</v-icon>
      </v-btn>
      <v-btn icon small @click.stop.prevent="clearTextFormats()">
        <v-icon>mdi-format-clear</v-icon>
      </v-btn>
    </div>
    <v-menu
      ref="blockSelector"
      :activator="blockSelectorActivator"
      v-model="showBlockSelector"
      :close-on-content-click="false"
      offset-y
      max-width="300px">
      <v-card
        class="w-full h-full overflow-hidden"
        style="max-height: 90vh"
        >
        <div class="flex flex-row items-center">
          <v-spacer></v-spacer>
          <v-btn small icon @click.stop.prevent="showBlockSelector = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-card-text
          class="w-full flex flex-col my-0 py-0"
          style="min-height: 480px; max-height:calc(100% - 48px);">
          <div class="flex shrink">
            <v-text-field
              dense
              filled
              rounded
              hide-details
              no-hint
            ></v-text-field>
          </div>
          <div
            class="w-full flex flex-col overflow-y-auto grow">
            <v-list class="w-full">
              <template v-for="(item, index) in noteTypes">
                <v-list-item
                  :key="index"
                  dense
                  class="pl-2"
                  @click.stop.prevent="onAddBlock(item)">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu
      ref="blockActions"
      :activator="blockActionsActivator"
      v-model="showBlockActions"
      :close-on-content-click="false"
      offset-y>
      <div class="flex flex-row bg-white p-1">
        <v-btn icon @click.stop.prevent="onMoveBlock('up')">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn icon @click.stop.prevent="onMoveBlock('down')">
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
        <v-btn icon @click.stop.prevent="onRemoveBlock">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import NoteTypes from '../NoteTypes'
import Block from './Block.vue'

const getParentElement = (n) => {
  let node = n
  while (node && node.nodeType !== Node.ELEMENT_NODE) {
    node = node.parentNode
  }
  return node
}

const compatible = (n1, n2) => {
  if (n1.nodeType == Node.TEXT_NODE
    && n2.nodeType == Node.TEXT_NODE ) {
    return true
  }

  if (n1.nodeType == Node.TEXT_NODE
    && n2.nodeType == Node.ELEMENT_NODE
    && n2.classList.length == 0) {
    return true
  }

  if (n2.nodeType == Node.TEXT_NODE
    && n1.nodeType == Node.ELEMENT_NODE
    && n1.classList.length == 0) {
    return true
  }

  if (n1.nodeType == Node.ELEMENT_NODE
   && n2.nodeType == Node.ELEMENT_NODE) {
     if (n1.tagName == n2.tagName) {
       if (n1.classList.length == n2.classList.length) {
         for (let i = 0; i < n1.classList.length; ++i) {
           let key = n1.classList[i]
           if (!n2.classList.contains(key)) {
             return false
           }
         }
         return true
       }
     }
  }

  return false
}

export default {
  name: 'BlockEditor',

  components: {
    draggable,
    Block
  },

  data() {
    return {
      blocks: [
        { id: 1, type: 'block-paragraph', content: 'Finally what to do now is beyond me' },
        { id: 2, type: 'block-paragraph', indent: 1, content: 'Hello world 2, I am right here!' },
        { id: 3, type: 'block-paragraph', indent: 1, content: 'hello world 3' },
        { id: 4, type: 'block-paragraph', content: 'hello world 4' },
      ],
      editing: true,
      drag: false,
      selectedText: '',
      selectedTextPosition: {},
      activeBlock: null,
      activeBlockIndex: -1,
      showBlockSelector: false,
      blockSelectorActivator: null,
      showBlockActions: false,
      blockActionsActivator: null,
      formatActions: {
        bold: { tag: 'span', class: ['font-bold'] },
        italic: { tag: 'span', class: ['italic'] },
        underline: { tag: 'span', class: ['underline'] },
        linethrough: { tag: 'span', class: ['line-through'] },
        shadow: { tag: 'span', class: ['text-shadow-md'] },
        link: { tag: 'a', class: ['bg-red-400', 'whole-select'] },
        code: { tag: 'span', class: ['bg-blue-400'] },
        background: { tag: 'span', class: ['bg-pink-400'] },
        text: { tag: 'span', class: ['bg-purple-400'] },
        annotation: { tag: 'span', class: [] },
        subscript: { tag: 'sub', class: ['whole-select'] },
        superscript: { tag: 'sup', class: ['whole-select'] },
        math: { tag: 'div', class: ['whole-select'] }
      }
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    noteTypes() {
      return NoteTypes
    }
  },

  methods: {
    onIndicatorKeyDown(event) {
      switch(event.code) {
        case 'Slash':
          this.showBlockSelector = true
          this.blockSelectorActivator = this.$refs.blockInput
          break
        
        default:
          this.showBlockSelector = false
          this.blockSelectorActivator = null
          break
      }
    },

    toggleBlockSelector(block, index, event) {
      this.blockSelectorActivator = event.target
      this.activeBlock = block
      this.activeBlockIndex = index
      this.$nextTick(() => {
        this.showBlockSelector = true
      })
    },

    toggleBlockActions(block, index, event) {
      this.blockActionsActivator = event.target
      this.activeBlock = block
      this.activeBlockIndex = index
      this.$nextTick(() => {
        this.showBlockActions = true
      })
    },

    onTextSelectionChange() {
      let selection = window.getSelection()
      let selectedText = selection.toString()
      if (!selection.isCollapsed && selectedText && this.$el) {
        let range = selection.getRangeAt(0)
        let element = getParentElement(range.startContainer)
        if (!element) {
          return
        }
        let editableElement = element.closest('.editable')
        if (!editableElement) {
          return
        }

        this.selectedText = selectedText
        let selectionRect = range.getBoundingClientRect()
        let viewRect = this.$el.getBoundingClientRect()
        let top = selectionRect.top - viewRect.top + this.$el.scrollTop
        let left = selectionRect.left - viewRect.left + this.$el.scrollLeft
        let toolbarHeight = 38
        let threshold = 8
        if (top - toolbarHeight < threshold) {
          top = top + selectionRect.height + threshold
        } else {
          top = top - toolbarHeight - threshold
        }
        this.selectedTextPosition = { top, left }
      } else {
        this.selectedText = ''
      }
    },

    isWholeSelectElement(node) {
      return node.classList.contains('whole-select')
    },

    trySelectWholeNode (range, node) {
      if (node.nodeType == Node.ELEMENT_NODE &&
        this.isWholeSelectElement(node)) {
        range.selectNode(node)
      }
    },

    formatSelectedText(action) {
      // loop over child nodes
      if (!(action in this.formatActions)) {
        console.warn('Action not defined')
        return
      }

      let selection = window.getSelection()
      let range = selection.getRangeAt(0)
      if (range.collapsed) {
        return
      }

      let { startContainer, endContainer } = range
      this.trySelectWholeNode(range, startContainer)
      this.trySelectWholeNode(range, endContainer)

      let contents = range.cloneContents()
      let wrapInfo = this.formatActions[action]
      const fragment = new DocumentFragment()
      let prevNode = null
      for (let i = contents.childNodes.length - 1; i >= 0; i--) {
        let node = contents.childNodes[i]
        if (node.nodeType == Node.TEXT_NODE) {
          let newNode = document.createElement(wrapInfo.tag)
          wrapInfo.class.forEach(key => {
            newNode.classList.add(key)
          })
          newNode.appendChild(node)
          fragment.insertBefore(newNode, prevNode)
          prevNode = newNode
        } else if (node.nodeType == Node.ELEMENT_NODE) {
          wrapInfo.class.forEach(key => {
            if (!node.classList.contains(key)) {
              node.classList.add(key)
            }
          })
          fragment.insertBefore(node, prevNode)
          prevNode = node
        } else {
          console.warn('should not be here')
        }
      }
      range.extractContents()
      range.insertNode(fragment)

      this.mergeTextFragments()
    },

    clearTextFormats(format) {
      let sel = window.getSelection()
      let range = sel.getRangeAt(0)
      let { startContainer, endContainer } = range
      let startElement = getParentElement(startContainer)
      let endElement = getParentElement(endContainer)

      if (!startElement || !endElement) {
        console.warn('Not a valid element')
        return
      }

      let elements = []
      if (startElement.classList.contains('editable')) {
        for (let i = 0; i < startElement.children.length; ++i) {
          let el = startElement.children[i]
          elements.push(el)
          if (el.isEqualNode(endElement)) {
            break
          }
        }
      } else {
        while (startElement) {
          elements.push(startElement)
          if (startElement.isEqualNode(endElement)) {
            break
          }
          startElement = startElement.nextElementSibling
        }
      }

      if (format) {
        elements.forEach(el => {
          if (el.classList.contains(format)) {
            el.classList.remove(format)
          }
        })
      } else {
        console.log('clear out')
        elements.forEach(el => {
          console.log(el.classList)
          el.classList.remove(...el.classList)
        })
      }

      console.log(elements)
      this.mergeTextFragments()
    },

    mergeTextFragments() {
      let selection = window.getSelection()
      let range = selection.getRangeAt(0)
      let el = getParentElement(range.startContainer)
      let editableEl = el.closest('.editable')

      // clear out
      for (let i = editableEl.childNodes.length - 1; i > -1; i--) {
        let node = editableEl.childNodes[i]
        if (node.nodeType == Node.TEXT_NODE && !node.nodeValue) {
          editableEl.removeChild(node)
        }
      }

      // loop over all children nodes to merge
      // nodes with the same formats
      for (
        let start = 0, end = 1; 
        start < editableEl.childNodes.length, end < editableEl.childNodes.length;
        )
      {
        let startNode = editableEl.childNodes[start]
        let endNode = editableEl.childNodes[end]
        if (compatible(startNode, endNode)) {
          startNode.textContent += endNode.textContent
          editableEl.removeChild(endNode)
        } else {
          start += 1
          end += 1
        }
      }
    },

    onAddBlock(blockType) {
      if (!this.activeBlock || this.activeBlockIndex < 0) {
        console.warn('No active block')
        return
      }

      let { key } = blockType
      this.blocks.splice(this.activeBlockIndex + 1, 0, {
        id: this.blocks.length + 1,
        type: 'block-' + key,
        content: ''
      })
    },

    onMoveBlock(direction) {
      if (!this.activeBlock || this.activeBlockIndex < 0) {
        console.warn('No active block')
        return
      }

      let removedItems = null
      let index = this.activeBlockIndex
      if (direction == 'up') {
        if (index > 0) {
          removedItems = this.blocks.splice(index, 1)
          this.blocks.splice(index - 1, 0, removedItems[0])
          this.activeBlockIndex -= 1
        }
      } else {
        if(index < this.blocks.length - 1) {
          removedItems = this.blocks.splice(index, 1)
          this.blocks.splice(index + 1, 0, removedItems[0])
          this.activeBlockIndex += 1
        }
      }
    },

    onRemoveBlock() {
      if (!this.activeBlock || this.activeBlockIndex < 0) {
        console.warn('No active block')
        return
      }

      let index = this.activeBlockIndex
      if (index > -1 && index < this.blocks.length) {
        this.blocks.splice(index, 1)
      }
    },

    onKeyDownInBlock(block, index, event) {
      switch(event.code) {
        case 'Enter':
          this.onPressEnterInBlock(block, index, event)
          event.preventDefault()
          event.stopPropagation()
          break

        case 'Delete':
        case 'Backspace':
          this.onPressDeleteInBlock(block, index, event)
          break
        
        case 'Slash':

          break

        default:
          break
      }
    },

    onPressEnterInBlock(block, index, event) {
      let el = event.target
      let blockContentEl = el.closest('.block-content')
      if (!blockContentEl) {
        console.warn('No block element selected')
        return
      }

      let selection = window.getSelection()
      let range = selection.getRangeAt(0)
      let wholeRange = document.createRange()
      wholeRange.selectNodeContents(blockContentEl)
      range.setEnd(wholeRange.endContainer, wholeRange.endOffset)
      let fragment = range.extractContents()
      let holderDiv = document.createElement('div')
      holderDiv.appendChild(fragment)
      this.blocks.splice(index + 1, 0, {
        id: this.blocks.length + 1,
        content: holderDiv.innerHTML
      })

      let blockEl = blockContentEl.closest('.editor-block')
      this.$nextTick(() => {
        let el = blockEl.nextSibling
        let targetEl = el.firstChild
        const range = document.createRange()
        const sel = window.getSelection()
        range.selectNodeContents(targetEl)
        range.collapse(false)
        sel.removeAllRanges()
        sel.addRange(range)
        targetEl.focus()
        range.detach()
      })
    },

    onPressDeleteInBlock(block, index, event) {
      let el = event.target
      let contentEl = el.closest('.block-content')
      if (!contentEl) {
        return
      }
      
      if (contentEl.textContent.trim() === '') {
        this.blocks.splice(index, 1)
        // move cursor to the previous block
        let blockEl = contentEl.closest('.editor-block')
        if (blockEl) {
          let prevBlock = blockEl.previousSibling
          let prevBlockContent = prevBlock.querySelector('.block-content')
          const range = document.createRange()
          const sel = window.getSelection()
          range.selectNodeContents(prevBlockContent)
          range.collapse(false)
          sel.removeAllRanges()
          sel.addRange(range)
          prevBlockContent.focus()
          range.detach()
          event.preventDefault()
          event.stopPropagation()
        }
      }
    }
  },

  mounted() {
    document.addEventListener('selectionchange', this.onTextSelectionChange.bind(this))
  }
}
</script>

<style scoped>

.block-indicator:empty:before {
  content: attr(data-placeholder);
}

.editor-block {
  min-height: 28px;
}

.editor-block >>> .block-controls {
  display: none;
}

.editor-block:hover >>> .block-controls {
  display: block;
}

.editor-block >>> .block-content {
  border: 1px solid white;
}

.editor-block:hover >>> .block-content {
  border: 1px solid lightgray;
}

.editor-block >>> .editable {
  outline: none;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>
import Vue from 'vue'
import './plugins/composition-api'

import VueTooltipDirective from 'vue-tooltip-directive'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from './plugins/confirm'
import VuetifyPrompt from './plugins/prompt'
import BrowserDetect from './plugins/detect'
import DomHelpers from './plugins/dom'
import Parse, * as api from './api/core'
import config from './config'
import 'windi.css'
import './plugins/filters'

Vue.config.productionTip = false
Vue.prototype.$Parse = Parse
Vue.prototype.$api = api
Vue.prototype.$detect = BrowserDetect
Vue.prototype.$dom = DomHelpers
Vue.prototype.$eventBus = new Vue({})
Vue.prototype.$config = config
Vue.prototype.$error = async (error, notify = false) => {
  console.log(error)
  if (notify) {
    await this.$notify()
  }
  if (error.message.indexOf('Permission denied')) {
    router.push({ name: 'login' } )
  }
}

Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VuetifyPrompt, { vuetify })

// tooltip
Vue.use(VueTooltipDirective)

import App from './App.vue'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <div
    :class="[
      'relative flex flex-row flex-nowrap shrink place-content-center items-center',
      level == 1 ? 'mindmap-node-root' : ''
    ]">
    <!-- left children list -->
    <div
      v-if="leftChildren.length > 0 && !leftCollapsed"
      ref="leftChildrenHolder"
      class="relative left-child-holder flex flex-row flex-nowrap shrink items-center z-0">
      <div
        class="flex flex-col shrink-0 space-y-8 items-end">
        <logic-node
          v-for="child in leftChildren"
          :key="child.id"
          :node="child"
          :level="level+1"
          :style-config="styleConfig"
          direction="left"
          ref="leftChildrenNodes"
          @select="$emit('select', $event)"
          @create="$emit('create', $event)"
          @remove="$emit('remove', $event)"
          @navigate="$emit('navigate', $event)"
          @bind="$emit('bind', $event)"
          @unbind="$emit('unbind', $event)"
          >
        </logic-node>
      </div>
      <!-- left path -->
      <div :style="{ width: `${ offsetX }px` }"></div>
      <svg
        ref="leftLineHolder"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute"
        :height="`${ leftChildrenHeight }px`"
        :width="`${offsetX + innerOffset}px`"
        :style="{ right: `-${ innerOffset }px` }">
        <template v-for="(seg, index) in leftSegments">
          <path
            :key="`leftseg-${index}`"
            :d="`M${seg.x1 + innerOffset} ${seg.y1} C${seg.x1 + innerOffset} ${seg.y1} ${ seg.x2 + offsetX} ${seg.y2} ${seg.x2} ${seg.y2}`"
            :stroke="styleConfig.lineColor"
            :stroke-width="styleConfig.lineWidth"/>
        </template>
      </svg>
    </div>

    <!-- root node -->
    <div
      ref="root"
      class="relative flex px-6 flex-col shrink rounded-full cursor-pointer shadow hover:shadow-md z-10"
      :style="{
        textColor: level == 1 ? styleConfig.primaryTextColor : ( level == 2 ? styleConfig.secondaryTextColor: 'gray' ), 
        backgroundColor: level == 1 ? styleConfig.primaryFillColor : ( level == 2 ? styleConfig.secondaryFillColor: 'transparent' ) 
      }"
      tabindex=0
      @dblclick.stop.prevent="onDoubleClick"
      @click.stop.prevent="onClick"
      @keydown="onKeyDown">
      <div class="relative text-white text-lg flex flex-row space-x-1 items-center z-10">
        <div
          class="font-bold my-4 mx-4">
          {{ node.title }}
        </div>
        <div
          v-if="editing"
          class="absolute w-full h-full outline-none top-0"
          v-click-outside="onDoneEditing">
          <v-text-field
            v-model="node.title"
            dense
            filled
            outlined
            rounded
            hide-details
            class="bg-white w-full py-0 my-2"
            @keydown.enter="onDoneEditing"
            @keydown.esc="onDoneEditing"
          ></v-text-field>
        </div>
      </div>
      <div
        v-if="selected && !editing"
        class="absolute border-2 border-blue-400 -top-1 -left-1 rounded-full"
        style="pointer-events: none;width: calc(100% + 8px); height: calc(100% + 8px)">
      </div>
      
      <template v-if="showCollapseButton">
        <template v-if="leftChildren.length > 0">
          <div
            class="absolute"
            :style="{
              left: `-${anchorSize}px`,
              top: `calc(50% - ${anchorSize / 2.0}px)`
            }">
            <button
              class="rounded-full border bg-white place-content-center hover:bg-gray-200 text-xs text-gray-500 shadow-md outline-none"
              @click.stop.prevent="toggleLeft"
              :style="{
                width: `${ anchorSize }px`,
                height: `${ anchorSize }px`
              }"
              >
              <span v-if="leftCollapsed">+</span>
              <span v-else>-</span>
            </button>
          </div>
        </template>
        <template v-if="rightChildren.length > 0">
          <div
            class="absolute"
            :style="{
              left: '100%',
              top: `calc(50% - ${ anchorSize / 2.0}px)`
            }">
            <button
              class="rounded-full border bg-white place-content-center hover:bg-gray-200 text-xs text-gray-500 shadow-md outline-none"
              @click.stop.prevent="toggleRight"
              :style="{
                width: `${ anchorSize }px`,
                height: `${ anchorSize }px`
              }"
              >
              <span v-if="rightCollapsed">+</span>
              <span v-else>-</span>
            </button>
          </div>
        </template>
      </template>
    </div>

    <!-- right children -->
    <div
      v-if="rightChildren.length > 0 && !rightCollapsed"
      ref="rightChildrenHolder"
      class="relative right-child-holder flex flex-row flex-nowrap shrink items-center z-0"
      >
      <div :style="{ width: `${ offsetX }px` }"></div>
      <svg
        ref="rightLineHolder"
        preserveaspectratio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :height="`${ rightChildrenHeight }px`"
        :width="`${offsetX + innerOffset}px`"
        class="absolute"
        :style="{ left: `-${ innerOffset }px` }">
        <template v-for="(seg, index) in rightSegments">
          <path
            :key="`rightseg-${index}`"
            :d="`M${seg.x1 - innerOffset} ${seg.y1} C${seg.x1 - innerOffset} ${ seg.y1 } ${seg.x2 - offsetX} ${seg.y2} ${seg.x2} ${seg.y2}`"
            :stroke="styleConfig.lineColor"
            :stroke-width="styleConfig.lineWidth"/>
        </template>
      </svg>
      <div
        class="flex flex-col shrink-0 space-y-8 items-start">
        <logic-node
          v-for="child in rightChildren"
          :key="child.id"
          :node="child"
          :level="level+1"
          :direction="'right'"
          :style-config="styleConfig"
          @select="$emit('select', $event)"
          @create="$emit('create', $event)"
          @remove="$emit('remove', $event)"
          @navigate="$emit('navigate', $event)"
          @bind="$emit('bind', $event)"
          @unbind="$emit('unbind', $event)"
          ref="rightChildrenNodes">
        </logic-node>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import mixin from './mixin'
import LogicNode from './LogicNode'

export default {
  name: 'MindMapNode',

  mixins: [
    mixin
  ],

  components: {
    LogicNode
  },

  data() {
    return {
      offsetX: 64,
      innerOffset: 20,
      leftChildrenHeight: 0,
      leftResizeObserver: null,
      leftLineStartPoint: null,
      leftChildrenAnchorPoints: [],
      leftCollapsed: false,
      rightChildrenHeight: 0,
      rightResizeObserver: null,
      rightLineStartPoint: null,
      rightChildrenAnchorPoints: [],
      rightCollapsed: false
    }
  },

  computed: {
    leftChildren() {
      let length = this.node.children.length
      let end = Math.floor(length / 2)
      return this.node.children.slice(0, end)
    },

    rightChildren() {
      let length = this.node.children.length
      let start = Math.floor(length / 2)
      return this.node.children.slice(start)
    },

    leftSegments() {
      let segs = []
      this.leftChildrenAnchorPoints.forEach(p => {
        segs.push({
          x1: this.leftLineStartPoint.x,
          y1: this.leftLineStartPoint.y,
          x2: p.x,
          y2: p.y
        })
      })
      return segs
    },

    rightSegments() {
      let segs = []
      this.rightChildrenAnchorPoints.forEach(p => {
        segs.push({
          x1: this.rightLineStartPoint.x,
          y1: this.rightLineStartPoint.y,
          x2: p.x,
          y2: p.y
        })
      })
      return segs
    }
  },

  methods: {
    toggleLeft() {
      Vue.set(this, 'leftCollapsed', !this.leftCollapsed)
    },

    toggleRight() {
      Vue.set(this, 'rightCollapsed', !this.rightCollapsed)
    },

    calculateLineStartPoint() {
      if (!this.$refs.root) {
        console.log('Node not initialized yet')
        return
      }
      let rect = this.$refs.root.getBoundingClientRect()
      let svgRect = null
      if (this.$refs.leftLineHolder) {
        svgRect = this.$refs.leftLineHolder.getBoundingClientRect()
        this.leftLineStartPoint = {
          x: svgRect.width - this.innerOffset,
          y: rect.top - svgRect.top + rect.height / 2.0
        }
      }

      if (this.$refs.rightLineHolder) {
        svgRect = this.$refs.rightLineHolder.getBoundingClientRect()
        this.rightLineStartPoint = {
          x: this.innerOffset,
          y: rect.top - svgRect.top + rect.height / 2.0
        }
      }
    },

    calculateChildrenAnchorPoints() {
      // left child nodes
      this.leftChildrenAnchorPoints.splice(0, this.leftChildrenAnchorPoints.length)
      if (this.$refs.leftChildrenNodes) {
        let leftSvgRect = this.$refs.leftLineHolder.getBoundingClientRect()

        // the child anchor points
        for (let i = 0; i < this.$refs.leftChildrenNodes.length; ++i) {
          let comp = this.$refs.leftChildrenNodes[i];
          let point = comp.getAnchorPoint()
          let anchorPoint = {
            x: 0,
            y: point.top - leftSvgRect.top
          };
          this.leftChildrenAnchorPoints.push(anchorPoint)
        }
      }

      // right child nodes
      this.rightChildrenAnchorPoints.splice(0, this.rightChildrenAnchorPoints.length)
      if (this.$refs.rightChildrenNodes) {
        let rightSvgRect = this.$refs.rightLineHolder.getBoundingClientRect()

        // the child anchor points
        for (let i = 0; i < this.$refs.rightChildrenNodes.length; ++i) {
          let comp = this.$refs.rightChildrenNodes[i]
          let point = comp.getAnchorPoint()
          let anchorPoint = {
            x: rightSvgRect.width,
            y: point.top - rightSvgRect.top
          };
          this.rightChildrenAnchorPoints.push(anchorPoint)
        }
      }
    },

    calculateSvgHeights() {
      if (this.$refs.leftChildrenHolder && 
        this.$refs.rightChildrenHolder)
      {
        this.leftChildrenHeight = this.$refs.leftChildrenHolder.getBoundingClientRect().height
        this.rightChildrenHeight = this.$refs.rightChildrenHolder.getBoundingClientRect().height
      }
    },

    doResize() {
      this.calculateSvgHeights()
      this.$nextTick(() => {
        this.calculateChildrenAnchorPoints()
        this.calculateLineStartPoint()
      })
    }
  },

  mounted() {
    const interval = setInterval(() => {
      if ( (this.leftChildren.length > 0 && this.$refs.leftChildrenNodes || this.leftChildren.length == 0)
        && (this.rightChildren.length > 0 && this.$refs.rightChildrenNodes || this.rightChildren.length == 0)
        && this.$refs.root)
      {
        this.doResize()
        this.$refs.root.scrollIntoView()
        clearInterval(interval)
      }
    }, 50)
    this.$emit('bind', { node: this.node, component: this })
  },
  
  destroyed() {
    this.leftResizeObserver && this.leftResizeObserver.disconnect()
    this.rightResizeObserver && this.rightResizeObserver.disconnect()
    this.$emit('unbind', this.node)
  }
}
</script>
<template>
  <div
    :key="block.id"
    :id="`block-${block.id}`"
    :data-block-type="block.type"
    class="editor-block relative w-full flex items-center overflow-hidden m-0 py-0"
    :style="boxStyles"
    >
    <div
      tabindex="0"
      class="block-content w-full h-full"
      >
      <component
        :is="block.type"
        :block="block"
        />
    </div>
    <div
      class="block-controls absolute top-0"
      :style="selectorStyles"
      >
      <v-btn icon small @click.stop.prevent="toggleBlockSelector">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        class="block-handle"
        @click.stop.prevent="toggleBlockActions">
        <v-icon>mdi-drag</v-icon>
      </v-btn>
    </div>
    <div class="block-controls absolute top-0 right-6">
      <v-btn icon small>
        <v-icon>mdi-comment-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import BlockComponents from './blocks'

export default {
  name: 'EditorBlock',

  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  components: BlockComponents,

  computed: {
    boxStyles() {
      const indent = this.block.indent || 0
      return {
        paddingLeft: `calc(58px + ${indent * 2}em)`,
        paddingRight: '58px'
      }
    },

    selectorStyles() {
      const indent = this.block.indent || 0
      return {
        left: `calc(0px + ${indent * 2}em)`
      }
    }
  },

  methods: {
    toggleBlockActions(event) {
      this.$emit('toggleBlockActions', this.block, this.index, event)
    },

    toggleBlockSelector(event) {
      this.$emit('toggleBlockSelector', this.block, this.index, event)
    }
  }
}
</script>
import _ from 'lodash'
import Vue from 'vue'
import Parse, * as api from '@/api/core'

export default {
  namespaced: true,
  state: {
    items: [
    ],
    itemsMap: {
    },
    inited: false
  },

  getters: {
    ancestors: (state) => (folder) => {
      let ancestors = []
      let folderKey = _.isObject(folder) ? folder.key : folder
      let folderItem = _.get(state.itemsMap, folderKey)
      if (!folderItem) {
        return ancestors
      }
      ancestors.push(folderItem)
      while (folderItem.parent) {
        let parentItem = folderItem.parent
        if (!_.isObject(parentItem)) {
          parentItem = _.get(state.itemsMap, parentItem)
        }
        if (parentItem) {
          ancestors.splice(0, 0, parentItem)
          folderItem = parentItem
        } else {
          break
        }
      }
      return ancestors
    },

    folder: (state) => (folderKey) => {
      return _.get(state.itemsMap, folderKey)
    }
  },

  mutations: {
    removeFolder(state, key) {
      let folder = _.get(state.itemsMap, key)
      if (!folder) {
        return
      }

      const clearChildren = (folder) => {
        (folder.children || []).forEach(c => {
          let childKey = _.isString(c) ? c : c.key
          let child = _.get(state.itemsMap, childKey)
          if (child) {
            Vue.delete(state.itemsMap, childKey)
            clearChildren(child)
          }
        })
      }

      let parent = folder.parent
      let targetItems = state.items
      if (parent) {
        targetItems = state.itemsMap[ _.isObject(parent) ? parent.key : parent].children
      }
      let index = _.findIndex(targetItems, (x) => x.key == folder.key)
      if (index > -1) {
        targetItems.splice(index, 1)
        Vue.delete(state.itemsMap, key)
        clearChildren(folder)
      }
    },

    moveFolder(state, options) {
      let { key, from, to } = options
      let movingFolder = _.get(state.itemsMap, key)
      if (!movingFolder) {
        console.warn('Moving folder not found')
        return
      }

      let fromParent = from ? _.get(state.itemsMap, from) : null
      let toParent = to ? _.get(state.itemsMap, to) : null

      let fromTargetItems = fromParent ? fromParent.children : state.items
      let toTargetItems = toParent ? toParent.children : state.items
      if (!fromTargetItems || !toTargetItems) {
        console.warn('Missing moving source or target')
        return
      }

      let fromIndex = _.findIndex(fromTargetItems, x => x.key == key)
      if (fromIndex < 0) {
        console.warn('Moving folder not found in parent children list')
        return
      }
    }
  },

  actions: {
    async load({ state }, options) {
      try {
        const { refresh = false } = options || {}
        if (state.inited && !refresh) {
          return
        }

        if (state.inited) {
          state.items.splice(0)
          state.itemsMap = {}
        }

        let results = await api.getQuery('Folder')
          .equalTo('owner', api.currentUser())
          .select('parent', 'name', 'color', 'key', 'children')
          .find()
        
        results.forEach(r => {
          Vue.set(state.itemsMap, r.key, r)
        })

        let topLevelNodes = _.filter(results, x => _.isNil(x.parent)) || []
        topLevelNodes.forEach(t => {
          state.items.push(t)
        })
        this.inited = true
      } catch(error) {
        console.error(error)
      }
    },

    async addFolder({ state }, options) {
      let { parent, folder } = options
      let addToRoot = !(_.isString(parent))
      let parentItem = null
      if (!addToRoot) {
        parentItem = _.get(state.itemsMap, parent)
        if (!parentItem) {
          console.warn('Parent folder not found')
          return
        }
      }
      
      let newFolderItem = api.newObjectWithData('Folder', {
        name: folder.name,
        key: folder.key,
        parent: parentItem,
        children: []
      })

      try {
        await newFolderItem.save()
        if (!addToRoot) {
          parentItem.add('children', newFolderItem)
          await parentItem.save()
        } else {
          state.items.push(newFolderItem)
        }
        Vue.set(state.itemsMap, newFolderItem.key, newFolderItem)
        return newFolderItem
      } catch(error) {
        console.error(error)
      }
      return null
    },

    async removeFolder({ state }, options) {
      // folder should be the string form folder key
      let { folder } = options
      let folderItem = _.get(state.itemsMap, _.isObject(folder) ? folder.key : folder)
      if (!folderItem) {
        console.warn('Folder not found')
        return
      }
  
      let parentItem = null
      if (_.isString(folderItem.parent)) {
        parentItem = _.get(state.itemsMap, folderItem.parent)
      } else {
        parentItem = folderItem.parent
      }
  
      try {
        await Parse.Cloud.run('deleteFolder', { folder: folderItem.key })
        if (parentItem) {
          parentItem.remove('children', folderItem)
          await parentItem.save()
        } else {
          // remove from folder list
          let index = _.findIndex(state.items, x => x.key == folderItem.key)
          if (index > -1) {
            state.items.splice(index, 1)
          }
        }
        
        const loopDeleteChildren = (f) => {
          (f.chidren || []).forEach(c => {
            loopDeleteChildren(c)
          })
          Vue.delete(state.itemsMap, f.key)
        }
  
        loopDeleteChildren(folderItem)
  
      } catch(error) {
        console.error(error)
      } 
    },
  
    /**
     * Move folder from one parent to the other
     */
    async moveFolder({ state }, options) {
      let { folder, from, to, toIndex } = options
      // folder should be the key of the folder
      if (!folder || !_.isString(folder)) {
        console.warn('Folder not provided or invalid folder key')
        return
      }
  
      let folderItem = _.get(state.itemsMap, folder)
      if (!folderItem) {
        console.warn('Specified folder item not found')
        return
      }
  
      let fromItem = null
      if (from) {
        fromItem = _.get(state.itemsMap, from)
        if (!fromItem) {
          console.warn('From item not found')
          return
        }
      }
  
      let toItem = null
      if (to) {
        toItem = _.get(state.itemsMap, to)
      }
  
      if (fromItem) {
        // remove from folder
        fromItem.remove('children', folderItem)
      } else {
        // remove from root
        let index = _.findIndex(state.items, x => x.key == folderItem.key)
        if (index > -1) {
          state.items.splice(index, 1)
        }
      }
  
      if (toItem) {
        if (toIndex < 0) {
          toIndex = 0
        }
        if (toIndex > toItem.children.length) {
          toIndex = toItem.children.length
        }
  
        let children = toItem.children.map(c => c)
        children.splice(toIndex, 0, folderItem)
        toItem.set('children', children)
        folderItem.set('parent', toItem)
      } else {
  
        if (toIndex < 0) {
          toIndex = 0
        }
        if (toIndex > state.items.length) {
          toIndex = state.items.length
        }
        
        folderItem.set('parent', null)
        state.items.splice(toIndex, 0, folderItem)
      }
  
      try {
        await Parse.Object.saveAll( _.filter([fromItem, toItem, folderItem], x => !!x))
      } catch(error) {
        console.error(error)
      }
    },
  
    /**
     * Update folder
     */
    async updateFolder({ state }, options) {
      try {
        let { folder } = options
        if (!folder || !_.isObject(folder) || !_.isString(folder.key)) {
          console.error('Invalid folder')
          return
        }
  
        let folderObj = _.get(state.itemsMap, folder.key )
        if (!folderObj) {
          console.error('Folder not found in store')
          return
        }
  
        folderObj.set('name', folder.name)
        folderObj.set('color', folder.color)
        await folderObj.save()
        
      } catch(error) {
        console.error(error)
      }
    }
  }
}
<template>
  <div
    contenteditable
    class="editable w-full cursor-pointer"
    v-html="block.content"
    >
  </div>
</template>

<script>

export default {
  name: 'Paragraph',

  props: {
    block: {
      type: Object,
      required: true
    }
  },

  data() {
    return {

    }
  },

  methods: {

  }
}
</script>
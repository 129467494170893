var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col px-4 pt-1 pb-4 bg-white"},[_c('v-subheader',{staticClass:"w-full flex flex-row space-x-2"},[_c('span',{staticClass:"grow"},[_vm._v("Select Labels")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleViewMode.apply(null, arguments)}}},[(_vm.viewMode == 'row')?_c('v-icon',[_vm._v("mdi-table-column")]):_c('v-icon',[_vm._v("mdi-table-row")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.allowCreateLabel)?[_c('v-text-field',{class:[_vm.darkText ? 'dark-text-input' : 'light-text-input'],style:({
        'background-color': _vm.newLabel.color
      }),attrs:{"dense":"","name":"name","filled":"","rounded":"","placeholder":"Type to add new label","hide-details":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createLabel.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clearInput.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('color-picker',{model:{value:(_vm.newLabel.color),callback:function ($$v) {_vm.$set(_vm.newLabel, "color", $$v)},expression:"newLabel.color"}})]},proxy:true}],null,false,58528647),model:{value:(_vm.newLabel.text),callback:function ($$v) {_vm.$set(_vm.newLabel, "text", $$v)},expression:"newLabel.text"}})]:_vm._e(),_c('div',{class:['grow px-4', _vm.allowCreateLabel ? 'mt-4' : 'mt-0']},[_c('div',{class:[
        'flex gap-2 my-2',
        _vm.viewMode == 'row' ? 'flex-row flex-wrap' : 'flex-col'
      ]},[_vm._l((_vm.filteredLabels),function(label){return [_c('div',{key:label.text,staticClass:"border px-4 py-2 hover:bg-gray-200 cursor-pointer rounded",style:({
            'background-color': label.color,
            'color': _vm.invertColorValue(label.color)
          }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectLabel(label)}}},[_vm._v(" "+_vm._s(label.text)+" ")])]})],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
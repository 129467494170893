const WidgetTypes = [
  {
    name: 'Button',
    key: 'wired-button', 
    props: {
      styles: {
        type: Object,
        default: () => {
          return {
            backgroundColor: '#fff'
          }
        }
      },
      width: { type: Number, default: 100 },
      height: { type: Number, default: 32 },
      randomSeed: { type: Number, default: Math.floor(Math.random() * 2 ** 31) },
      text: {
        type: String,
        default: 'Button'
      }
    },
    render: (h, props) => {
      return h('wired-button', 
        {
          style: props.styles,
          attrs: { width: props.width, height: props.height, randomSeed: props.randomSeed }
        },
        props.text
      )
    },
    demo: '<wired-button>Button</wired-button>'
  },
  {
    name: 'Calendar',
    key: 'wired-calendar',
    props: {
      styles: {
        type: Object, 
        default: () => {
          return {
          }
        }
      },
      width: { type: Number, default: 300 },
      height: { type: Number, default: 300 },
      randomSeed: { type: Number, default: Math.floor(Math.random() * 2 ** 31) },
    },
    render: (h, props) => {
      return h('wired-calendar', 
        {
          style: props.styles,
          attrs: { width: props.width, height: props.height, randomSeed: props.randomSeed }
        }
      )
    },
    demo: '<wired-calendar style="width:180px;height:180px;transform: scale(0.5)"></wired-calendar>'
  },
  {
    name: 'Card',
    key: 'wired-card',
    props: {
      titleTag: { type: String, default: 'h2' },
      styles: { type: Object, default: () => {
        return {
          paddingLeft: '20px',
          paddingTop: '10px'
        }
      } },
      width: { type: Number, default: 300 },
      height: { type: Number, default: 300 },
      randomSeed: { type: Number, default: Math.floor(Math.random() * 2 ** 31) },
      title: { type: String, default: 'Title' },
      content: { type: String, default: 'Card Text' },
    },
    render: (h, props) => {
      return h('wired-card',
        {
          style: props.styles,
          attrs: { width: props.width, height: props.height, randomSeed: props.randomSeed }
        }, [
        h(props.titleTag, props.title),
        h('div', props.content)
      ])
    },
    demo: '<wired-card style="width:200px;padding:12px;"><h2>Card</h2><div>Text </div></wired-card>'
  },
  {
    name: 'Checkbox',
    key: 'wired-checkbox',
    props: {
      styles: {
        type: Object, 
        default: () => {
          return {
            width: '160px',
            height: '32px'
          }
        }
      },
      text: { type: String, default: 'Checkbox' }
    },
    render: (h, props) => {
      return h('wired-checkbox', { style: props.styles }, props.text )
    },
    demo: '<wired-checkbox>Checkbox</wired-checkbox>'
  },
  {
    name: 'Combobox',
    key: 'wired-combo',
    props: {
      styles: {
        type: Object,
        default: () => {
          return {
            width: '120px',
            height: '48px'
          }
        }
      },
      items: {
        type: Array,
        default: () => {
          return [
            { value: 'One', text: 'Number one' },
            { value: 'Two', text: 'Number two' }
          ]
        }
      }
    },
    render: (h, props) => {
      return h('wired-combo', { selected: 'one', role: 'combobox', style: props.styles },
        props.items.map(item => {
          return h('wired-item', { value: item.value, role: 'option' }, item.text )
        })
      )
    },
    demo: `
    <wired-combo selected="two" role="combobox" aria-haspopup="listbox" tabindex="0" aria-expanded="false">
      <wired-item value="one" role="option">Number one</wired-item>
      <wired-item value="two" aria-selected="true" role="option">Number two</wired-item>
      <wired-item value="three" role="option">Number three</wired-item>
      <wired-item value="four" role="option">Number four</wired-item>
    </wired-combo>
  `
  },
  {
    name: 'Divider',
    key: 'wired-divider',
    demo: '<wired-divider style="width:160px"></wire-divider>'
  },
  {
    name: 'Fab',
    key: 'wired-fab',
    demo: '<wired-fab class="white">&#9733</wired-fab>'
  },
  {
    name: 'Icon Button',
    key: 'wired-icon-button',
    demo: '<wired-icon-button>&#9733</wired-icon-button>'
  },
  {
    name: 'Image',
    key: 'wired-image',
    demo: `
    <wired-image src="https://www.gstatic.com/webp/gallery/1.sm.jpg" style="width: 160px"></wired-image>
  `
  },
  {
    name: 'Input',
    key: 'wired-input',
    demo: `
    <wired-input placeholder="Enter name"></wired-input>
  `
  },
  {
    name: 'Link',
    key: 'wired-link',
    demo: '<wired-link href="https://github.com/wiredjs/wired-elements/">Link</wired-link>'
  },
  {
    name: 'ListBox',
    key: 'wired-listbox',
    demo: `
    <wired-listbox selected="one" role="listbox" tabindex="0">
      <wired-item value="one" aria-selected="true" role="option">No. one</wired-item>
      <wired-item value="two" role="option">No. two</wired-item>
      <wired-item value="three" role="option">No. three</wired-item>
      <wired-item value="four" role="option">No. four</wired-item>
    </wired-listbox>
    `
  },
  {
    name: 'Progress',
    key: 'wired-progress',
    demo: '<wired-progress value="25" style="width: 160px"></wired-progress>'
  },
  {
    name: 'Radio Button',
    key: 'wired-radio',
    demo: `
    <wired-radio name="one">Radio
    </wired-radio>
    `
  },
  {
    name: 'Radio Group',
    key: 'wired-radio-group',
    demo: `
    <wired-radio-group id="rg" selected="one" role="radiogroup" tabindex="0">
      <wired-radio name="one">One</wired-radio>
      <wired-radio name="two">Two</wired-radio>
    </wired-radio-group>
    `
  },
  {
    name: 'Search Input',
    key: 'wired-search-input',
    demo: '<wired-search-input placeholder="Search"></wired-search-input>'
  },
  {
    name: 'Slider',
    key: 'wired-slider',
    demo: '<wired-slider value="10" min="5" max="15" style="width:160px"></wired-slider>'
  },
  {
    name: 'Spinner',
    key: 'wired-spinner',
    demo: '<wired-spinner></wired-spinner>'
  },
  {
    name: 'Tabs',
    key: 'wired-tabs',
    demo: `
    <wired-tabs selected="One" tabindex="0">
      <wired-tab name="One" style="padding: 8px">
        <h4>Card 1</h4>
        <p style="width:160px">
          Lorem ipsum dolor sit amet, consectetur
          </p>
      </wired-tab>
      <wired-tab name="Two" class="hidden wired-rendered">
        <h4>Card 2</h4>
        <p style="width:160px">
          Lorem ipsum dolor sit amet, consectetur
         </p>
      </wired-tab>
    </wired-tabs>
    `
  },
  {
    name: 'Textarea',
    key: 'wired-textarea',
    demo: '<wired-textarea placeholder="Enter text" style="width:160px;"></wired-textarea>'
  },
  {
    name: 'Toggle',
    key: 'wired-toggle',
    demo: '<wired-toggle></wired-toggle>'
  },
  {
    name: 'Video',
    key: 'wired-video',
    demo: `
    <wired-video autoplay="" playsinline="" muted="" loop="">
        <video autoplay="" loop="" src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4">
        </video>
        <div id="overlay">
          <svg width="306" height="221"><path d="M3.506890356329135 3.0154015636684264 C94.68457534256596 4.9198324106083104, 186.79285317321578 6.138203648436113, 302.397403351095 5.2744567073520265 M3.3891451980189364 4.630681572080912 C75.66377139102934 3.3770386386216966, 147.33137543233497 3.615144133138391, 301.8265143010516 3.6543828622105856 M301.00424530624076 2.320831947356173 C302.0088643354015 62.49802333684526, 304.9852406702403 125.20909977960278, 303.2000018200344 216.27692368107992 M302.29566516322376 3.5870939743984716 C302.1931851987211 71.7395603436906, 303.1043092017706 137.476824519869, 301.4932192028899 216.75171389807082 M302.1102778462412 215.87762310971902 C200.30308432006348 215.00351321734257, 99.04603058951946 214.73930282769388, 4.647372910863355 215.64847788503562 M301.32225248406684 216.55462959082388 C224.75461462541747 218.19544180445232, 148.71038843749977 218.13200920229468, 4.6732953535263695 217.63029807139927 M5.635908066983894 217.2184165795238 C4.749465904218073 170.33204585495076, 4.392121267737265 119.3089620982946, 4.551598035568812 3.476205342147681 M4.1704526035537945 216.95076534520584 C2.9973380984474542 145.87251726991195, 3.497200288768089 75.10109602074265, 4.180741870658277 3.5030081456878674"></path></svg>
        </div>
        <div id="controls">
          <wired-progress style="width:80px"></wired-progress>
          <div class="horizontal layout center">
            <div class="flex">
              <wired-slider style="width:80px;"></wired-slider>
            </div>
          </div>
        </div>
    </wired-video>
    `
  },
]

const widgetTypeMaps = {}
WidgetTypes.forEach(widgetType => {
  widgetTypeMaps[widgetType.key] = widgetType
})

const getWidgetType = (key) => {
  return widgetTypeMaps[key]
}

export {
  WidgetTypes,
  getWidgetType
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'relative flex flex-row flex-nowrap shrink place-content-center items-center',
    _vm.level == 1 ? 'mindmap-node-root' : ''
  ]},[(_vm.leftChildren.length > 0 && !_vm.leftCollapsed)?_c('div',{ref:"leftChildrenHolder",staticClass:"relative left-child-holder flex flex-row flex-nowrap shrink items-center z-0"},[_c('div',{staticClass:"flex flex-col shrink-0 space-y-8 items-end"},_vm._l((_vm.leftChildren),function(child){return _c('logic-node',{key:child.id,ref:"leftChildrenNodes",refInFor:true,attrs:{"node":child,"level":_vm.level+1,"style-config":_vm.styleConfig,"direction":"left"},on:{"select":function($event){return _vm.$emit('select', $event)},"create":function($event){return _vm.$emit('create', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"navigate":function($event){return _vm.$emit('navigate', $event)},"bind":function($event){return _vm.$emit('bind', $event)},"unbind":function($event){return _vm.$emit('unbind', $event)}}})}),1),_c('div',{style:({ width: (_vm.offsetX + "px") })}),_c('svg',{ref:"leftLineHolder",staticClass:"absolute",style:({ right: ("-" + _vm.innerOffset + "px") }),attrs:{"preserveAspectRatio":"none","fill":"none","xmlns":"http://www.w3.org/2000/svg","height":(_vm.leftChildrenHeight + "px"),"width":((_vm.offsetX + _vm.innerOffset) + "px")}},[_vm._l((_vm.leftSegments),function(seg,index){return [_c('path',{key:("leftseg-" + index),attrs:{"d":("M" + (seg.x1 + _vm.innerOffset) + " " + (seg.y1) + " C" + (seg.x1 + _vm.innerOffset) + " " + (seg.y1) + " " + (seg.x2 + _vm.offsetX) + " " + (seg.y2) + " " + (seg.x2) + " " + (seg.y2)),"stroke":_vm.styleConfig.lineColor,"stroke-width":_vm.styleConfig.lineWidth}})]})],2)]):_vm._e(),_c('div',{ref:"root",staticClass:"relative flex px-6 flex-col shrink rounded-full cursor-pointer shadow hover:shadow-md z-10",style:({
      textColor: _vm.level == 1 ? _vm.styleConfig.primaryTextColor : ( _vm.level == 2 ? _vm.styleConfig.secondaryTextColor: 'gray' ), 
      backgroundColor: _vm.level == 1 ? _vm.styleConfig.primaryFillColor : ( _vm.level == 2 ? _vm.styleConfig.secondaryFillColor: 'transparent' ) 
    }),attrs:{"tabindex":"0"},on:{"dblclick":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDoubleClick.apply(null, arguments)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick.apply(null, arguments)},"keydown":_vm.onKeyDown}},[_c('div',{staticClass:"relative text-white text-lg flex flex-row space-x-1 items-center z-10"},[_c('div',{staticClass:"font-bold my-4 mx-4"},[_vm._v(" "+_vm._s(_vm.node.title)+" ")]),(_vm.editing)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onDoneEditing),expression:"onDoneEditing"}],staticClass:"absolute w-full h-full outline-none top-0"},[_c('v-text-field',{staticClass:"bg-white w-full py-0 my-2",attrs:{"dense":"","filled":"","outlined":"","rounded":"","hide-details":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onDoneEditing.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onDoneEditing.apply(null, arguments)}]},model:{value:(_vm.node.title),callback:function ($$v) {_vm.$set(_vm.node, "title", $$v)},expression:"node.title"}})],1):_vm._e()]),(_vm.selected && !_vm.editing)?_c('div',{staticClass:"absolute border-2 border-blue-400 -top-1 -left-1 rounded-full",staticStyle:{"pointer-events":"none","width":"calc(100% + 8px)","height":"calc(100% + 8px)"}}):_vm._e(),(_vm.showCollapseButton)?[(_vm.leftChildren.length > 0)?[_c('div',{staticClass:"absolute",style:({
            left: ("-" + _vm.anchorSize + "px"),
            top: ("calc(50% - " + (_vm.anchorSize / 2.0) + "px)")
          })},[_c('button',{staticClass:"rounded-full border bg-white place-content-center hover:bg-gray-200 text-xs text-gray-500 shadow-md outline-none",style:({
              width: (_vm.anchorSize + "px"),
              height: (_vm.anchorSize + "px")
            }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleLeft.apply(null, arguments)}}},[(_vm.leftCollapsed)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("-")])])])]:_vm._e(),(_vm.rightChildren.length > 0)?[_c('div',{staticClass:"absolute",style:({
            left: '100%',
            top: ("calc(50% - " + (_vm.anchorSize / 2.0) + "px)")
          })},[_c('button',{staticClass:"rounded-full border bg-white place-content-center hover:bg-gray-200 text-xs text-gray-500 shadow-md outline-none",style:({
              width: (_vm.anchorSize + "px"),
              height: (_vm.anchorSize + "px")
            }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleRight.apply(null, arguments)}}},[(_vm.rightCollapsed)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("-")])])])]:_vm._e()]:_vm._e()],2),(_vm.rightChildren.length > 0 && !_vm.rightCollapsed)?_c('div',{ref:"rightChildrenHolder",staticClass:"relative right-child-holder flex flex-row flex-nowrap shrink items-center z-0"},[_c('div',{style:({ width: (_vm.offsetX + "px") })}),_c('svg',{ref:"rightLineHolder",staticClass:"absolute",style:({ left: ("-" + _vm.innerOffset + "px") }),attrs:{"preserveaspectratio":"none","fill":"none","xmlns":"http://www.w3.org/2000/svg","height":(_vm.rightChildrenHeight + "px"),"width":((_vm.offsetX + _vm.innerOffset) + "px")}},[_vm._l((_vm.rightSegments),function(seg,index){return [_c('path',{key:("rightseg-" + index),attrs:{"d":("M" + (seg.x1 - _vm.innerOffset) + " " + (seg.y1) + " C" + (seg.x1 - _vm.innerOffset) + " " + (seg.y1) + " " + (seg.x2 - _vm.offsetX) + " " + (seg.y2) + " " + (seg.x2) + " " + (seg.y2)),"stroke":_vm.styleConfig.lineColor,"stroke-width":_vm.styleConfig.lineWidth}})]})],2),_c('div',{staticClass:"flex flex-col shrink-0 space-y-8 items-start"},_vm._l((_vm.rightChildren),function(child){return _c('logic-node',{key:child.id,ref:"rightChildrenNodes",refInFor:true,attrs:{"node":child,"level":_vm.level+1,"direction":'right',"style-config":_vm.styleConfig},on:{"select":function($event){return _vm.$emit('select', $event)},"create":function($event){return _vm.$emit('create', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"navigate":function($event){return _vm.$emit('navigate', $event)},"bind":function($event){return _vm.$emit('bind', $event)},"unbind":function($event){return _vm.$emit('unbind', $event)}}})}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
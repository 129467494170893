import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue'
import * as api from '../api/core'

Vue.use(VueRouter)

function checkAuthentication(to, from, next) {
  console.log(from)
  if (to.name !== 'login' && !api.isAuthenticated()) {
    return next({ name: 'login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'notes',
        name: 'user-notes',
        meta: {
          key: 'notes'
        },
        beforeEnter: checkAuthentication,
        component: () => import( '../views/user/NotesView.vue' )
      },
      {
        path: 'todos',
        name: 'user-todos',
        meta: {
          key: 'todos'
        },
        beforeEnter: checkAuthentication,
        component: () => import ( '../views/user/TodosView.vue' )
      },
      {
        path: 'labels',
        name: 'user-labels',
        meta: {
          key: 'labels'
        },
        beforeEnter: checkAuthentication,
        component: () => import ( '../views/user/LabelsView.vue' )
      },
      {
        path: 'trash',
        name: 'user-trash',
        meta: {
          key: 'trash'
        },
        beforeEnter: checkAuthentication,
        component: () => import ( '../views/user/TrashView.vue' )
      },
      {
        path: 'account',
        name: 'user-account',
        beforeEnter: checkAuthentication,
        component: () => import ( '../views/user/AccountView.vue' )
      },
      {
        path: 'search',
        name: 'user-search',
        meta: {
          key: 'search'
        },
        beforeEnter: checkAuthentication,
        component: () => import ( '../views/user/SearchView.vue' )
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/SignUp.vue')
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/ForgotPassword.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

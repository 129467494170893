<template>
  <div class="flex flex-col items-center w-full h-full pb-8 overflow-auto">
    <div class="todos-holder flex flex-col w-4/5 mt-4">
      <div class="flex flex-row space-x-4 shrink items-center">
        <span class="text-lg">Todos</span>
        <span
          :class="[
            'text-green-400 hover:bg-gray-100 px-2 py-1 cursor-pointer',
            filterStatus == 'done' ? 'border border-gray-400' : 'border border-white'
          ]"
          @click.stop.prevent="filterStatus = 'done'">
          {{ todoGroups['done'] || 0 }} Done
        </span>
        <span
          :class="[
            'text-blue-400 hover:bg-gray-100 px-2 py-1 cursor-pointer',
            filterStatus == 'progress' ? 'border border-gray-400' : 'border border-white'
          ]"
          @click.stop.prevent="filterStatus = 'progress'">
          {{ todoGroups['progress'] || 0 }} In progress
          </span>
        <span
          :class="[
            'text-red-400 hover:bg-gray-100 px-2 py-1 cursor-pointer',
            filterStatus == 'overdue' ? 'border border-gray-400' : 'border border-white'
          ]"
          @click.stop.prevent="filterStatus = 'overdue'">
          {{ todoGroups['overdue'] || 0 }} Overdue</span>
        <span
          v-if="filterStatus"
          class="cursor-pointer border border-gray-400 px-2 py-1 hover:bg-gray-100"
          @click.stop.prevent="filterStatus = ''">
          x Clear Filter
        </span>
        <v-spacer></v-spacer>
        <v-dialog
          ref="newTaskDialog"
          v-model="showNewTaskForm"
          max-width="500px"
          hide-overlay
          transition="dialog-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="primary"
              v-on="on">
              + New Task
            </v-btn>
          </template>
          <v-card>
            <v-toolbar flat dense>
              <span class="text-lg">New Task</span>
              <v-spacer></v-spacer>
              <div class="flex flex-row shrink space-x-2">
                <v-btn
                  small
                  text
                  @click.stop.prevent="showNewTaskForm = false"
                  >
                  <span>Cancel</span>
                </v-btn>
                <v-btn
                  small
                  class="primary"
                  @click.stop.prevent="saveTask">
                  <span>Save</span>
                </v-btn>
              </div>
            </v-toolbar>
            <v-card-text>
              <div
                class="flex flex-col space-y-2 my-4">
                <v-text-field
                  v-model="task.title"
                  dense
                  filled
                  rounded
                  hide-details
                  placeholder="Input the title"
                  class="w-full"
                  >
                </v-text-field>
                <div class="flex flex-row px-4 w-full items-center space-x-4">
                  <v-text-field
                    :value="formatDate(task.dueAt)"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    placeholder="Due Date"
                    @click="toggleDatePicker(task, $event)"
                    >
                  </v-text-field>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      
      <template v-if="todos.length > 0">
        <v-list dense>
          <template v-for="todo in filteredTasks">
            <v-list-item
              dense
              :key="todo.id"
              :class="[
                'hover:bg-gray-100',
                todo.status == 'completed' ? 'text-gray-200' : ''
              ]">
              <v-list-item-action class="mr-2">
                <v-checkbox
                  :value="task.status == 'done'"
                  class="p-0 m-0"
                  @change="onToggleTaskStatus(todo, $event)"
                  >
                  </v-checkbox>
              </v-list-item-action>
              <v-list-item-title>
                <span class="text-lg">{{ todo.title }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="truncate text-ellipsis" style="min-width: 120px">
                <span
                  class="inline-block mx-2 px-2 rounded border border-gray-400 cursor-pointer hover:bg-gray-200"
                  @click.stop.prevent="toggleDatePicker(null, $event)"
                  >
                  {{ formatDate(todo.dueAt) }}
                </span>
              </v-list-item-subtitle>
              
              <v-list-item-subtitle style="max-width:80px;">
                <v-chip small
                  text-color="white"
                  :color="
                    todo.status == 'progress' ? 'blue' : (todo.status == 'completed' ? 'green' :  'red')
                  ">
                  {{ todo.status }}
                </v-chip>
              </v-list-item-subtitle>
              <v-list-item-action>
                <v-btn icon @click.stop.prevent="onDeleteTask(todo)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </template>

      <template v-else>
        <div class="w-full text-center text-lg text-gray-400 my-4 py-4 border border-dashed">
          No todo task. Click to create a new one.
        </div>
      </template>
    </div>
    <v-dialog
      ref="datePickerDialog"
      :value="showDatePicker"
      :activator="datePickerActivator"
      :return-value.sync="task.dueAt"
      transition="scale-transition"
      width="500px"
      scrollable
      >
      <v-card>
        <v-card-title class="flex flex-row">
          <span class="glow">Date and time picker</span>
          <v-spacer></v-spacer>
          <div class="flex flex-row items-center space-x-2 shrink">
            <v-btn
              text
              @click.stop.prevent="showDatePicker = false"
              >
              Cancel</v-btn>
            <v-btn
              color="primary"
              @click.stop.prevent="$refs.datePickerDialog.save(task.dueAt)"
              >
              Save</v-btn>
          </div>
        </v-card-title>
        <v-card-text style="600px">
          <date-time-picker v-model="task.dueAt">
          </date-time-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import domtoimage from 'dom-to-image-more'
import mixin from '../mixin'
import DateTimePicker from './DateTimePicker.vue'

export default {
  name: 'TodoEditor',

  mixins: [ mixin ],

  components: {
    DateTimePicker
  },

  data() {
    return {
      showNewTaskForm: false,
      todos: [],
      filterStatus: '',
      showDatePicker: false,
      datePickerActivator: null,
      task: {
        title: '',
        dueAt: null,
        status: 'progress'
      }
    }
  },

  computed: {
    todoGroups() { return _.countBy(this.todos, 'status' )},
    filteredTasks() {
      if (this.filterStatus) {
        return _.filter(this.todos, t => t.status == this.filterStatus)
      } else {
        return this.todos
      }
    }
  },

  methods: {
    formatDate(date) {
      if (!date) {
        return 'Please select the due date & time'
      }
      if (typeof date == typeof '') {
        date = new Date(date)
      }
      if (date.getHours() == 0 && date.getMinutes() == 0) {
        return `Due at: ${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
      } else {
        return `Due at: ${date.getFullYear()}/${date.getMonth()}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
      }
    },

    toggleDatePicker(task, event) {
      this.datePickerActivator = event.target
      this.showDatePicker = true
      if (task) {
        this.task = task
      }
    },

    editTask(task) {
      this.task = task
    },

    resetTask() {
      this.task = {
        title: '',
        dueAt: null,
        status: 'progress'
      }
    },

    async saveTask() {
      try {
        let task = null
        if (this.task.id) {
          task = await this.task.save()
        } else {
          task = await this.$api.updateOrCreateObject('Todo', null, this.task)
        }
        this.todos.push(task)
        this.showNewTaskForm = false
        this.resetTask()
        this.note.add('todos', task)
        this.$emit('save', {})
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    async save() {
      this.$emit('save', {
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.todos-holder')
        return domtoimage.toPNG(el, {
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
      return ''
    },

    async onDeleteTask(task) {
      let yes = await this.$confirm('Are you sure to remove the task?')
      if (yes) {
        try {
          await task.destroy()
          let index = _.findIndex(this.todos, t => t.id == task.id)
          if (index > -1) {
            this.todos.splice(index, 1)
          }
          this.note.remove('todos', task)
          this.$emit('save', {})
        } catch(error) {
          console.error(error)
          await this.$notify(error.message)
        }
      }
    },

    onToggleTaskStatus(task, event) {
      if (event) {
        task.set('status', 'done')
      } else {
        task.set('status', 'progress')
      }
      this.$forceUpdate()
    }
  },

  async mounted() {
    await this.note.fetch({
      include: [
        'todos'
      ]
    })
    this.todos = this.note.todos
  },
}
</script>
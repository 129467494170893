let isEdge = () => {
  return navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
}

let isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

let isElectron = () => {

}

export default {
  isEdge,
  isSafari,
  isElectron
}

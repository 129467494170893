<template>
  <div
    class="mockup-editor w-full h-full flex flex-row relative place-content-center items-center"
    style="min-height:100%">
    <div class="mockup-canvas-holder w-4/5 h-full mx-auto my-12 border border-dotted">
      <page-canvas
        :page="activePage"
        :scale="scale"
        @move-elements="moveElements"
        @resize-elements="resizeElements"
        >
      </page-canvas>
    </div>

    <div class="absolute flex flex-row items-center top-2 w-full">
      <div class="flex flex-row shrink items-center space-x-2 p-1 rounded">
        <v-btn
          icon
          class="bg-gray-100"
          @click.stop.prevent="showPages = true"
          >
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
        <v-menu
          v-model="showWidgetList"
          :close-on-content-click="false"
          offset-y
          id="widgets-list"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              hide-details
              dense
              filled
              rounded
              clearable
              placeholder="Type to filter widgets"
              v-on="on"
              >
              <template v-slot:append>
                <v-tooltip
                  bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="cursor-pointer hover:bg-gray-300 rounded-circle"
                      >mdi-menu-swap-outline</v-icon>
                  </template>
                  Toggle widgets list board
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <div class="w-full h-full flex flex-col overflow-hidden">
            <mockup-widget-types-selector
              @new-element="addElement"
              @hide="showWidgetList = false"
              ></mockup-widget-types-selector>
          </div>
        </v-menu>
      </div>
      <v-spacer class="grow"></v-spacer>
      <div class="flex flex-row shrink space-x-2 items-center">
        <v-btn icon class="bg-gray-100">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-select
          dense
          filled
          hide-details
          rounded
          :items="zoomPercents"
          v-model="zoomPercent"
          style="width:132px"
        ></v-select>
        <v-btn icon class="bg-gray-100">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      v-if="showPages"
      class="absolute w-full h-full top-0 left-0 overflow-hidden bg-gray-100 flex flex-row place-content-center">
      <div
        class="absolute top-2 left-2 flex flex-row items-center hover:bg-gray-300 px-2 py-1 rounded-md cursor-pointer"
        @click.stop.prevent="showPages = false">
        <v-icon>mdi-chevron-double-left</v-icon>
        <span>Back to edtior</span>
      </div>
      <div class="w-4/5 flex flex-col shrink overflow-y-auto my-10">
        <div class="w-full py-2">
          Pages List
        </div>
        <div class="flex flex-row flex-wrap grow gap-4 place-items-start m-0">
          <div 
            class="flex w-1/5 mx-2 place-content-center items-center hover:bg-gray-200 border-dashed border-2 border-gray-300 cursor-pointer"
            style="height:220px;max-width:200px;min-width:180px;"
            @click.stop.prevent="onNewPage">
            <v-icon>mdi-plus</v-icon>
            <span>New Page</span>
          </div>
          <template v-for="page in pages">
            <div
              :key="page.id"
              class="w-1/5 mx-2 hover:bg-gray-300 cursor-pointer text-center border-1 border-gray-300"
              style="height:220px;max-width:200px;min-width:180px;"
              @click.stop.prevent="selectPage(page)">
              <v-img
                src="https://picsum.photos/350/165?random"
                height="190"
                >
              </v-img>
              <span>{{ page.title }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import domtoimage from 'dom-to-image-more'
import PageCanvas from './PageCanvas.vue'
import MockupWidgetTypesSelector from './WidgetTypeSelector.vue'
import UndoHistory from '../undo'
import mixin from '../mixin'

export default {
  name: 'MockupEditor',

  mixins: [mixin],

  components: {
    PageCanvas,
    MockupWidgetTypesSelector
  },

  data() {
    return {
      showWidgetList: false,
      // pages
      showPages: false,
      activePage: null,
      pages: [],
      pageUndos: {
      },
      // view zoom
      zoomPercents: [
        '200%', '150%', '100%', '80%', '60%', '50%'
      ],
      zoomPercent: 1.0
    }
  },

  computed: {
    scale() {
      return this.zoomPercent
    }
  },

  methods: {
    selectPage(page) {
      this.showPages = false
      this.activePage = page
      if (!_.get(this.pageUndos, page.id)) {
        this.pageUndos[page.id] = new UndoHistory()
      }
    },

    onNewPage() {
      let page = this.createPage()
      this.selectPage(page)
      this.showPages = false
    },

    createPage() {
      let page = {
        id: `page-${ nanoid() }`,
        title: 'New Page',
        description: '',
        elements: {}
      }
      this.pages.push(page)
      return page
    },

    /**
     * Move selected elements, moves is in array format,
     * and each item of the array is in { elementId, pos } format
     */
    moveElements(moves) {
      let page = this.activePage
      let undoHistory = _.get(this.pageUndos, page.id)
      let oldPositions = []
      let elements = moves.map(move => _.get(page.elements, move.elementId))
      undoHistory.push({
        redo: () => {
          oldPositions = []
          moves.forEach((move, index) => {
            let element = elements[index]
            oldPositions.push({
              x: element.geom.x,
              y: element.geom.y
            })
            element.geom.x = move.pos.x
            element.geom.y = move.pos.y
          })
        },
        undo: () => {
          elements.forEach((element, index) => {
            element.geom.x = oldPositions[index].x
            element.geom.y = oldPositions[index].y
          })
        }
      })
    },

    /**
     * Resize selected elements, resizes is in array format,
     * and each resize item is in format: { elementId, size }
     */
    resizeElements(resizes) {
      let page = this.activePage
      let undoHistory = _.get(this.pageUndos, page.id)
      let oldSizes = []
      let elements = resizes.map(resize => _.get(page.elements, resize.elementId) )
      undoHistory.push({
        redo: () => {
          oldSizes = []
          resizes.forEach((resize, index) => {
            let element = elements[index]
            if (element) {
              oldSizes.push({
                x: element.geom.x,
                y: element.geom.y,
                w: element.geom.w,
                h: element.geom.h
              })
              element.geom.x = resize.geom.x
              element.geom.y = resize.geom.y
              element.geom.w = resize.geom.w
              element.geom.h = resize.geom.h
            }
          })
        },

        undo: () => {
          elements.forEach((element, index) => {
            element.geom.w = oldSizes[index].w
            element.geom.h = oldSizes[index].h
            element.geom.x = oldSizes[index].x
            element.geom.y = oldSizes[index].y
          })
        }
      })
      
    },

    updateElements(input) {
      let page = this.activePage
      let undoHistory = _.get(this.pageUndos, page.id)
      let elements = input.elementIds.map(id => _.get(page.elements, id))
      let oldProps = []
      undoHistory.push({
        redo: () => {
          oldProps = []
          elements.forEach((element, index) => {
            let updates = input[index].updates
            let oldValues = []
            updates.forEach(update => {
              let { path, value } = update
              oldValues.push({
                path,
                value: _.get(element, path)
              })
              _.set(element, path, value)
            })
            oldProps.push(oldValues)
          })
        },
        undo: () => {
          elements.forEach((element, index) => {
            oldProps[index].forEach(prop => {
              let { path, value } = prop
              _.set(element, path, value)
            })
          })
        }
      })
      let { elementIds, updates } = input
      elementIds.forEach(id => {
        let element = _.get(page.elements, id)
        if (element) {
          updates.forEach(update => {
            let { path, value } = update
            _.set(element, path, value)
          })
        }
      })
    },

    addElement(type, pos, size) {
      console.log('add element:' + type)
      let page = this.activePage
      if (!page) {
        return
      }
      let newElement = {
        id: `el-${nanoid()}`,
        type: type.key,
        geom: {
          x: pos ? pos.x : 100,
          y: pos ? pos.y : 200,
          w: size ? size.width : 100,
          h: size ? size.height : 50
        }
      }
      let undoHistory = _.get(this.pageUndos, page.id)
      undoHistory.push({
        redo: () => {
          console.log('perform adding')
          Vue.set(page.elements, newElement.id, newElement)
          console.log(page)
        },

        undo: () => {
          if (_.has(page.elements, newElement.id)) {
            delete page.elements[newElement.id]
          }
        }
      })
    },

    removeElements(elements) {
      let page = this.activePage
      if (!page) {
        return
      }

      let removedElements = elements.map( el => {
        let id = el
        if (_.isObject(el)) {
          id = el.id
        }
        return _.get(page.elements, id, null)
      }).filter( e => !!e )

      if (removedElements.length == 0) {
        console.log('No element specified to remove')
        return
      }

      let undoHistory = _.get(this.pageUndos, page.id)
      undoHistory.push({
        redo: () => {
          removedElements.forEach(element => {
            delete page.elements[element.id]
          })
        },

        undo: () => {
          removedElements.forEach(element => {
            page.elements[element.id] = element
          })
        }
      })
    },

    redo() {
      let undoHistory = _.get(this.pageUndos, this.activePage.id)
      if (undoHistory) {
        undoHistory.redo()
      }
    },

    undo() {
      let undoHistory = _.get(this.pageUndos, this.activePage.id)
      if (undoHistory) {
        undoHistory.undo()
      }
    },

    async save() {
      this.$emit('save', {
        data: '',
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.mockup-canvas-holder').firstChild
        return await domtoimage.toPNG(el, {
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
      return ''
    }
  },

  mounted() {
    if (this.pages.length == 0) {
      this.createPage()
    }
    this.selectPage(this.pages[0])
  },
}
</script>

<style scoped>

.mockup-editor {
  background: linear-gradient(90deg, white 21px, transparent 1%) center, linear-gradient(white 21px, transparent 1%) center, black;
  background-size: 22px 22px;
}

.widgets-list >>> .v-menu__content {
  overflow: hidden;
}


</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center space-y-4 w-full h-full overflow-hidden"},[(_vm.showUploader)?_c('div',{staticClass:"remotefile-uploader h-full w-1/2"},[_c('dashboard',{attrs:{"uppy":_vm.uppy,"plugins":[
        'GoogleDrive',
        'Dropbox',
        'Box',
        'Instagram',
        'Facebook',
        'OneDrive',
        'Url' ],"props":_vm.config}})],1):_vm._e(),_c('div',{class:[
      'remotefile-viewer relative h-full flex flex-col py-8 overflow-hidden',
      _vm.showUploader ? 'w-1/2' : 'w-full'
    ]},[_c('div',{staticClass:"absolute top-0 left-0 mx-1"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showUploader = !_vm.showUploader}}},[_c('span',[_vm._v("<<Upload files")])])],1),_c('div',{staticClass:"flex flex-col place-content-center items-center space-y-2 pb-4 w-full shrink"},[_vm._v(" Uploaded files: "+_vm._s(_vm.uploadedFiles ? _vm.uploadedFiles.length : 0)+" files ")]),(!_vm.uploadedFiles || _vm.uploadedFiles.length == 0)?[_c('div',{staticClass:"w-full text-center py-2 text-lg"},[_vm._v("No uploaded file")])]:[_c('div',{staticClass:"flex flex-col grow px-2 overflow-auto",staticStyle:{"height":"calc(100% - 38px)"}},[_vm._l((_vm.uploadedFiles),function(file){return [_c('div',{key:file.id,staticClass:"px-2 py-1 flex flex-row items-center shrink space-x-2 hover:bg-gray-200"},[_c('v-icon',[_vm._v("mdi-attachment")]),_c('span',{staticClass:"grow"},[_vm._v(_vm._s(file.name))]),_c('span',{},[_vm._v(_vm._s(_vm._f("prettyBytes")(file.size)))]),_c('span',{staticClass:"w-24"},[_vm._v(_vm._s(file.status))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDownloadFile(file)}}},[_c('v-icon',[_vm._v("mdi-cloud-download-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDeleteFile(file)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]})],2)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-x
    :min-width="300">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          icon
          class="bg-gray-100"
          v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>
    <div class="pen-picker-holder flex flex-col bg-white px-4 py-2">
      <span>Pen Color</span>
      <div class="grid grid-cols-7">
        <template v-for="color in colors">
          <div
            :key="color"
            :class="[
              'cursor-pointer hover:border-2 border-gray-500 rounded-full flex place-content-center items-center',
              pen.color == color ? 'border-2 border-blue-500' : ''
            ]"
            style="width: 24px; height: 24px;"
            @click.stop.prevent="selectColor(color)">
            <div class="rounded-full w-4 h-4"
              :style="{ backgroundColor: color }">
            </div>
          </div>
        </template>
      </div>
      <span class="mt-3">Pen Size</span>
      <div class="grid grid-cols-10 p-2 justify-items-center	items-center">
        <template v-for="size in sizes">
          <div
            :key="size"
            :class="[
              'cursor-pointer hover:border-2 border-gray-500 rounded-full flex place-content-center items-center',
              pen.size == size ? 'border-2 border-blue-500' : ''
            ]"
            style="width: 24px; height: 24px;"
            @click.stop.prevent="selectSize(size)">
            <div
              class="rounded-full bg-black"
              :style="{ width: `${size}px`, height: `${size}px` }">
            </div>
          </div>
        </template>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'PenSelector',

  props: {
    selectPen: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showMenu: false,
      pen: this.selectPen,
      colors: [
        '#000000', '#ff5252', '#ffbc00', '#00c853', '#00b0ff', '#d500f9', '#8d6e63',
        '#fafafa', '#a52714', '#ee8100', '#558b2f', '#01579b', '#8e24aa', '#4e342e',
        '#90a4ae', '#ff4081', '#ff6e40', '#aeea00', '#304ffe', '#7c4dff', '#1de9b6',
        '#cfd8dc', '#f8bbd0', '#ffccbc', '#f0f4c3', '#9fa8da', '#d1c4e9', '#b2dfdb'
      ],
      sizes: [
        1, 2, 4, 6, 8, 10, 12, 14, 16, 18
      ]
    }
  },

  methods: {
    selectSize(size) {
      this.pen.size = size
      this.$emit('change', this.pen)
    },

    selectColor(color) {
      this.pen.color = color
      this.$emit('change', this.pen)
    },

    close() {
      this.showMenu = false
    }
  }
}
</script>

<template>
  <v-menu
    :min-width="480"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        small
        class="mx-auto"
        color="primary"
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <note-new-selector
      @select="$emit('select', $event)">
    </note-new-selector>
  </v-menu>
</template>

<script>
import NoteNewSelector from './NoteNewSelector.vue'

export default {
  name: 'NoteNewMenu',

  components: {
    NoteNewSelector
  }
}
</script>
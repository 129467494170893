<template>
  <div class="w-full h-full flex flex-row overflow-hidden bg-gray-50">
    <div class="w-1/4 px-4 py-2 h-full flex flex-col overflow-y-auto overflow-x-hidden">
      <div class="w-full flex flex-col shrink">
        <div
          class="w-full flex flex-row space-x-2 px-2 py-2 bg-gray-100 cursor-pointer"
          @click.stop.prevent="showCropAndRotate = !showCropAndRotate"
          >
          <v-icon>mdi-crop-rotate</v-icon>
          <span>Crop and rotate</span>
        </div>
        <div v-if="showCropAndRotate" class="w-full flex flex-col grow">
          <div class="flex flex-row w-full items-center space-x-2">
            <span class="grow">Shape</span>
            <div class="flex flex-row gap-2 shrink">
              <v-btn icon>
                <v-icon>mdi-crop-landscape</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-crop-portrait</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-crop-square</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-crop-free</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="flex flex-row w-full items-center space-x-2">
            <span class="grow">Rotate</span>
            <div class="flex flex-row gap-2 shrink">
              <v-btn icon>
                <v-icon>mdi-flip-horizontal</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-flip-vertical</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-format-rotate-90</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col shrink">
        <div
          class="flex flex-row space-x-2 px-2 py-2 bg-gray-100 cursor-pointer"
          @click.stop.prevent="showFilters = !showFilters"
          >
          <v-icon>mdi-tune</v-icon>
          <span>Filters</span>
        </div>
        <div v-if="showFilters" class="flex flex-col space-y-1 grow">
          <template v-for="filter in filters">
            <template v-if="filter.items">
              <div :key="filter.name" class="flex flex-col">
                <span class="px-2 font-bold">{{ filter.name }}</span>
                <div class="flex flex-col space-y-1">
                  <template v-for="item in filter.items">
                    <image-filter-edit
                      :key="item.key"
                      :filter="item">
                    </image-filter-edit>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <image-filter-edit :key="filter.key" :filter="filter">
              </image-filter-edit>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="canvas-holder w-3/4 h-full flex place-content-center overflow-auto">
      <canvas class="bg-transparent"/>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric"
import 'fabric-history'
import ImageFilterEdit from './FilterEdit'

// let $ = function(id){return document.getElementById(id)}

export default {
  name: 'ImageEditor',

  props: {
    image: {
      type: [String, Object],
      required: true
    }
  },

  components: {
    ImageFilterEdit
  },

  data() {
    return {
      canvas: null,
      loadedImage: null,
      webglFilterBackend: null,
      canvas2dBackend: null,
      showCropAndRotate: true,
      cropShapes: [
        'circle',
        'square',
        'triangle',
        'rectangle',
        'polygon'
      ],
      showFilters: true,
      filters: [
        {
          key: 'grayscale',
          name: 'GrayScale',
          enabled: false,
          type: 'enum',
          enum: ['average', 'lightness', 'luminosity'],
          value: 'average'
        },
        {
          key: 'invert',
          name: 'Invert',
          enabled: false,
          type: 'boolean',
          value: false
        },
        { key: 'sepia', name: 'Sepia', enabled: false, type: 'boolean', value: false },
        { key: 'blackwhite', name: 'Black/White', enabled: false, type: 'boolean', value: false },
        { key: 'brownie', name: 'Brownie', enabled: false, type: 'boolean', value: false },
        { key: 'vintage', name: 'Vintage', enabled: false, type: 'boolean', value: false },
        { key: 'kodachrome', name: 'Kodachrome', enabled: false, type: 'boolean', value: false },
        { key: 'technicolor', name: 'Technicolor', enabled: false, type: 'boolean', value: false },
        { key: 'polaroid', name: 'Polaroid', enabled: false, type: 'boolean', value: false },
        {
          // group type value
          key: 'removecolor',
          name: 'Remove Color',
          type: 'group',
          options: [
            { key: 'color', name: 'Color', type: 'color', value: '#00ff00' },
            { key: 'distance', name: 'Distance', type: 'number', min: 0, max: 1, value: 0.02, step: 0.01 },
          ]
        },
        {
          key: 'brightness',
          name: 'Brightness',
          type: 'number',
          min: -1,
          max: 1,
          value: 0.1,
          step: 0.003921
        },
        {
          key: 'gamma',
          name: 'Gamma',
          type: 'group',
          options: [
            { key: 'red', name: 'Red', type: 'number', min: 0.2, max: 2.2, value: 1, step: 0.003921 },
            { key: 'green', name: 'Green', type: 'number', min: 0.2, max: 2.2, value: 1, step: 0.003921 },
            { key: 'blue', name: 'Blue', type: 'number', min: 0.2, max: 2.2, value: 1, step: 0.003921 }
          ]
        },
        {
          key: 'contrast',
          name: 'Contast',
          type: 'number',
          min: -1,
          max: 1,
          value: 0,
          step: 0.003921
        },
        {
          key: 'saturation',
          name: 'Saturation',
          type: 'number',
          min: -1,
          max: 1,
          value: 0,
          step: 0.003921
        },
        {
          key: 'vibrance',
          name: 'Vibrance',
          type: 'number',
          min: -1,
          max: 1,
          value: 0,
          step: 0.003921
        },
        {
          key: 'hue',
          name: 'Hue',
          type: 'number',
          min: -2,
          max: 2,
          value: 0,
          step: 0.002
        },
        {
          key: 'noise',
          name: 'Noise',
          type: 'number',
          min: 0,
          max: 1000,
          value: 100,
          step: 10
        },
        {
          key: 'pixelate',
          name: 'Pixelate',
          type: 'number',
          min: 2,
          max: 20,
          value: 4,
          step: 1
        },
        {
          key: 'blur',
          name: 'Blur',
          type: 'number',
          min: 0,
          max: 1,
          value: 0.1,
          step: 0.01
        },
        {
          key: 'shapen',
          name: 'Shapen',
          enabled: false,
          type: 'boolean',
          value: false
        },
        {
          key: 'Emboss',
          name: 'Emboss',
          enabled: false,
          type: 'boolean',
          value: false
        },
        {
          key: 'blend',
          name: 'Blend Color',
          enabled: false,
          type: 'group',
          options: [
            { key: 'mode', name: 'Mode', type: 'enum', enum: ['add', 'diff', 'subtract', 'multiply', 'screen', 'lighten', 'darken', 'overlay', 'exclusion', 'tint'], value: 'add' },
            { key: 'color', name: 'Color', type: 'color', value: '#ffffff' },
            { key: 'alpha', name: 'Alpha', type: 'number', min: 0, max: 1, step: 0.01, value: 1 },
          ]
        }
      ]
    }
  },

  methods: {
    initialize() {
      // manually initialize 2 filter backend to give ability to switch:
      try {
        this.webglBackend = new fabric.WebglFilterBackend()
      } catch (e) {
        console.log(e)
      }
      this.canvas2dBackend = new fabric.Canvas2dFilterBackend()

      // fabric.filterBackend = fabric.initFilterBackend()
      if (this.webglFilterBackend) {
        fabric.filterBackend = this.webglFilterBackend
      } else {
        fabric.filterBackend = this.canvas2dBackend
      }
      fabric.Object.prototype.transparentCorners = false

      fabric.Object.prototype.padding = 5
      fabric.Object.prototype.transparentCorners = false

      let canvasEl = this.$el.querySelector('canvas')
      this.canvas = new fabric.Canvas(canvasEl)

      fabric.Image.fromURL(this.image.src, (img) => {
        this.loadedImage = img
        this.resize()
      })
    },

    resize() {
      let img = this.loadedImage
      if (!img) {
        return
      }
      let holderEl = this.$el.querySelector('.canvas-holder')
      let rect = holderEl.getBoundingClientRect()
      this.canvas.setWidth(rect.width)
      this.canvas.setHeight(rect.height)
      let imageWidth = img.width
      let imageHeight = img.height
      let scaleW = 1.0
      let scaleH = 1.0
      if (rect.width < imageWidth) {
        scaleW = rect.width / imageWidth
      }
      if (rect.height < imageHeight) {
        scaleH = rect.height / imageHeight
      }
      let scale = Math.min(scaleW, scaleH)
      imageWidth = imageWidth * scale
      imageHeight = imageHeight * scale
      img.set({
        left: (rect.width - imageWidth) / 2.0,
        top: (rect.height - imageHeight) / 2.0
      }).scale(scale)
      img.selectable = false
      if (!this.canvas.item(0)) {
        this.canvas.add(img)
      }
    },

    applyFilter(index, filter) {
      let obj = this.canvas.item(0)
      obj.filters[index] = filter
      obj.applyFilters()
      this.canvas.renderAll()
    },

    getFilter(index) {
      let obj = this.canvas.item(0)
      return obj.filters[index]
    },

    applyFilterValue(index, prop, value) {
      let obj = this.canvas.item(0)
      if (obj.filters[index]) {
        obj.filters[index][prop] = value
        obj.applyFilters()
        this.canvas.renderAll()
      }
    }
  },

  mounted() {
    this.initialize()
    this.resizeObserver = new ResizeObserver(() => {
      this.resize()
    })
    this.resizeObserver.observe(this.$el.querySelector('.canvas-holder'))
  },

  beforeDestroy() {
    this.resizeObserver && this.resizeObserver.disconnect()
  },
}
</script>
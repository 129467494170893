import _ from 'lodash'
import * as api from '@/api/core'

export default  {
  namespaced: true,
  state: {
    items: [],
    inited: false
  },

  mutations: {
    setLabels(state, labels) {
      state.items = labels
    },

    removeLabel(state, label) {
      let index = _.findIndex(state.items, x => x.id == label.id)
      if (index > -1) {
        state.items.splice(index, 1)
      }
    },

    addLabel(state, label) {
      let index = _.findIndex(state.items, x => x.id == label.id)
      if (index < 0) {
        state.items.push(label)
      }
    }
  },

  actions: {
    async load({ state }, options) {
      let { forceFetch } = options || {}
      if (state.inited && !forceFetch) {
        return
      }

      if (state.inited) {
        state.items.splice(0)
      }

      let results= await api.getQuery('Label')
        .equalTo('owner', api.currentUser())
        .select('text', 'color', 'notesCount')
        .find()
      
      results && results.map(r => {
        state.items.push(r)
      })
      state.inited = true
    }
  }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'flex flex-col flex-nowrap shrink items-start space-y-4', 
    _vm.level == 1 ? 'mindmap-node-root' : ''
  ]},[_c('div',{ref:"root",class:[
      'relative flex flex-col shrink rounded cursor-pointer hover:shadow-xl',
      _vm.level == 1 ? 'text-lg py-4' : '',
      _vm.level == 2 ? 'py-2': '',
      _vm.level > 2 ? 'text-sm py-1 border-b' : ''
    ],style:({
      textColor: _vm.level == 1 ? _vm.styleConfig.primaryTextColor : ( _vm.level == 2 ? _vm.styleConfig.secondaryTextColor: 'gray' ), 
      backgroundColor: _vm.level == 1 ? _vm.styleConfig.primaryFillColor : ( _vm.level == 2 ? _vm.styleConfig.secondaryFillColor: 'transparent' ) 
    }),attrs:{"tabindex":"0"},on:{"dblclick":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDoubleClick.apply(null, arguments)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick.apply(null, arguments)},"keydown":_vm.onKeyDown}},[_c('div',{staticClass:"flex flex-row px-4 space-x-1 items-center"},[_c('div',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.node.title)+" ")]),(_vm.editing)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onDoneEditing),expression:"onDoneEditing"}],staticClass:"absolute w-full h-full outline-none top-2"},[_c('v-text-field',{staticClass:"bg-white",attrs:{"dense":"","filled":"","rounded":"","hide-details":"","no-hints":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onDoneEditing.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onDoneEditing.apply(null, arguments)}]},model:{value:(_vm.node.title),callback:function ($$v) {_vm.$set(_vm.node, "title", $$v)},expression:"node.title"}})],1):_vm._e()]),(_vm.selected)?_c('div',{staticClass:"absolute border-2 border-blue-400 -top-1 -left-1 rounded",staticStyle:{"pointer-events":"none","width":"calc(100% + 8px)","height":"calc(100% + 8px)"}}):_vm._e(),(_vm.node.children.length > 0)?[_c('div',{staticClass:"absolute",style:({
          left: ("calc(50% - " + (_vm.anchorSize / 2.0) + "px)"),
          top: '100%'
        }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.collapsed = !_vm.collapsed}}},[_c('button',{staticClass:"rounded-full border bg-white text-gray-500 place-content-center hover:bg-gray-200 text-xs shadow-md outline-none",style:({
            width: (_vm.anchorSize + "px"),
            height: (_vm.anchorSize + "px"),
          })},[(_vm.collapsed)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("-")])])])]:_vm._e()],2),(_vm.node.children.length > 0 && !_vm.collapsed)?[_c('div',{ref:"childrenHolder",staticClass:"relative flex flex-row p-0 m-0",style:({ left: (_vm.childrenOffsetX + "px") })},[_c('svg',{ref:"lineHolder",attrs:{"preserveaspectratio":"none","fill":"none","xmlns":"http://www.w3.org/2000/svg","height":(_vm.childrenHeight + "px"),"width":"24px"}},[_vm._l((_vm.segments),function(seg,index){return [_c('path',{key:("seg-" + index),attrs:{"d":("M" + (seg.x1) + " " + (seg.y1) + " L" + (seg.x2) + " " + (seg.y2)),"stroke":"#C5CCD0","stroke-width":"2px"}})]})],2),_c('div',{ref:"childHolder",class:[
          'flex flex-col shrink items-start',
          _vm.level == 1 ? 'space-y-6' : '',
          _vm.level == 2 ? 'space-y-4' : '',
          _vm.level > 2 ? 'space-y-2' : ''
        ]},_vm._l((_vm.node.children),function(child){return _c('tree-node',{key:child.id,ref:"childrenNodes",refInFor:true,attrs:{"node":child,"level":_vm.level+1,"style-config":_vm.styleConfig},on:{"select":function($event){return _vm.$emit('select', $event)},"create":function($event){return _vm.$emit('create', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"navigate":function($event){return _vm.$emit('navigate', $event)},"bind":function($event){return _vm.$emit('bind', $event)},"unbind":function($event){return _vm.$emit('unbind', $event)}}})}),1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
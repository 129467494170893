<template>
  <div class="w-full flex flex-col items-start bg-white overflow-hidden" style="height: calc(100vh - 160px)">
    <div class="flex flex-row items-center px-4 pt-4 pb-2 w-full shrink">
      <span>Widgets List</span>
    </div>
    <div class="w-full flex flex-col pb-4 overflow-y-auto overflow-x-hidden px-4" style="height: calc(100% - 48px);">
      <div class="flex flex-col">
        <template v-for="widget in widgets">
          <div
            class="relative px-4 hover:bg-gray-200 flex flex-row items-center space-x-2 cursor-pointer"
            :key="widget.key"
            style="min-width: 200px;min-height:32px;"
            @click.stop.prevent="onAddElement(widget)">
            <div class="py-2 grow" v-html="widget.demo">
            </div>
            <div>
              {{ widget.name }}
            </div>
            <div class="absolute w-full h-full top left">
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import 'wired-elements'
import { WidgetTypes } from './WidgetTypes'

export default {
  name: 'WidgetTypeSelector',

  data() {
    return {
      widgets: WidgetTypes
    }
  },

  methods: {
    onAddElement(widget) {
      this.$emit('new-element', widget)
      this.$emit('hide')
    }
  }
}
</script>

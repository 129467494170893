<template>
  <div class="flex flex-row items-center space-y-4 w-full h-full overflow-hidden">
    <div v-if="showUploader" class="remotefile-uploader h-full w-1/2">
      <dashboard
        :uppy="uppy"
        :plugins="[
          'GoogleDrive',
          'Dropbox',
          'Box',
          'Instagram',
          'Facebook',
          'OneDrive',
          'Url',
        ]"
        :props="config"
      />
    </div>
    <div :class="[
        'remotefile-viewer relative h-full flex flex-col py-8 overflow-hidden',
        showUploader ? 'w-1/2' : 'w-full'
      ]">
      <div class="absolute top-0 left-0 mx-1">
        <v-btn text @click.stop.prevent="showUploader = !showUploader">
          <span>&lt;&lt;Upload files</span>
        </v-btn>
      </div>
      <div class="flex flex-col place-content-center items-center space-y-2 pb-4 w-full shrink">
        Uploaded files: {{ uploadedFiles ? uploadedFiles.length : 0 }} files
      </div>
      <template v-if="!uploadedFiles || uploadedFiles.length == 0">
        <div class="w-full text-center py-2 text-lg">No uploaded file</div>
      </template>
      <template v-else>
        <div
          class="flex flex-col grow px-2 overflow-auto"
          style="height: calc(100% - 38px);">
          <template v-for="file in uploadedFiles">
            <div
              :key="file.id"
              class="px-2 py-1 flex flex-row items-center shrink space-x-2 hover:bg-gray-200">
              <v-icon>mdi-attachment</v-icon>
              <span class="grow">{{ file.name }}</span>
              <span class="">{{ file.size | prettyBytes }}</span>
              <span class="w-24">{{ file.status }}</span>
              <v-btn icon @click.stop.prevent="onDownloadFile(file)">
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
              <v-btn icon @click.stop.prevent="onDeleteFile(file)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image-more'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
import GoogleDrive from '@uppy/google-drive'
import Dropbox from '@uppy/dropbox'
import Box from '@uppy/box'
import Instagram from '@uppy/instagram'
import Facebook from '@uppy/facebook'
import OneDrive from '@uppy/onedrive'
import Url from '@uppy/url'
import ProgressBar from '@uppy/progress-bar'
import XHRUpload from '@uppy/xhr-upload'
import Informer from '@uppy/informer'
import StatusBar from '@uppy/status-bar'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/informer/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import UploadTokenAuthPlugin from '@/components/uploader/UploadTokenAuthPlugin'
import mixin from '../mixin'

export default {
  name: 'UploadRemoteEditor',

  mixins: [mixin],

  components: {
    Dashboard
  },

  data() {
    return {
      config: {
        height: '100%',
        width: '100%',
        theme: 'light',
        disableLocalFiles: false
      },
      showUploader: false,
      uploadedFiles: []
    }
  },

  computed: {
    uppy() {
      return new Uppy({ id: 'uppy-remote-uploader', autoProceed: false, debug: true })
        .use(GoogleDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Dropbox, { companionUrl: this.$config.uppy.companionUrl })
        .use(Box, { companionUrl: this.$config.uppy.companionUrl })
        .use(Instagram, { companionUrl: this.$config.uppy.companionUrl })
        .use(Facebook, { companionUrl: this.$config.uppy.companionUrl })
        .use(OneDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Url, { companionUrl: this.$config.uppy.companionUrl })
        .use(XHRUpload, {
          endpoint: this.$config.uppy.endpoint,
          fieldName: 'uploads',
          formData: true,
          method: 'POST',
          metaFields: ['size'],
          headers: (file) => {
            return {
              authorization: `Bearer ${ file.meta.token }`,
            }
          }
        })
        .use(ProgressBar, {
          hideAfterFinish: true,
        })
        .use(Informer)
        .use(StatusBar, {
          hideAfterFinish: true,
          showProgressDetails: false,
          hideUploadButton: false,
          hideRetryButton: false,
          hidePauseResumeButton: false,
          hideCancelButton: false,
          doneButtonHandler: null
        })
        .use(UploadTokenAuthPlugin, {
          Parse: this.$Parse,
          note: this.note
        })
    }
  },

  methods: {
    async fileUploaded(attachments) {
      try {
        attachments.forEach(element => {
          this.note.add('attachments', element)
        })
      } catch(error) {
        console.error(error)
      }
    },

    async save() {
      // todo: data contains list of the image urls
      this.$emit('save', {
        data: '',
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.media-player')
        if (el) {
          return domtoimage.toPNG(el, {
            width: 300,
            height: 200
          })
        }
      } catch(error) {
        console.error(error)
      }
      return ''
    },

    async syncWithRemote() {
      if (this.note.id) {
        await this.note.fetchWithInclude(['attachments.size', 'attachments'])
      }
      this.uploadedFiles.splice(0, this.uploadedFiles.length)
      this.note.attachments.forEach(attachment => {
        this.uploadedFiles.push(attachment)
      })
    },

    async onDownloadFile(file) {
      try {
        let token = await this.$Parse.Cloud.run('getDownloadToken', {
          key: file.key
        })
        let user = this.$Parse.User.current()
        let url = `http://localhost:3030/files/${user.id}/${file.key}`
        console.log(url)
        await fetch(url, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            authorization: `Beazer ${ token }`
          }
        })
      } catch(error) {
        console.error(error)
      }
    },

    async onDeleteFile(file) {
      try {
        let yes = await this.$confirm('Are you sure to remove the attachment? It is undoable.')
        if (yes) {
          await file.destroy()
          await this.syncWithRemote()
        }
      } catch(error) {
        console.error(error)
      }
    }
  },

  async mounted() {
    this.uppy.on('complete', async (result) => {
      console.log(result)
      await this.syncWithRemote()
    })

    await this.syncWithRemote()
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  }
}
</script>

<style scoped>

.remotefile-uploader div,
.remotefile-uploader >>> .uppy-Root,
.remotefile-uploader >>> .uppy-Dashboard
 {
  width: 100%;
  height: 100%;
}


</style>
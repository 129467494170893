<template>
  <div
    class="free-draw-editor relative flex flex-col w-full h-full overflow-hidden">
    <div class="w-full h-full overflow-auto bg-transparent">
      <canvas
        class="w-full h-full bg-transparent border border-dashed border-gray-500 rounded"
        />
    </div>
    <div class="absolute top left z-50 px-2 flex flex-row items-center space-x-2 py-1 bg-transparent">
      <pen-picker
        :select-pen="pen"
        @change="selectPen"
        >
      </pen-picker>
      <v-btn
        icon
        class="bg-gray-100"
        @click.stop.prevent="clear"
        >
        <v-icon>mdi-eraser</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric"
import 'fabric-history'
import PenPicker from './PenSelector.vue'
import mixin from '../mixin'

export default {
  name: 'DrawEditor',

  mixins: [ mixin ],

  components: {
    PenPicker
  },

  data() {
    return {
      canvas: null,
      resizeObserver: null,
      pen: {
        color: '#000000',
        size: 1
      }
    }
  },

  methods: {
    initialize() {
      let canvasEl = this.$el.querySelector('canvas')
      let rect = this.$el.getBoundingClientRect()

      this.canvas = new fabric.Canvas(canvasEl, {
        isDrawingMode: true,
        width: rect.width,
        height: rect.height
      })
      this.canvas.freeDrawingBrush.color = this.pen.color
      this.canvas.freeDrawingBrush.width = this.pen.size
      this.canvas.on('history:append', this.onChange.bind(this))
      fabric.Object.prototype.transparentCorners = false
      try {
        this.canvas.loadFromJSON(this.note.data)
      } catch(error) {
        console.error(error)
      }
      this.resizeObserver = new ResizeObserver(() => {
        this.resize()
      })

      this.resizeObserver.observe(this.$el)
    },

    selectPen(pen) {
      this.pen.color = pen.color
      this.pen.size = pen.size
      this.canvas.freeDrawingBrush.color = this.pen.color
      this.canvas.freeDrawingBrush.width = this.pen.size
    },

    clear() {
      this.canvas.clear()
    },

    resize() {
      this.$nextTick(() => {
        let rect = this.$el.getBoundingClientRect()
        if (this.canvas) {
          this.canvas.setWidth(rect.width)
          this.canvas.setHeight(rect.height)
        }
      })
    },

    onChange() {
      console.log('changing')
      this.$emit('change')
    },

    redo() {
      this.canvas.redo()
    },

    undo() {
      this.canvas.undo()
    },

    async save() {
      this.$emit('save', {
        data: JSON.stringify(this.canvas.toJSON()),
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        this.canvas.toDataURL({
          type: 'png',
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
    }
  },

  mounted() {
    this.initialize()
  },

  beforeDestroy() {
    this.resizeObserver && this.resizeObserver.disconnect()
  },
}
</script>

<style scoped>

.free-draw-editor {
  background: linear-gradient(90deg, white 21px, transparent 1%) center, linear-gradient(white 21px, transparent 1%) center, black;
  background-size: 22px 22px;
}

</style>
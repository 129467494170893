<template>
  <v-app light>
    <v-navigation-drawer
      permanent
      app
      :mini-variant="true"
      class="bg-gray-100"
      >
      <div class="flex flex-col h-full pt-2">
        <note-new-menu @select="createNote"></note-new-menu>
        <v-list
          nav
          dense
          class="mt-3 h-full"
        >
          <v-list-item
            link
            :to="{ name: 'user-notes' }"
            >
            <v-list-item-icon>
              <v-icon>mdi-note-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Notes</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'user-todos' }"
            >
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Todos</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'user-labels' }"
            >
            <v-list-item-icon>
              <v-icon>mdi-calendar-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Labels</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :to="{ name: 'user-trash' }"
            >
            <v-list-item-icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Trash</v-list-item-title>
          </v-list-item>
          <div style="height: calc(100% - 220px);"></div>
          <v-list-item
            link
            :to="{ name: 'user-account' }"
            >
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      dense
      flat
      app
      color="white"
      extension-height="32"
      class="pa-0 ma-0 flex-none">
      <v-app-bar-title>
        <v-subheader class="ma-0 pa-0">
          <span class="title mr-1">{{ title }}</span>
        </v-subheader>
      </v-app-bar-title>
      <v-spacer/>
      <v-text-field
        v-model="searchKeywords"
        dense
        clearable
        rounded
        filled
        hide-details
        placeholder="Search"
        class="pa-0"
        clear-icon=""
        ref="search"
        :append-icon="searching ? 'mdi-close': ''"
        @focus="showSearch"
        @keydown.esc="hideSearch"
        @click:append="hideSearch">
      </v-text-field>
      <v-spacer />
      <v-btn
        icon
        outlined
        class="border-none"
        @click.stop.prevent="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        icon
        outlined
        class="border-none"
        @click.stop.prevent="switchNotesViewMode">
        <v-icon v-if="notesViewMode == 'list'">mdi-view-grid-outline</v-icon>
        <v-icon v-else>mdi-view-agenda-outline</v-icon>
      </v-btn>
      <pinned-notes-list></pinned-notes-list>
    </v-app-bar>
    <div
      class="ml-14 mt-14 flex flex-col overflow-hidden"
      style="height: calc(100% - 48px);width: calc(100% - 56px);">
      <router-view  v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'" mode="out-in">
          <keep-alive>
            <suspense>
              <template #default>
                <component
                  :is="Component"
                  :key="route.meta.usePathKey ? route.path : undefined"
                />
              </template>
              <template #fallback> Loading... </template>
            </suspense>
          </keep-alive>
        </transition>
      </router-view>
    </div>
    <note-editor></note-editor>
  </v-app>
</template>

<script>
import NoteNewMenu from '@/components/NoteNewMenu'
import PinnedNotesList from '@/components/PinnedNotesList.vue'
import NoteEditor from '@/components/NoteEditor.vue'
// import SearchFilters from '@/components/SearchFilters.vue'

export default {
  name: 'Home',

  components: {
    NoteNewMenu,
    PinnedNotesList,
    NoteEditor,
    // SearchFilters
  },

  data() {
    return {
      searching: false
    }
  },

  computed: {
    notesViewMode() {
      return this.$store.state.notesViewMode
    },

    title() {
      let str = this.$route.meta.key
      if (str) {
        return str[0].toUpperCase() + str.slice(1)
      }
      return 'Account'
    },

    searchKeywords: {
      get() {
        return this.$store.state.searchKeywords
      },
      set(value) {
        this.$store.commit('setSearchKeywords', value)
      }
    }
  },

  methods: {
    async createNote(type) {
      try {
        let note = await this.$api.newObjectWithData('Note', {
          title: 'New note',
          type: type,
          data: ''
        })
        this.$eventBus.$emit('new.note', note)
        this.$eventBus.$emit('edit.note', note)
      } catch(error) {
        this.$notify(error.message)
      }
    },

    showSearch() {
      this.searching = true
      this.$router.push({ name: 'user-search'} )
    },

    hideSearch() {
      this.searching = false
      this.$refs.search.blur()
      this.$router.back()
    },

    switchNotesViewMode() {
      let mode = this.$store.state.notesViewMode
      if (mode == 'grid') {
        this.$store.commit('changeNotesViewMode', 'list' )
      } else {
        this.$store.commit('changeNotesViewMode', 'grid' )
      }
    },

    refresh() {
      this.$eventBus.$emit('refresh')
    }
  }
}
</script>


<style>

body, html {
  overflow: hidden;
}

.v-slide-group__prev,
.v-slide-group__next {
  min-width: 0 !important;
  flex: none !important;
  border-radius: 100% !important;
  background: transparent !important;
}

</style>
<template>
  <div
    ref="textEditor"
    class="editable absolute border-2 border-gray-400 rounded px-2 py-1 z-20 bg-white outline-none"
    contenteditable
    data-placeholder="Edit content"
    tabindex="0"
    :style="editStyles"
    @blur="doneEdit"
    @keydown.enter="onEnter"
    @keydown.esc.stop.prevent="onEsc"
    >
    text
  </div>
</template>

<script>
export default {
  name: 'TextEdit',

  props: {
    editingElement: {
      type: Object,
      required: false
    }
  },

  computed: {
    editStyles() {
      if (!this.editingElement) {
        return {
          display: 'none'
        }
      }

      let parentRect = this.$parent.$el.getBoundingClientRect()
      let targetRect = this.editingElement.el.getBoundingClientRect()
      let delta = 4
      return {
        top: `${targetRect.top - parentRect.top + delta }px`,
        left: `${targetRect.left - parentRect.left + delta}px`,
        width: `${ targetRect.width * 2 }px`,
        height: `${ targetRect.height * 2 }px`,
        display: this.editingElement ? 'block' : 'none'
      }
    }
  },

  methods: {
    doneEdit() {
      this.$emit('done-edit')
    },

    onEnter(event) {
      if (event.ctrlKey) {
        console.log('new line')
      } else {
        this.$emit('done-edit')
      }
    },

    onEsc() {
      this.$emit('done-edit')
    }
  },

  watch: {
    editStyles: {
      immediate: true,
      handler: function(newValue) {
        if (newValue.display == 'block') {
          console.log('set focus')
          this.$nextTick(() => {
            let el = this.$el
            el.focus()
            // select all text
            let range = null
            let sel = null
            if (window.getSelection && document.createRange) {
              range = document.createRange()
              range.selectNodeContents(el)
              sel = window.getSelection()
              sel.removeAllRanges()
              sel.addRange(range)
            } else if (document.body.createTextRange) {
              range = document.body.createTextRange();
              range.moveToElementText(el)
              range.select()
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>


.editable:empty:before {
    content: attr(data-placeholder);
}

</style>
<template>
  <div class="flex flex-row items-center w-full h-full overflow-hidden">
    <div class="media-record h-full w-1/2">
      <dashboard
        :uppy="uppy"
        :plugins="[
          'Audio',
          'Webcam',
          'ScreenCapture',
          'ProgressBar',
          'Informer',
          'StatusBar'
        ]"
        :props="config"
      />
    </div>
    <div class="media-player w-1/2 h-full flex flex-col overflow-hidden">
      <div class="shrink px-4 py-2">
        Recorded files
      </div>
      <div
        class="flex flex-col grow px-2 overflow-auto"
        style="height: calc(100% - 38px);">
        <template v-for="file in recordedFiles">
          <div
            :key="file.id"
            class="px-2 py-1 flex flex-row items-center shrink space-x-2 hover:bg-gray-200">
            <v-icon v-if="file.type == 'audio'">mdi-microphone-outline</v-icon>
            <v-icon v-else>mdi-video-box</v-icon>
            <span class="grow">Audio file</span>
            <v-btn icon>
              <v-icon>mdi-play-outline</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image-more'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
import Webcam from '@uppy/webcam'
import ScreenCapture from '@uppy/screen-capture'
import Audio from '@uppy/audio'
import ProgressBar from '@uppy/progress-bar'
import XHRUpload from '@uppy/xhr-upload'
import Informer from '@uppy/informer'
import StatusBar from '@uppy/status-bar'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/audio/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/screen-capture/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/informer/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import mixin from '../mixin'

export default {
  name: 'Uploader',

  mixins: [ mixin ],

  components: {
    Dashboard
  },

  data() {
    return {
      recordedFiles: [
        { id: 1, type: 'audio', src: '' },
        { id: 2, type: 'video', src: '' },
        { id: 3, type: 'video', src: '' },
        { id: 4, type: 'video', src: '' },
        { id: 5, type: 'video', src: '' },
        { id: 6, type: 'video', src: '' },
        { id: 7, type: 'video', src: '' },
        { id: 8, type: 'video', src: '' },
        { id: 9, type: 'video', src: '' },
        { id: 10, type: 'video', src: '' },
        { id: 11, type: 'video', src: '' }
      ],
      config: {
        note: 'Record audio/video/screen, less than 1 hour',
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
          { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
        ],
        height: '100%',
        width: '100%',
        theme: 'light',
        disableLocalFiles: true,
        locale: {
          strings: {
            importFiles: 'Choose to record audio/video/screen:'
          }
        }
      }
    }
  },

  computed: {
    uppy() {
      return new Uppy({ id: 'uppy-recorder', autoProceed: false, debug: true })
        .use(Webcam, {
          onBeforeSnapshot: () => Promise.resolve(),
          countdown: false,
          modes: [
            'video-audio',
            'video-only',
            'audio-only',
            'picture',
          ],
          mirror: true,
          videoConstraints: {
            facingMode: 'user',
            width: { min: 720, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 800, max: 1080 },
          },
          showRecordingLength: false,
          preferredVideoMimeType: null,
          preferredImageMimeType: null,
          locale: {},
        })
        .use(Audio, {
          showAudioSourceDropdown: true
        })
        .use(XHRUpload, {
          endpoint: this.$config.uppy.endpoint,
          fieldName: 'files',
          metaFields: [],
          headers: {}
        })
        .use(ScreenCapture, {
          displayMediaConstraints: {
            video: {
              width: 1280,
              height: 720,
              frameRate: {
                ideal: 3,
                max: 5,
              },
              cursor: 'motion',
              displaySurface: 'monitor',
            },
          },
          userMediaConstraints: {
            video: true,
            audio: true,
          },
          preferredVideoMimeType: 'video/webm'
        })
        .use(ProgressBar, {
          hideAfterFinish: true,
        })
        .use(Informer)
        .use(StatusBar, {
          hideAfterFinish: true,
          showProgressDetails: false,
          hideUploadButton: false,
          hideRetryButton: false,
          hidePauseResumeButton: false,
          hideCancelButton: false,
          doneButtonHandler: null
        })
    }
  },

  methods: {
    async fileUploaded(attachments) {
      try {
        attachments.forEach(element => {
          this.note.add('attachments', element)
        })
      } catch(error) {
        console.error(error)
      }
    },

    async save() {
      // todo: data contains list of the image urls
      this.$emit('save', {
        data: '',
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.media-player')
        if (el) {
          return domtoimage.toPNG(el, {
            width: 300,
            height: 200
          })
        }
      } catch(error) {
        console.error(error)
      }
      return ''
    }
  },

  mounted() {
    this.uppy.on('complete', result => {
      console.log(result)
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    })
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  }
}
</script>

<style scoped>

.media-record div,
.media-record >>> .uppy-Root,
.media-record >>> .uppy-Dashboard
 {
  width: 100%;
  height: 100%;
}


</style>
<template>
  <v-menu
    :value="true"
    :activator="activator"
    :close-on-content-click="false"
    :max-width="width"
    @keydown.esc="choose(false)">
    <v-card flat min-width="450px">
       <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="choose(true)">
          <v-icon>{{ icons.save }}</v-icon>&nbsp;
          {{ okButtonText }}
        </v-btn>
        <v-btn icon @click.stop="choose(false)">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="ma-0 pa-3" style="max-height: calc(100vh - 120px);">
        <template v-if="!!customWidget">
          <component
            :is="customWidget.component"
            v-bind="customWidget.props"
            @input="input">
          </component>
        </template>
        <template v-else>
          <template v-for="(field, index) in fields">
            <v-text-field
              :autofocus="index === 0 ? true: false"
              :key="field.label"
              :label="field.label"
              :value="field.value"
              @input="input(field.key, $event)"
              @keydown.enter="choose(true)">
            </v-text-field>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'PromptMenu',

  props: {
    activator: {
      type: [String, Object, HTMLElement],
      default: () => {
        return null;
      }
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    customWidget: {
      type: Object,
      default: () => {
        return null
      }
    },
    title: {
      type: String
    },
    okButtonText: {
      type: String,
      default: 'Submit'
    },
    width: {
      type: Number,
      default: 450
    }
  },

  data () {
    return {
      form: {},
      value: {
        response: false,
        data: {}
      },
      icons: {
        save: 'mdi-content-save-outline',
        close: 'mdi-close'
      }
    }
  },

  mounted () {
    document.addEventListener('keyup', this.onEnterPressed)
  },

  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },

  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(true)
      }
    },

    choose (response) {
      let result = {};
      this.fields.map((f) => {
        result[f.key] = f.value;
      });
      Object.assign(result, this.form);
      this.value['response'] = response;
      this.value['data'] = result;
      this.$emit('result', this.value);
      this.$destroy();
    },

    // text input changed
    input(key, value) {
      this.form[key] = value;
    }
  }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import folders from './folders'
import labels from './labels'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeUser: null,
    activeNote: null,
    pinnedNotes: [],
    searchKeywords: '',
    notesViewMode: 'grid',
    notesView: {
      activeFolder: null,
      activeLabels: [],
      activeTypes: [],
      viewCategoryNavigator: false,
      openFolders: []
    },
    todosView: {
      filters: [],
      activeLabels: []
    },
    trashView: {
      filters: [],
      activeLabels: []
    }
  },

  getters: {
    notePinned: (state) => (note) => {
      return _.findIndex(state.pinnedNotes, (n) => n.id == note.id) > -1
    }
  },

  mutations: {
    changeNotesViewMode(state, mode) {
      console.log('change mode here')
      console.log(mode)
      state.notesViewMode = mode
    },

    setActiveUser(state, user) {
      state.activeUser = user
    },
    
    setActiveNote(state, note) {
      if (!state.activeNote || state.activeNote.id !== note.id) {
        state.activeNote = note
      }
    },

    unsetActiveNote(state) {
      state.activeNote = null
    },

    pinNote(state, note) {
      if (!_.find(state.pinnedNotes, (x) => x.id == note.id)) {
        state.pinnedNotes.push(note)
      }
    },

    unpinNote(state, note) {
      let index = _.findIndex(state.pinnedNotes, (x) => x.id == note.id)
      if (index > -1) {
        state.pinnedNotes.splice(index, 1)
      }
    },

    togglePinNote(state, note) {
      let index = _.findIndex(state.pinnedNotes, (x) => x.id == note.id)
      if (index > -1) {
        state.pinnedNotes.splice(index, 1)
      } else {
        state.pinnedNotes.push(note)
      }
    },

    setSearchKeywords(state, keywords) {
      state.searchKeywords = keywords
    }
  },

  modules: {
    folders,
    labels
  }
})

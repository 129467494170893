<template>
  <div class="flex flex-col space-y-2 bg-white">
    <v-tabs v-model="tab">
      <v-tab>Date</v-tab>
      <v-tab>Time</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          >
        </v-date-picker>
      </v-tab-item>
      <v-tab-item>
        <v-time-picker
          v-model="time"
          format="24hr"
          ampm-in-title
          width="290px"
         >
         </v-time-picker>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'DateTimePicker',

  props: {
    value: {
      type: Date,
      required: false
    }
  },

  data() {
    return {
      tab: '',
      date: null,
      time: null
    }
  },

  mounted() {
    let date = this.value ? new Date(this.value) : new Date()
    this.date = `${ date.getFullYear() }-${ date.getMonth() + 1 }-${ date.getDate() }`
    this.time = `${ date.getHours() }:${ date.getMinutes() }`
    this.$watch('date', (newValue) => {
      if (!newValue) {
        return null
      }

      const [year, month, day] = newValue.split('-')
      date.setFullYear( parseInt(year) )
      date.setMonth( parseInt(month) )
      date.setDate( parseInt(day) )
      this.$emit('input', date)
    })
    this.$watch('time', (newValue) => {
      if (!newValue) {
        return null
      }

      const [hours, minutes] = newValue.split(':')
      date.setHours( parseInt(hours) )
      date.setMinutes( parseInt(minutes) )
      this.$emit('input', date)
    })
  },
}
</script>
<template>
  <div
    :class="[
      'flex flex-nowrap shrink place-content-center items-center',
      direction == 'right' ? 'flex-row' : 'flex-row-reverse',
      level == 1 ? 'mindmap-node-root' : ''
    ]">
    <div
      ref="root"
      :class="[
        'relative flex flex-col shrink px-4 cursor-pointer hover:shadow-xl',
        level == 1 ? 'text-lg py-4 rounded-full ' : '',
        level == 2 ? 'py-2 rounded-full ': '',
        level > 2 ? 'text-sm py-1 border-b-2' : ''
      ]"
      :style="{
        textColor: level == 1 ? styleConfig.primaryTextColor : ( level == 2 ? styleConfig.secondaryTextColor: 'gray' ), 
        backgroundColor: level == 1 ? styleConfig.primaryFillColor : ( level == 2 ? styleConfig.secondaryFillColor: 'transparent' ), 
        borderColor: styleConfig.linkColor
      }"
      tabindex=0
      @dblclick.stop.prevent="onDoubleClick"
      @click.stop.prevent="onClick"
      @keydown="onKeyDown">
      <div class="flex flex-row space-x-1 items-center">
        <div
          class="font-bold mx-4">
          {{ node.title }}
        </div>
        <div
          v-if="editing"
          class="absolute w-full h-full outline-none top-0 left-0"
          v-click-outside="onDoneEditing">
          <v-text-field
            v-model="node.title"
            dense
            filled
            rounded
            hide-details
            no-hints
            class="bg-white"
            @keydown.enter="onDoneEditing"
            @keydown.esc="onDoneEditing"
          ></v-text-field>
        </div>
      </div>
      <div
        v-if="selected"
        class="absolute border-2 border-blue-400 -top-1 -left-1 rounded-full"
        style="pointer-events: none;width: calc(100% + 8px); height: calc(100% + 8px)">
      </div>
      <template v-if="showCollapseButton">
        <template v-if="node.children.length > 0">
          <template v-if="direction == 'right'">
            <div
              class="absolute"
              :style="{
                left: '100%',
                top: `calc(50% - ${ anchorSize / 2 }px)`
              }">
              <button
                class="rounded-full border bg-white text-gray-500 flex place-content-center items-center hover:bg-gray-200 shadow-md outline-none"
                @click.stop.prevent="collapsed = !collapsed"
                :style="{
                  width: `${ anchorSize }px`,
                  height: `${ anchorSize }px`
                }"
                >
                <span v-if="collapsed">+</span>
                <span v-else>-</span>
              </button>
            </div>
          </template>
          <template v-else>
            <div
              class="absolute"
              :style="{
                left: `-${anchorSize}px`,
                top: `calc(50% - ${ anchorSize / 2 }px)`
              }">
              <button
                class="rounded-full border flex bg-white place-content-center items-center hover:bg-gray-200 text-xs shadow-md outline-none"
                @click.stop.prevent="collapsed = !collapsed"
                :style="{
                  width: `${ anchorSize }px`,
                  height: `${ anchorSize }px`
                }"
                >
                <span v-if="collapsed">+</span>
                <span v-else>-</span>
              </button>
            </div>
          </template>
        </template>
      </template>
    </div>
    <template v-if="node.children.length > 0 && !collapsed">
      <svg
        ref="lineSvg"
        preserveaspectratio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :height="`${childrenHeight}px`"
        :width="`${ offsetX }px`">
        <template v-for="(seg, index) in segments">
          <path
            :key="`seg-${index}`"
            :d="`M${seg.x1} ${seg.y1} C${seg.x1} ${seg.y1} ${ direction == 'left' ? seg.x2 + offsetX : seg.x2 - offsetX} ${seg.y2} ${seg.x2} ${seg.y2}`"
            :stroke="styleConfig.lineColor"
            :stroke-width="styleConfig.lineWidth"/>
        </template>
      </svg>
      <div
        ref="childrenHolder"
        :class="[
          'flex flex-col shrink-0 space-y-8',
          direction == 'right' ? 'items-start' : 'items-end' 
        ]">
        <logic-node
          v-for="child in node.children"
          :key="child.id"
          :node="child"
          :level="level+1"
          :direction="direction"
          :style-config="styleConfig"
          @select="$emit('select', $event)"
          @remove="$emit('remove', $event)"
          @create="$emit('create', $event)"
          @navigate="$emit('navigate', $event)"
          @bind="$emit('bind', $event)"
          @unbind="$emit('unbind', $event)"
          ref="childrenNodes">
        </logic-node>
      </div>
    </template>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'LogicNode',

  mixins: [mixin],

  props: {
    direction: {
      type: String,
      default: 'right'
    }
  },

  data() {
    return {
      offsetX: 48,
      fromPoint: { x: 0, y: 0 },
      childrenHeight: 0,
      sizeObserver: null
    }
  },

  computed: {
    segments() {
      let segs = []
      if (this.childAnchorPoints.length > 0) {
        this.childAnchorPoints.forEach(p => {
          segs.push({
            x1: this.fromPoint.x,
            y1: this.fromPoint.y,
            x2: p.x,
            y2: p.y
          })
        })
      }
      
      return segs
    }
  },

  methods: {
    getAnchorPoint() {
      let adjust = 1
      if (this.$el) {
        let rect = this.$el.firstChild.getBoundingClientRect()
        if (this.direction == 'left') {
          return {
            top: this.level > 2 ? rect.top + rect.height - adjust : rect.top + rect.height / 2.0,
            left: rect.right
          }
        } else {
          return {
            top: this.level > 2 ? rect.top + rect.height - adjust : rect.top + rect.height / 2.0,
            left: rect.left
          }
        }
      } else {
        return {
          top: 0,
          left: 0
        }
      }
    },

    doResize() {
      if (!this.$refs.childrenHolder) {
        return
      }
      this.childrenHeight = this.$refs.childrenHolder.getBoundingClientRect().height
      this.$nextTick(() => {
        let svgRect = this.$refs.lineSvg.getBoundingClientRect()
        let rootRect = this.$refs.root.getBoundingClientRect()
        if (this.direction == 'right') {
          // the start point
          this.fromPoint.x = 0
          this.fromPoint.y = rootRect.top - svgRect.top + rootRect.height / 2.0
        } else {
          this.fromPoint.x = svgRect.width
          this.fromPoint.y = rootRect.top - svgRect.top + rootRect.height / 2.0
        }

        // the child anchor points
        this.childAnchorPoints.splice(0, this.childAnchorPoints.length)
        for (let i = 0; i < this.$refs.childrenNodes.length; ++i) {
          let comp = this.$refs.childrenNodes[i]
          let point = comp.getAnchorPoint()
          this.childAnchorPoints.push({
            x: this.direction == 'left' ? 0 : svgRect.width,
            y: point.top - svgRect.top
          })
        }
      })
    }
  },

  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.childrenNodes) {
        this.doResize()

        // this.sizeObserver = new ResizeObserver(() => {
        //   this.doResize()
        // })
        // this.sizeObserver.observe(this.$el)
        clearInterval(interval)
      }
    }, 50)
    this.$emit('bind', { node: this.node, component: this })
  },

  destroyed() {
    if (this.sizeObserver) {
      this.sizeObserver.disconnect()
    }
    this.$emit('unbind', this.node)
  }
}
</script>
<template>
    <v-card>
      <v-card-title class="py-0 my-0">
        <v-subheader class="py-0 my-0 px-2 w-full flex">
          <span class="grow">Note Folder Selection</span>
          <div class="flex flex-row shrink space-x-2 items-center">
            <v-btn
              small
              color="success"
              :disabled="activeFolders.length == 0"
              @click.stop.prevent="select">
              Select
            </v-btn>
            <v-btn icon small @click.stop.prevent="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-subheader>
      </v-card-title>
      <v-card-text class="flex flex-col w-full">
        <v-text-field
          v-model="search"
          dense
          filled
          rounded
          hide-details
          clearable
          placeholder="Type to search"
          >
          </v-text-field>
        <div class="grow flex mt-4 w-full">
          <v-treeview
            dense
            activatable
            hoverable
            transition
            item-key="key"
            item-children="children"
            :search="search"
            :filter="filter"
            :items="categories"
            :active.sync="activeFolders"
            class="w-full"
            >
          </v-treeview>
        </div>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'NoteFolderSelector',

  data() {
    return {
      search: '',
      caseSensitive: false,
      activeFolders: []
    }
  },

  computed: {
    categories() {
      return this.$store.state.folders.items
    },

    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    }
  },

  methods: {
    select() {
      if (this.activeFolders.length == 0) {
        console.warn('No folder selected')
        return
      }
      let folder = this.$store.getters['folders/folder'](this.activeFolders[0])
      if (folder) {
        this.$emit('select-folder', folder)
      }
      this.close()
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>
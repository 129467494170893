export default {
  uppy: {
    maxFileSizeInBytes: 1024*1024*1024*10, // 10MB
    maxNumberOfFiles: 10, //
    minNumberOfFiles: 1,
    allowedFileTypes: '*',
    xhrupload: {
      fieldName: 'files'
    },
    endpoint: 'http://localhost:3030/upload',
    companionUrl: 'http://localhost:3030/companion'
  },
  parse: {
    appId: 'easynotes',
    masterKey: 'JG4gtaIQ6BmLULoNjIwMv1GPBBzA5XdD',
    javascriptKey: 'NRIXLB7bWPyJ1BAStMmhuF0jOpsKnddC',
    // server: 'https://pg-app-y5cphwu1ygfyk01xp53u0t8ziqrzis.scalabl.cloud/1/'
    server: 'http://localhost:1337/parse',
    // "xAItZe1qysU7aomEes9dWH1GUj4HoUkTOUJzAUdR",
    // "688nWlYPsSv9ZJncTRZ4KtGd4jNzASRUOkXUUQsH"
  },
  fileServer: {
    host: 'http://localhost:3030'
  }
};

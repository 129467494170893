<template>
  <div class="flex flex-row items-center space-y-4 w-full h-full overflow-hidden">
    <div v-if="showUploader" class="images-uploader h-full w-1/2">
      <dashboard
        :uppy="uppy"
        :plugins="[
          'GoogleDrive',
          'Dropbox',
          'Box',
          'Instagram',
          'Facebook',
          'OneDrive',
          'Url',
        ]"
        :props="uploaderConfig"
      />
    </div>
    <div
      :class="[
        'images-viewer relative h-full flex flex-col py-8 overflow-x-hidden overflow-y-auto',
        showUploader ? 'w-1/2' : 'w-full'
      ]">
      <div class="flex flex-col place-content-center items-center space-y-2 pb-4 w-full">
        <div>Images: {{ images.length }} images</div>
        <v-btn
          v-if="selectedImages.length > 0"
          outlined
          @click.stop.prevent="onRemoveSelectedImages"
          >
          Remove selected {{ selectedImages.length }} images
        </v-btn>
      </div>
      <div class="images-holder grid lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 grid-cols-1 justify-items-stretch gap-4 px-2">
        <template v-for="image in images">
          <div
            :key="image.id"
            class="image-group relative flex place-content-center items-center p-1 hover:bg-gray-100">
            <img
              src="https://picsum.photos/200/300"
              class="cursor-pointer"
              @click.stop.prevent="onToggleSelection(image)"
              />
            <div
              v-if="selectedImages.indexOf(image.id) > -1"
              class="absolute top-0 left-0 bg-green-600 rounded-full w-6 h-6 text-white">
              <v-icon color="white">mdi-check</v-icon>
            </div>
            <div class="image-controls absolute top-2 right-2">
              <v-btn
                class="bg-white"
                icon
                @click.stop.prevent="onEditImage(image)">
                <v-icon>mdi-image-edit-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div class="absolute top-0 left-0 mx-1">
        <v-btn text @click.stop.prevent="showUploader = !showUploader">
          <span>&lt;&lt;Upload images</span>
        </v-btn>
      </div>
    </div>
    <v-dialog
      :value="showImageEditor"
      fullscreen
      :scrollable="false"
      >
      <v-card
        class="overflow-hidden"
        style="height: calc(100vh);">
        <v-toolbar dense flat>
          <v-toolbar-title class="grow">Image Editor</v-toolbar-title>
          <div class="flex flex-row items-center space-x-2 shrink">
            <v-btn icon>
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-redo</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-btn icon @click.stop.prevent="showImageEditor = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text  
          class="relative w-full px-0 py-0 overflow-hidden"
          style="height: calc(100% - 48px);"
          >
          <div
            class="w-full h-full overflow-hidden flex items-center">
            <image-editor
              :image="activeImage"
              >
            </image-editor>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
import GoogleDrive from '@uppy/google-drive'
import Dropbox from '@uppy/dropbox'
import Box from '@uppy/box'
import Instagram from '@uppy/instagram'
import Facebook from '@uppy/facebook'
import OneDrive from '@uppy/onedrive'
import Url from '@uppy/url'
import ProgressBar from '@uppy/progress-bar'
import XHRUpload from '@uppy/xhr-upload'
import Informer from '@uppy/informer'
import StatusBar from '@uppy/status-bar'

import ImageEditor from '@/components/imageeditor'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/informer/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import mixin from '../mixin'

export default {
  name: 'ImagesEditor',

  mixins: [ mixin ],

  components: {
    Dashboard,
    ImageEditor
  },

  data() {
   return {
      activeImage: null,
      selectedImages: [],
      showUploader: false,
      showImageEditor: false,
      uploaderConfig: {
        theme: 'light',
        height: '100%',
        width: '100%'
      },
      images: [
        { id: 1, src: 'https://picsum.photos/200/300' },
        { id: 2, src: 'https://picsum.photos/200/300' },
        { id: 3, src: 'https://picsum.photos/200/300' },
        { id: 4, src: 'https://picsum.photos/200/300' },
        { id: 5, src: 'https://picsum.photos/200/300' }
      ]
    }
  },

  computed: {
    uppy() {
      return new Uppy({
          id: 'uppy-images-uploader',
          autoProceed: false,
          debug: true,
          restrictions: {
            allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', 'gif']
          }
        })
        .use(GoogleDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Dropbox, { companionUrl: this.$config.uppy.companionUrl })
        .use(Box, { companionUrl: this.$config.uppy.companionUrl })
        .use(Instagram, { companionUrl: this.$config.uppy.companionUrl })
        .use(Facebook, { companionUrl: this.$config.uppy.companionUrl })
        .use(OneDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Url, { companionUrl: this.$config.uppy.companionUrl })
        .use(XHRUpload, {
          endpoint: this.$config.uppy.endpoint,
          fieldName: 'files',
          metaFields: [],
          headers: {}
        })
        .use(ProgressBar, {
          hideAfterFinish: true,
        })
        .use(Informer)
        .use(StatusBar, {
          hideAfterFinish: true,
          showProgressDetails: false,
          hideUploadButton: false,
          hideRetryButton: false,
          hidePauseResumeButton: false,
          hideCancelButton: false,
          doneButtonHandler: null
        })
    }
  },

  methods: {
    onEditImage(image) {
      console.log('edit now')
      this.activeImage = image
      this.showImageEditor = true
    },

    onToggleSelection(image) {
      let index = this.selectedImages.indexOf(image.id)
      if (index > -1) {
        this.selectedImages.splice(index, 1)
      } else {
        this.selectedImages.push(image.id)
      }
    },

    onRemoveSelectedImages() {
      // todo:
    },

    async imageUploaded(attachments) {
      try {
        attachments.forEach(element => {
          this.note.add('attachments', element)
        })
      } catch(error) {
        console.error(error)
      }
    },

    async save() {
      // todo: data contains list of the image urls
      this.$emit('save', {
        data: '',
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      let img = this.$el.querySelector('.image-holder img')
      if (img) {
        return this.$dom.toDataURL(img)
      } else {
        return ''
      }
    }
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  }
}
</script>

<style scoped>

.images-uploader div,
.images-uploader >>> .uppy-Root,
.images-uploader >>> .uppy-Dashboard
 {
  width: 100%;
  height: 100%;
}

.image-group .image-controls {
  display: none;
}

.image-group:hover .image-controls {
  display: block;
}

</style>
import Parse from 'parse'
import parseCache from 'parse-cache'

// refer to https://www.npmjs.com/package/parse-cache for
// more details
parseCache(
  Parse, 
  'EasyNotes',
  {
    engine: 'memory',
    count: 10000
  }
) // {engine: 'memory', count: 1000} are default values and are optional

export default parseCache

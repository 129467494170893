<template>
  <div class="w-screen h-screen">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
};
</script>


<style>

.v-btn {
  text-transform: none !important;
}
.v-treeview-node__root {
  cursor: pointer;
}

</style>
<template>
  <div class="w-full flex flex-col px-4 pt-1 pb-4 bg-white">
    <v-subheader class="w-full flex flex-row space-x-2">
      <span class="grow">Select Labels</span>
      <v-btn
        icon
        small
        @click.stop.prevent="toggleViewMode">
        <v-icon v-if="viewMode == 'row'">mdi-table-column</v-icon>
        <v-icon v-else>mdi-table-row</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click.stop.prevent="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-subheader>
    <template v-if="allowCreateLabel">
      <v-text-field
        dense
        name="name"
        filled
        rounded
        placeholder="Type to add new label"
        hide-details
        v-model="newLabel.text"
        @keydown.enter="createLabel"
        @keydown.esc="clearInput"
        :style="{
          'background-color': newLabel.color
        }"
        :class="[darkText ? 'dark-text-input' : 'light-text-input']"
      >
        <template v-slot:append>
          <color-picker
            v-model="newLabel.color">
          </color-picker>
        </template>
      </v-text-field>
    </template>
    <div
      :class="['grow px-4', allowCreateLabel ? 'mt-4' : 'mt-0']">
      <div
        :class="[
          'flex gap-2 my-2',
          viewMode == 'row' ? 'flex-row flex-wrap' : 'flex-col'
        ]">
        <template v-for="label in filteredLabels">
          <div
            :key="label.text"
            class="border px-4 py-2 hover:bg-gray-200 cursor-pointer rounded"
            :style="{
              'background-color': label.color,
              'color': invertColorValue(label.color)
            }"
            @click.stop.prevent="selectLabel(label)">
            {{ label.text }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ColorPicker from './colorpicker'
import invertColor from 'invert-color'

export default {
  name: 'LabelSelector',

  props: {
    allowCreateLabel: {
      type: Boolean,
      default: true
    },

    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  components: {
    ColorPicker
  },

  data() {
    return {
      newLabel: {
        text: '',
        color: '#ffffff'
      },
      viewMode: 'row',
      localSelectedLabels: this.value
    }
  },

  computed: {
    labels() {
      return this.$store.state.labels.items
    },

    filteredLabels() {
      return _.filter(this.labels, (x) => _.findIndex(this.localSelectedLabels, r => r.id == x.id) < 0)
    },

    darkText() {
      let color = invertColor(this.newLabel.color, true)
      console.log(color)
      if (color === '#ffffff') {
        return false
      }
      return true
    }
  },

  methods: {
    invertColorValue(color) {
      return invertColor(color, true)
    },

    close() {
      this.$emit('close')
    },

    toggleViewMode() {
      if (this.viewMode == 'row') {
        this.viewMode = 'column'
      } else {
        this.viewMode = 'row'
      }
    },

    async createLabel() {
      try {
        if ( _.isEmpty(this.newLabel.text) ) {
          throw new Error('Label text must not be empty')
        }
        let label = this.$api.newObjectWithData('Label', this.newLabel)
        await label.save()
        this.clearInput()
        this.$store.commit('labels/addLabel', label)
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    clearInput() {
      this.newLabel.text = ''
      this.newLabel.color = '#ffffff'
    },

    selectLabel(label) {
      if (_.findIndex(this.localSelectedLabels, x => x.id == label.id) < 0) {
        this.localSelectedLabels.push(label)
      }
      this.$emit('input', this.localSelectedLabels)
    },

    submit() {
      this.$emit('input', this.localSelectedLabels)
    }
  },

  async mounted() {
    await this.$store.dispatch('labels/load')
  },
}
</script>
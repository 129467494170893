<template>
  <v-dialog
    eager
    :value="true"
    @input="change" 
    :persistent="persistent"
    :overlay="false"
    :max-width="width"
    @keydown.esc="choose(false)"
    transition="dialog-transition">
    <v-card tile>
      <v-toolbar v-if="Boolean(title)" dark :color="color" dense flat>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        <v-toolbar-title class="white--text" v-text="title"/>
      </v-toolbar>
      <v-card-text class="body-1 py-3" v-html="message"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="Boolean(buttonFalseText)"
          :color="buttonFalseColor"
          text
          @click="choose(false)">
          {{ buttonFalseText }}
        </v-btn>
        <v-btn
          v-if="Boolean(buttonTrueText)"
          :color="buttonTrueColor"
          text
          @click="choose(true)">
          {{ buttonTrueText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes'
    },
    buttonFalseText: {
      type: String,
      default: 'No'
    },
    buttonTrueColor: {
      type: String,
      default: 'primary'
    },
    buttonFalseColor: {
      type: String,
      default: 'grey'
    },
    color: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default () {
        return 'mdi-alert'
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 450
    }
  },

  data () {
    return {
      value: false
    }
  },

  mounted () {
    document.addEventListener('keyup', this.onEnterPressed)
  },

  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },

  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(true)
      }
    },
    choose (value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change () {
      this.$destroy()
    }
  }
}
</script>
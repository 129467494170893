export const createNote = (note, type) => {
  const defaultData = {
    texts: '{}',
    mockup: '',
    todo: '',
    mindmap: '',
    codes: '',
    draw: '',
    local: '',
    remote: '',
    record: '',
    images: '',
    document: ''
  }

  note.data = defaultData[type]
  note.labels = []
  note.todos = []
}

export const NoteDefaultThumbnails = () => {
  return ''
}

export default [
  { name: 'Rich Text', icon: 'mdi-format-text', key: 'texts', selected: false },
  { name: 'Sketch Design', icon: 'mdi-chart-tree', key: 'mockup', selected: false },
  { name: 'Todo List', icon: 'mdi-calendar-text', key: 'todo', selected: false },
  { name: 'Mindmap', icon: 'mdi-graph-outline', key: 'mindmap', selected: false },
  { name: 'Source Code', icon: 'mdi-code-tags', key: 'codes', selected: false },
  { name: 'Free Draw', icon: 'mdi-draw-pen', key: 'draw', selected: false },
  { name: 'Upload Files', icon: 'mdi-attachment', key: 'uploads', selected: false },
  { name: 'Record Audio/Video', icon: 'mdi-microphone-outline', key: 'record', selected: false },
  { name: 'Images Gallery', icon: 'mdi-file-image-outline', key: 'images', selected: false },
]

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center w-full h-full overflow-hidden"},[_c('div',{staticClass:"media-record h-full w-1/2"},[_c('dashboard',{attrs:{"uppy":_vm.uppy,"plugins":[
        'Audio',
        'Webcam',
        'ScreenCapture',
        'ProgressBar',
        'Informer',
        'StatusBar'
      ],"props":_vm.config}})],1),_c('div',{staticClass:"media-player w-1/2 h-full flex flex-col overflow-hidden"},[_c('div',{staticClass:"shrink px-4 py-2"},[_vm._v(" Recorded files ")]),_c('div',{staticClass:"flex flex-col grow px-2 overflow-auto",staticStyle:{"height":"calc(100% - 38px)"}},[_vm._l((_vm.recordedFiles),function(file){return [_c('div',{key:file.id,staticClass:"px-2 py-1 flex flex-row items-center shrink space-x-2 hover:bg-gray-200"},[(file.type == 'audio')?_c('v-icon',[_vm._v("mdi-microphone-outline")]):_c('v-icon',[_vm._v("mdi-video-box")]),_c('span',{staticClass:"grow"},[_vm._v("Audio file")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-play-outline")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }
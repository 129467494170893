var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","min-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g({staticClass:"bg-gray-100",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]},null,{ on: on })]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('div',{staticClass:"pen-picker-holder flex flex-col bg-white px-4 py-2"},[_c('span',[_vm._v("Pen Color")]),_c('div',{staticClass:"grid grid-cols-7"},[_vm._l((_vm.colors),function(color){return [_c('div',{key:color,class:[
            'cursor-pointer hover:border-2 border-gray-500 rounded-full flex place-content-center items-center',
            _vm.pen.color == color ? 'border-2 border-blue-500' : ''
          ],staticStyle:{"width":"24px","height":"24px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectColor(color)}}},[_c('div',{staticClass:"rounded-full w-4 h-4",style:({ backgroundColor: color })})])]})],2),_c('span',{staticClass:"mt-3"},[_vm._v("Pen Size")]),_c('div',{staticClass:"grid grid-cols-10 p-2 justify-items-center\titems-center"},[_vm._l((_vm.sizes),function(size){return [_c('div',{key:size,class:[
            'cursor-pointer hover:border-2 border-gray-500 rounded-full flex place-content-center items-center',
            _vm.pen.size == size ? 'border-2 border-blue-500' : ''
          ],staticStyle:{"width":"24px","height":"24px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectSize(size)}}},[_c('div',{staticClass:"rounded-full bg-black",style:({ width: (size + "px"), height: (size + "px") })})])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    :min-width="300">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn icon small v-on="on">
          <v-icon>mdi-format-color-fill</v-icon>
        </v-btn>
      </slot>
    </template>
    <div class="color-picker-holder flex flex-col bg-white">
      <div class="flex flex-row px-4 py-2">
        <span class="grow">Color Picker</span>
        <!-- <v-btn small icon @click="submit">
          <v-icon>mdi-check</v-icon>
        </v-btn> -->
        <v-btn small icon @click="close">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
      <div class="grow flex">
        <v-color-picker
          dot-size="24"
          hide-canvas
          hide-mode-switch
          hide-sliders
          mode="hexa"
          show-swatches
          swatches-max-height="300"
          v-model="color"
        ></v-color-picker>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorPicker',

  props: {
    value: {
      type: String,
      default: '#ffffff'
    }
  },

  data() {
    return {
      showMenu: false,
      color: this.value
    }
  },

  methods: {
    // submit() {
    //   this.$emit('input', this.color)
    //   this.showMenu = false
    // },

    close() {
      this.showMenu = false
    }
  },

  watch: {
    color: {
      immediate: true,
      handler: function(newValue) {
        console.log(newValue)
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped>

.color-picker-holder >>> .v-color-picker__controls {
  padding-top: 0;
  padding-bottom: 0;
}

.color-picker-holder >>> .v-color-picker__edit {
  margin-top: 0;
}

</style>
import Parse from 'parse'
// import { keys, isArray, isString, isObject } from 'lodash';
import _ from 'lodash'
import config from '../config'
import parseCache_ from './cache'
// import { ParseVueObject } from './object'

const WHITESPACE = ' '

Parse.initialize(
  config.parse.appId,
  config.parse.javascriptKey
);
// can not set in initialize
Parse.masterKey = config.parse.masterKey
// Parse.serverURL = 'https://pg-app-y5cphwu1ygfyk01xp53u0t8ziqrzis.scalabl.cloud/1/';
Parse.serverURL = config.parse.server

// clear possible pre-logined user
if (Parse.User.current()) {
  // do stuff with the user
  Parse.User.logOut();
}

export const parseCache = parseCache_

export const currentUser = () => {
  return Parse.User.current();
}


export const isAuthenticated = () => {
  const currentUser = Parse.User.current();
  if (currentUser) {
    return true;
  } else {
    return false
  }
}

const defineClassProps = (className, props) => {
  Parse.Object.extend(className, {
    initialize: function() {
      props.forEach(key => {
        Object.defineProperty(this, key, {
          get() { return this.attributes[key]; },
          set(value) { this.set(key, value); }
        });
      })
    }
  });
}

// const defineSubclass = (className) => {
//   Parse.Object.registerSubclass(className, ParseVueObject)
// } 

export const buildQuery = (name, filters, selects, includes, excludes, sorts) => {
  let query = new Parse.Query( Parse.Object.extend(name) );
  if (_.isArray(filters) && filters.length > 0) {
    filters.forEach((filter) => {
      let { op, key, value } = filter;
      query[op].call(query, key, value);
    });
  }

  if (_.isArray(selects) && selects.length > 0 && selects.indexOf('*') < 0) {
    query.select(...selects);
  } else if (_.isString(selects)) {
    query.select(selects.split(WHITESPACE));
  }

  if (_.isArray(includes) && includes.length > 0) {
    query.include(...includes);
  }

  if (_.isArray(excludes) && excludes.length > 0) {
    query.exclude(...excludes);
  } else if (_.isString(excludes)) {
    query.exclude(excludes.split(WHITESPACE));
  }

  if (_.isArray(sorts) && sorts.length > 0) {
    sorts.forEach((sort) => {
      let { op, keys } = sort
      query[op].call(query, keys)
    })
  }

  return query;
}

export const getQuery = (objectName) => {
  const ObjectType = Parse.Object.extend(objectName);
  return new Parse.Query(ObjectType);
}

export const newObjectWithData = (objectName, data) => {
  const ObjectType = Parse.Object.extend(objectName);
  const objectInstance = new ObjectType();
  _.keys(data).forEach((key) => {
    objectInstance.set(key, data[key]);
  });
  return objectInstance;
}

export const saveObjects = async (objects) => {
  await Parse.Object.saveAll(objects);
}

export const clearObjects = async (objects) => {
  await Parse.Object.destroyAll(objects)
}

export const updateObjectOnly = async (obj, data) => {
  _.keys(data).forEach((key) => {
    obj.set(key, data[key]);
  });

  return await obj.save();
}

export const updateOrCreateObject = async (objectName, id, data) => {
  try {
    let objectInstance = null;
    if (_.isObject(id)) {
      objectInstance = id;
    } else {
      const ObjectType = Parse.Object.extend(objectName);
      if (id) {
        const queryType = new Parse.Query(ObjectType);
        objectInstance = await queryType.get(id);
        if (!objectInstance) {
          throw new Error('Object instance not found');
        }
      } else {
        objectInstance = new ObjectType();
      }
    }

    _.keys(data).forEach((key) => {
      objectInstance.set(key, data[key]);
    });

    return await objectInstance.save();
  } catch(err) {
    console.error(err);
    throw err;
  }
}

export const getObjectById = async (objectName, id, options) => {
  const { selects, includes } = options || {};
  const ObjectType = Parse.Object.extend(objectName);
  const query = new Parse.Query(ObjectType);
  if (selects) {
    query.select(...selects);
  }
  if (includes) {
    includes.forEach(inc => query.include(inc));
  }
  return await query.get(id);
}

export const removeObjectById = async (className, id, softDelete = false) => {
  let obj = await getObjectById(className, id, { selects: ['objectId'] });
  removeObject(obj, softDelete)
}

export const removeObject = async (obj, softDelete = false) => {
  if (obj) {
    if (!softDelete) {
      await obj.destroy()
    } else {
      obj.set('deletedAt', new Date())
      await obj.save()
    }
  }
}

export default Parse;

defineClassProps('Attachment', ['owner', 'note', 'name', 'suffix', 'type', 'key', 'status', 'url', 'size', 'meta', 'tags']);
defineClassProps('Folder', ['owner', 'parent', 'children', 'name', 'color', 'key', 'deletedAt']);
defineClassProps('Label', ['owner', 'text', 'color', 'notes', 'notesCount']);
defineClassProps('Note', ['owner', 'title', 'type', 'slug', 'folder', 'reminders', 'todos', 'labels', 'data', 'attachments', 'deletedAt']);
defineClassProps('Reminder', ['owner', 'note', 'date', 'status' ] )
defineClassProps('Todo', ['owner', 'note', 'title', 'dueAt', 'status']);
defineClassProps('User', ['avatar', 'username', 'email', 'password', 'status']);


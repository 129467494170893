<template>
  <div class="w-full h-full overflow-hidden">
    <div class="text-editor pell h-full overflow-hidden">
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image-more'
import mixin from '../mixin'
import pell from 'pell'
import 'pell/dist/pell.css'

export default {
  name: 'TextEditor',

  mixins: [ mixin ],

  data() {
    return {
      content: this.note.data
    }
  },

  methods: {
    init() {
      // Initialize pell on an HTMLElement
      pell.init({
        // <HTMLElement>, required
        element: this.$el.querySelector('.text-editor'),

        // <Function>, required
        // Use the output html, triggered by element's `oninput` event
        onChange: (data) => {
          this.content = data
          this.$emit('change')
        },

        // <string>, optional, default = 'div'
        // Instructs the editor which element to inject via the return key
        defaultParagraphSeparator: 'div',

        // <boolean>, optional, default = false
        // Outputs <span style="font-weight: bold;"></span> instead of <b></b>
        styleWithCSS: false,

        // <Array[string | Object]>, string if overwriting, object if customizing/creating
        // action.name<string> (only required if overwriting)
        // action.icon<string> (optional if overwriting, required if custom action)
        // action.title<string> (optional)
        // action.result<Function> (required)
        // Specify the actions you specifically want (in order)
        actions: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'heading1',
          'heading2',
          'paragraph',
          'olist',
          'ulist',
          'line',
          'link',
          'image'
        ],

        // classes<Array[string]> (optional)
        // Choose your custom class names
        classes: {
          actionbar: 'pell-actionbar',
          button: 'pell-button',
          content: 'pell-content',
          selected: 'pell-button-selected'
        }
      })

      this.$el.querySelector('.pell-content').innerHTML = this.content
    },

    redo() {
      pell.exec('redo')
    },

    undo() {
      pell.exec('undo')
    },

    async save() {
      this.$emit('save', {
        data: this.content,
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.text-editor')
        return domtoimage.toPNG(el, {
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
      return ''
    }
  },

  mounted() {
    this.init()
    this.$nextTick(() => {
      this.$el.querySelector('.pell-content').focus()
    })
  },
}
</script>

<style scoped>

.pell {
  border: none !important;
}
.pell >>> ul li {
  list-style: disc;
}

.pell >>> ol li {
  list-style: decimal;
}

.pell >>> h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.pell >>> h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.pell >>> .pell-actionbar {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pell >>> .pell-actionbar button {
  font-size: 1.2em;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 100%;
  margin-left: 2px;
  margin-right: 2px;
}

.pell >>> .pell-actionbar button:hover {
  background-color: lightgray;
}

.pell >>> .pell-content {
  height: calc(100% - 32px);
  padding-left: 10px;
}

.pell >>> hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border: 0;
  border-top: 2px dashed #585858;
  margin-bottom: 20px;
  margin-top: 20px;
}

</style>
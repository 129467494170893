import Prompt from './Prompt.vue'

function Install (Vue, options = {}) {
  const property = options.property || '$prompt'
  delete options.property
  const vuetify = options.vuetify
  delete options.vuetify
  if (!vuetify) {
    console.warn('Module vuetify-prompt needs vuetify instance. Use Vue.use(VuetifyPrompt, { vuetify })')
  }
  const Ctor = Vue.extend(Object.assign({ vuetify }, Prompt))
  function createMenuCmp (options) {
    const container = document.querySelector('[data-app=true]') || document.body
    return new Promise(resolve => {
      const cmp = new Ctor(Object.assign({}, {
        propsData: Object.assign({}, Vue.prototype.$prompt.options, options),
        destroyed: () => {
          container.removeChild(cmp.$el)
          resolve(cmp.value)
        }
      }))
      container.appendChild(cmp.$mount().$el)
    })
  }
  
  function show (options = {}) {
    return createMenuCmp(options)
  }

  Vue.prototype[property] = show
  Vue.prototype[property].options = options || {}
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Install)
}

export default Install

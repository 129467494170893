export default {
  props: {
    note: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activeUndoHistory: null
    }
  },

  computed: {
    canRedo() {
      return this.activeUndoHistory && this.activeUndoHistory.canRedo()
    },

    canUndo() {
      return this.activeUndoHistory && this.activeUndoHistory.canUndo()
    }
  },

  methods: {
    redo() {
      this.activeUndoHistory && this.activeUndoHistory.redo()
    },

    undo() {
      this.activeUndoHistory && this.activeUndoHistory.undo()
    }
  }
}
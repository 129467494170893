<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="false"
    bottom
    offset-y
    min-width="220">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-pin-outline</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader>Pinned Notes</v-subheader>
      <template v-if="pinnedNotes.length > 0">
        <v-list-item-group>
          <template v-for="(note, index) in pinnedNotes">
            <v-list-item two-line link :key="index">
              <v-list-item-avatar>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content @click.stop.prevent="editNote(note)">
                <v-list-item-title>{{ note.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span>Updated {{ note.updatedAt | timeAgo }} ago</span>&nbsp;&nbsp;
                  <span>{{ note.type | capitalize}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action @click.stop.prevent="unpinNote(note)">
                <v-btn icon small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
      <template v-else>
        <div class="px-8 text-center w-full">
          <span>No pinned notes</span>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'PinnedNotesList',

  computed: {
    pinnedNotes() {
      return this.$store.state.pinnedNotes
    }
  },

  methods: {
    unpinNote(note) {
      this.$store.commit('unpinNote', note)
    },

    editNote(note) {
      this.$eventBus.$emit('edit.note', note)
    }
  }
}
</script>

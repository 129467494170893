<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
    width="50%"
    class="note-editor p-0"
    >
    <v-card
      class="p-0 w-full overflow-hidden border-0"
      style="height: 90vh">
      <v-toolbar
        light
        dense
        flat
        fixed
        color="white"
        :extension-height="28">
        <v-toolbar-title class="px-0 flex items-center">
          <span class="mx-2">Title#</span>
          <line-edit
            :text="note.title"
            placeholder="Title"
            @save="updateTitle"
            >
          </line-edit>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="flex flex-row space-x-1 shrink">
          <template v-if="redoable">
            <v-btn small fab icon @click="undo">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn small fab icon @click="redo">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <v-btn small fab icon @click="showFiltersInput = !showFiltersInput">
            <v-icon>mdi-tune-variant</v-icon>
          </v-btn>
          <v-btn small fab icon @click="fullscreen = !fullscreen">
            <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
            <v-icon v-else>mdi-fullscreen-exit</v-icon>
          </v-btn>
          <v-btn :disabled="saved" small fab icon @click="triggerSave">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!-- <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                fab
                v-bind="attrs"
                v-on="on"
              >
              <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-divider vertical></v-divider>
          <v-btn small fab icon @click="togglePinNote">
            <v-icon v-if="notePinned">mdi-pin-off-outline</v-icon>
            <v-icon v-else>mdi-pin-outline</v-icon>
          </v-btn>
          <v-btn small fab icon @click="hide">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <template v-if="showFiltersInput" v-slot:extension>
          <div class="flex flex-row space-x-1 items-center note-editor-footer">
            <note-folder-selector-menu
              @select-folder="moveToFolder">
              <template v-slot:activator="{ on }">
                <v-btn x-small fab icon v-on="on">
                  <v-icon>mdi-folder-settings-outline</v-icon>
                </v-btn>
              </template>
            </note-folder-selector-menu>
            <v-breadcrumbs :items="ancestors" class="px-0 py-0 my-0">
              <template v-slot:item="{ item }">
                <v-chip x-small>
                  {{ item.name }}
                </v-chip>
              </template>
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <v-spacer></v-spacer>
            <div class="flex flex-row items-center space-x-1 shrink">
              <span class="text-xs">Labels:</span>
              <template v-if="!note.labels || note.labels.length == 0">
                <span class="text-xs text-gray-500">No labels</span>
              </template>
              <template v-else>
                <label-list
                  :labels="labels"
                  :visible-items="3"
                  :xsmall="true"
                  @click-close="removeLabel"
                  ></label-list>
              </template>
              <label-selector-menu
                :selected-labels="labels"
                @close="addLabel">
                <template v-slot:activator="{ on }">
                  <v-btn x-small fab icon v-on="on">
                    <v-icon>mdi-label-multiple-outline</v-icon>
                  </v-btn>
                </template>
              </label-selector-menu>
            </div>
          </div>
        </template>
      </v-toolbar>
      <v-card-text
        class="relative px-0 py-0 overflow-hidden"
        style="height: calc(100% - 48px);">
        <component
          ref="activeEditor"
          :note="note"
          :is="editor"
          @change="changeNote"
          @save="updateNote"
          >
        </component>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentEditor from './editors/document'
import TextEditor from './editors/text'
import RecordEditor from './editors/record'
import CodeEditor from './editors/code'
import MindMapEditor from './editors/mindmap'
import MockupEditor from './editors/mockup'
import FreeDrawEditor from './editors/draw'
import TodoEditor from './editors/todo'
import UploadsEditor from './editors/uploads'
import ImagesEditor from './editors/image'
import LineEdit from './LineEdit.vue'
import LabelSelectorMenu from './LabelSelectorMenu.vue'
import NoteFolderSelectorMenu from './NoteFolderSelectorMenu.vue'
import LabelList from './LabelList.vue'

export default {
  name: 'NoteEditor',

  components: {
    'document': DocumentEditor,
    'texts': TextEditor,
    'mindmap': MindMapEditor,
    'mockup': MockupEditor,
    'todo': TodoEditor,
    'codes': CodeEditor,
    'record': RecordEditor,
    'draw': FreeDrawEditor,
    'uploads': UploadsEditor,
    'images': ImagesEditor,
    LineEdit,
    LabelSelectorMenu,
    NoteFolderSelectorMenu,
    LabelList
  },

  data() {
    return {
      dialog: false,
      fullscreen: false,
      editor: 'plain',
      note: { title: '' },
      showFiltersInput: false,
      labels: [],
      saved: false,
      redoEditors: [
        'texts', 'codes','draw', 'mindmap', 'mockup', 'text'
      ]
    }
  },

  computed: {
    ancestors() {
      if (!this.note || !this.note.folder) {
        return [{ name: 'Uncategoried' }]
      } else {
        return this.$store.getters['folders/ancestors'](this.note.folder)
      }
    },

    notePinned() {
      return this.$store.getters['notePinned'](this.note)
    },

    redoable() {
      return this.redoEditors.indexOf(this.editor) > -1
    }
  },

  methods: {
    async show(note) {
      let { type } = note
      this.editor = type
      this.note = note
      this.dialog = true
    },

    hide() {
      this.dialog = false
    },

    async updateTitle(title) {
      try {
        this.note.set('title', title)
        await this.note.save()
      } catch(error) {
        this.$notify(error.message)
      }
    },

    async moveToFolder(folder) {
      try {
        if (!this.note) {
          return
        }

        this.note.set('folder', folder)
        await this.note.save()
      } catch(error) {
        console.error(error)
      }
    },

    async removeLabel(label) {
      try {
        let yes = await this.$confirm('Are you sure to remove the note\'s label?')
        if (!yes) {
          return
        }
        await this.$Parse.Cloud.run('deleteNoteLabel', {
          note: this.note.id,
          label: label.id
        })

        await this.$Parse.Object.fetchAll([this.note])
        await this.$Parse.Object.fetchAll([label])
      } catch(error) {
        console.error(error)
      }
    },

    async addLabel() {
      try {
        if (!this.note) {
          return
        }

        let newLabels = []
        let noteLabels = (this.note.labels || [])
        if (this.labels.length > noteLabels.length) {
          for (let i = noteLabels.length; i < this.labels.length; ++i) {
            newLabels.push(this.labels[i])
          }
        }

        if (newLabels.length > 0) {
          await this.$Parse.Cloud.run('addNoteLabel', {
            note: this.note.id,
            labels: newLabels.map(label => label.id)
          })
          await this.$Parse.Object.fetchAll([this.note])
          await this.$Parse.Object.fetchAll(newLabels)
        }
      } catch(error) {
        console.error(error)
      }
    },

    togglePinNote() {
      if (this.notePinned) {
        this.$store.commit('unpinNote', this.note)
      } else {
        this.$store.commit('pinNote', this.note)
      }
    },

    async triggerSave() {
      await this.$refs.activeEditor.save()
    },

    redo() {
      if (this.redoable) {
        this.$refs.activeEditor.redo()
      }
    },

    undo() {
      if (this.redoable) {
        this.$refs.activeEditor.undo()
      }
    },

    changeNote() {
      this.saved = false
    },

    async updateNote(updates) {
      try {
        console.log('save note')
        console.log(updates)
        this.$api.updateObjectOnly(this.note, updates)
        await this.note.save()
        this.saved = true
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    }
  },

  async mounted() {
    this.$eventBus.$on('edit.note', this.show.bind(this))
  },

  watch: {
    note: {
      immediate: true,
      handler: function(newValue) {
        this.labels.splice(0, this.labels.length)
        if (newValue && newValue.labels) {
          newValue.labels.forEach(label => {
            this.labels.push(label)
          })
        }
      }
    }

    // labels: {
    //   immediate: true,
    //   handler: function(newValue) {
    //     if (!this.note || !this.note.labels) {
    //       return
    //     }
    //     let noteLabels = this.note.labels
    //     if (newValue.length > noteLabels.length) {
    //       for (let i = noteLabels.length; i < newValue.length; ++i) {
    //         await this.addLabel(newValue[i])
    //       }
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>

.note-editor-footer >>> .v-breadcrumbs__divider {
  padding-left: 0 !important;
  padding-right: 0 !important
}

</style>
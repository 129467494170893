import Vue from 'vue';

function host (url) {
  const host = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '')
  const parts = host.split('.').slice(-3)
  if (parts[0] === 'www') { parts.shift() }
  return parts.join('.')
}

function timeAgo (time) {
  const t = new Date(time);
  const between = Date.now() / 1000 - Number( t.valueOf() / 1000 )
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

function dateFormat(date /* format */) {
  // return moment(date).format(format);
  return new Date(date).toDateString()
}

function prettyBytes(num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
      throw new TypeError('Expected a number');
    }
  
    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    if (neg) {
      num = -num;
    }
  
    if (num < 1) {
      return (neg ? '-' : '') + num + ' B';
    }
  
    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];
  
    return (neg ? '-' : '') + num + ' ' + unit;
}

function pluralize (time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function firstLetter(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase();
}

Vue.filter('host', host);
Vue.filter('timeAgo', timeAgo);
Vue.filter('dateFormat', dateFormat);
Vue.filter('capitalize', capitalize);
Vue.filter('firstLetter', firstLetter);
Vue.filter('prettyBytes', prettyBytes);

<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="overflow-y-auto">
        <div class="flex flex-col mx-auto w-1/3">
            <p class="headline text-center grey--text mt-6">
              Welcome to <strong>EasyNotes</strong>
            </p>
            <div class="elevation-2 p-8">
              <div class="flex flex-col space-y-2">
                <p class="text-gray-400 font-bold text-center text-2xl">404. Page not found</p>
                <p class="text-gray-500">
                  Goto dashboard > 
                  <router-link :to="{ path: '/' }">Dashboard</router-link>
                </p>
                <p class="text-gray-500">
                  Goto login page >
                  <router-link :to="{ path: '/login' }">Login</router-link>
                </p>
                <p class="text-gray-500">
                  Goto landing page > 
                  <router-link :to="{ path: '/' }">Home</router-link>
                </p>
              </div>
            </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PageNotFound' 
}
</script>

import EventEmitter from 'events'

/*
 * Editor redo/undo history
 */
class UndoHistory extends EventEmitter {
  constructor(size) {
    super()
    this.size = size > 0 ? size : 100
    this.dones = []
    this.undos = []
  }

  /**
   * When new command is performed, add new revision
   * of the state to the history for later redo/undo
   */
  async push(command) {
    this.dones.push(command)
    if (this.dones.length > this.size) {
      this.dones.splice(this.dones.length - 1, 1)
    }
    this.undos = []
    await command.redo()
    this.emit('change')
  }

  /**
   * Perform redo on the history
   */
  async redo() {
    const undos = this.undos
    const length = undos.length
    if (length > 0) {
      const command = undos[length - 1]
      this.dones.push(command)
      this.undos.splice(length - 1, 1)
      await command.redo()
    }
  }

  /**
   * Perform undo on the history
   */
  async undo() {
    const dones = this.dones
    const length = dones.length
    if (length > 0) {
      const command = dones[length - 1]
      this.undos.push(command)
      if (this.undos.length > this.size) {
        this.undos.splice(this.undos.length - 1, 1)
      }
      this.dones.splice(length - 1, 1)
      await command.undo()
    }
  }
}

// the const variable
export default UndoHistory

<template>
  <div :class="[
      'flex flex-col flex-nowrap shrink items-start space-y-4', 
      level == 1 ? 'mindmap-node-root' : ''
    ]">
    <!-- node content itself -->
    <div
      ref="root"
      :class="[
        'relative flex flex-col shrink rounded cursor-pointer hover:shadow-xl',
        level == 1 ? 'text-lg py-4' : '',
        level == 2 ? 'py-2': '',
        level > 2 ? 'text-sm py-1 border-b' : ''
      ]"
      :style="{
        textColor: level == 1 ? styleConfig.primaryTextColor : ( level == 2 ? styleConfig.secondaryTextColor: 'gray' ), 
        backgroundColor: level == 1 ? styleConfig.primaryFillColor : ( level == 2 ? styleConfig.secondaryFillColor: 'transparent' ) 
      }"
      tabindex=0
      @dblclick.stop.prevent="onDoubleClick"
      @click.stop.prevent="onClick"
      @keydown="onKeyDown">
      <div class="flex flex-row px-4 space-x-1 items-center">
        <div
          class="font-bold">
          {{ node.title }}
        </div>
        <div
          v-if="editing"
          class="absolute w-full h-full outline-none top-2"
          v-click-outside="onDoneEditing">
          <v-text-field
            v-model="node.title"
            dense
            filled
            rounded
            hide-details
            no-hints
            class="bg-white"
            @keydown.enter="onDoneEditing"
            @keydown.esc="onDoneEditing"
          ></v-text-field>
        </div>
      </div>
      <div
        v-if="selected"
        class="absolute border-2 border-blue-400 -top-1 -left-1 rounded"
        style="pointer-events: none;width: calc(100% + 8px); height: calc(100% + 8px)">
      </div>

      <!-- children collapsed controller -->
      <template v-if="node.children.length > 0">
        <div
          class="absolute"
          :style="{
            left: `calc(50% - ${ anchorSize / 2.0}px)`,
            top: '100%'
          }"
          @click.stop.prevent="collapsed = !collapsed"
          >
          <button
            class="rounded-full border bg-white text-gray-500 place-content-center hover:bg-gray-200 text-xs shadow-md outline-none"
            :style="{
              width: `${ anchorSize }px`,
              height: `${ anchorSize }px`,
            }"
            >
            <span v-if="collapsed">+</span>
            <span v-else>-</span>
          </button>
        </div>
      </template>
    </div>

    <!-- children holder -->
    <template
      v-if="node.children.length > 0 && !collapsed">
      <div
        ref="childrenHolder"
        class="relative flex flex-row p-0 m-0"
        :style="{ left: `${childrenOffsetX}px` }"
        >
        <svg
          ref="lineHolder"
          preserveaspectratio="none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :height="`${childrenHeight}px`"
          width="24px">
          <template v-for="(seg, index) in segments">
            <path
              :key="`seg-${index}`"
              :d="`M${seg.x1} ${seg.y1} L${seg.x2} ${seg.y2}`"
              stroke="#C5CCD0"
              stroke-width="2px"/>
          </template>
        </svg>
        <div
          ref="childHolder"
          :class="[
            'flex flex-col shrink items-start',
            level == 1 ? 'space-y-6' : '',
            level == 2 ? 'space-y-4' : '',
            level > 2 ? 'space-y-2' : ''
          ]">
          <tree-node
            v-for="child in node.children"
            :key="child.id"
            :node="child"
            :level="level+1"
            :style-config="styleConfig"
            @select="$emit('select', $event)"
            @create="$emit('create', $event)"
            @remove="$emit('remove', $event)"
            @navigate="$emit('navigate', $event)"
            @bind="$emit('bind', $event)"
            @unbind="$emit('unbind', $event)"
            ref="childrenNodes">
          </tree-node>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'TreeNode',

  mixins: [ mixin ],

  data() {
    return {
      fromPoint: { x: 0, y: 0 },
      childrenOffsetX: 0,
      childrenHeight: 0
    }
  },

  computed: {
    segments() {
      let segs = []
      if (this.childAnchorPoints.length > 0) {
        segs.push({
          x1: 0,
          y1: 0,
          x2: 0,
          y2: this.childAnchorPoints[this.childAnchorPoints.length - 1].y
        })
        this.childAnchorPoints.forEach(p => {
          segs.push({
            x1: 0,
            y1: p.y,
            x2: p.x,
            y2: p.y
          })
        })
      }
      return segs
    }
  },

  methods: {
    getAnchorPoint() {
      if (this.$el) {
        let rect = this.$el.firstChild.getBoundingClientRect()
        return {
          top: rect.top + rect.height / 2.0,
          left: rect.left
        }
      } else {
        return {
          top: 0,
          left: 0
        }
      }
    },

    doResize() {
      this.childrenHeight = this.$refs.childrenHolder.getBoundingClientRect().height
      this.$nextTick(() => {
        let svgRect = this.$refs.lineHolder.getBoundingClientRect()
        // the start point
        this.fromPoint.x = 0
        this.fromPoint.y = 0

        // the child anchor points
        this.childAnchorPoints.splice(0, this.childAnchorPoints.length)
        for (let i = 0; i < this.$refs.childrenNodes.length; ++i) {
          let comp = this.$refs.childrenNodes[i]
          let point = comp.getAnchorPoint()
          this.childAnchorPoints.push({
            x: svgRect.width,
            y: point.top - svgRect.top
          })
        }
      })
      
      if (this.$el && this.$el.firstChild) {
        this.childrenOffsetX = this.$el.firstChild.getBoundingClientRect().width / 2.0
      }
    }
  },

  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.childrenNodes) {
        this.doResize()
        clearInterval(interval)
      }
    }, 50)
    this.$emit('bind', { node: this.node, component: this })
  },

  destroyed() {
    this.$emit('unbind', this.node)
  }
}
</script>
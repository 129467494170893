import { BasePlugin } from '@uppy/core'

class UploadTokenAuthPlugin extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts)

    this.id = opts.id || 'UploadTokenAuth'
    this.type = 'authentication'
    this.Parse = opts.Parse
    this.note = opts.note
    this.callbacks = opts.callbacks
  }

  async prepareUpload(fileIDs) {
    try {
      if (this.note.isNew()) {
        await this.note.save()
      }

      let Attachment = this.Parse.Object.extend('Attachment')
      let attachments = fileIDs.map(id => {
        let file = this.uppy.getFile(id)
        let attachment = new Attachment()
        attachment.set('note', this.note)
        attachment.set('name', file.name)
        attachment.set('type', file.type)
        attachment.set('size', file.size)
        return attachment
      })
      
      await this.Parse.Object.saveAll(attachments)

      // store upload token temporary
      fileIDs.forEach((fileId, index) => {
        console.log(attachments[index])
        this.uppy.setFileMeta(fileId, {
          token: attachments[index].get('token')
        })
      })
    } catch(error) {
      console.error(error)
    }
  }

  async postUploadProcessor() {
    // todo something?
    // console.log(fileIDs)
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload.bind(this))
    this.uppy.addPostProcessor(this.postUploadProcessor.bind(this))
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload.bind(this))
    this.uppy.removePostProcessor(this.postUploadProcessor.bind(this))
  }
}

export default UploadTokenAuthPlugin

<template>  
  <div class="w-full h-full flex flex-col overflow-auto">
    <block-editor
      ref="editor"
      :config="config"
      :initialized="onInitialized"
    />
  </div>
</template>

<script>
import BlockEditor from '@/components/blockeditor/index.js'
import domtoimage from 'dom-to-image-more'
import mixin from '../mixin'

export default {
  name: 'DocumentEditor',

  mixins: [ mixin ],

  components: {
    BlockEditor
  },

  data() {
    return {
      config: {}
    };
  },
  methods: {
    onInitialized (editor) {
      console.log(editor)
    },

    async save() {
      try {
        let data = await this.$refs.editor.editor.save()
        console.log(data)
        this.$emit('save', {
          data,
          thumbnail: await this.generateThumbnail()
        })
      } catch(error) {
        console.error(error)
      }
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('')
        domtoimage.toPNG(el, {
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
    }
  }
};
</script>

<template>
  <div
    :id="filter.key"
    :class="[
      'flex hover:border-gray-100 border',
      standalone ? 'flex-col' : 'flex-row items-center'
    ]">
    <template v-if="filter.type == 'boolean'">
      <div class="flex flex-row py-2 items-center px-2">
        <span class="grow">{{ filter.name }}</span>
        <input type="checkbox" :value="filter.value" >
      </div>
    </template>
    <template v-else>
      <div class="flex flex-row py-2 items-center px-2">
        <span class="grow">{{ filter.name }}</span>
        <template v-if="standalone">
          <input type="checkbox" :value="filter.enabled" >
        </template>
      </div>
      <div class="flex flex-col px-2 py-1">
        <template v-if="filter.type == 'enum'">
          <v-select
            dense
            outlined
            hide-details
            v-model="filter.value"
            class="mr-2"
            :items="filter.enum">
          </v-select>
        </template>
        <template v-else-if="filter.type == 'number'">
          <input tabindex="0" type="range" :min="filter.min" :max="filter.max" :step="filter.step" :value="filter.value" >
        </template>
        <template v-else-if="filter.type == 'boolean'">
          <input type="checkbox" :value="filter.value" >
        </template>
        <template v-else-if="filter.type == 'color'">
          <input type="text" :value="filter.value" >
        </template>
        <template v-else-if="filter.type == 'group'">
          <template v-for="option in filter.options">
              <image-filter-edit
                :key="option.key"
                :standalone="false"
                :filter="option">
              </image-filter-edit>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ImageFilterEdit',

  props: {
    filter: {
      type: Object,
      required: true
    },
    standalone: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
    }
  }

}
</script>
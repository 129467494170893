<template>
  <div
    class="h-full w-full relative">
    <template v-for="el in elements">
      <mockup-element
        :key="el.id"
        :id="`mockup-element-${el.id}`"
        :element="el"
        @select="selectElement"
        @edit="editElement"
        @bind="bindElement">
      </mockup-element> 
    </template>
  </div>
</template>

<script>
import MockupElement from './Element'

export default {
  name: 'MockupPage',

  components: {
    MockupElement
  },

  props: {
    page: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  computed: {
    elements() {
      return this.page ? Object.values(this.page.elements) : []
    }
  },

  methods: {
    selectElement(element, el, event) {
      this.$emit('select-element', element, el, event)
    },
    
    bindElement(element, el) {
      this.$emit('bind-element', element, el)
    },

    editElement(event) {
      this.$emit('edit-element', event)
    }
  },

  mounted() {
    this.$emit('bind-page', this)
  },
}
</script>
<template>
  <div class="relative flex items-center w-full h-full overflow-hidden">
    <div class="w-full h-full overflow-auto">
      <div
        class="mindmap-canvas grid grid-cols-1 items-center place-items-center px-8 py-8 top-0 left-0"
        style="min-width: 3000px;min-height: 3000px; width: 3000px; height: 3000px;">
        <component
          :is="activeLayout"
          :node="mindmap.root"
          :level="1"
          :direction="'left'"
          :style-config="styleConfig"
          @select="selectNode"
          @create="onNewNode"
          @remove="onRemoveNode"
          @navigation="onNavigationNode"
          @bind="bindNode"
          @unbind="unbindNode">
        </component>
      </div>
    </div>

    <div class="absolute flex flex-row w-full h-8 bg-transparent top-0 left-0 p-2">
      <v-select
        :items="layouts"
        item-text="text"
        item-value="key"
        v-model="activeLayout"
        dense
        filled
        rounded
        hide-details
        class="max-w-48 bg-g"
      ></v-select>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { nanoid } from 'nanoid'
import domtoimage from 'dom-to-image-more'
import MindMapNode from './MindMapNode.vue'
import LogicNode from './LogicNode.vue'
import OrganizationNode from './OrganizationNode.vue'
import TreeNode from './TreeNode.vue'
import UndoHistory from '../undo'

import mixin from '../mixin'

export default {
  name: 'MindMapEditor',

  mixins: [ mixin ],

  components: {
    'mindmap': MindMapNode,
    'logic': LogicNode,
    'organization': OrganizationNode,
    'tree': TreeNode
  },

  data() {
    return {
      selectedNodes: [],
      styleConfig: {
        lineColor: 'gray',
        lineWidth: '2px',
        primaryFillColor: 'pink',
        primaryTextColor: 'white',
        secondaryFillColor: 'purple',
        secondaryTextColor: 'white',
        shapeStyle: 'rounded',
        lineStyle: 'curve'
      },
      mindmap: this.getInitData(),
      nodesMap: {},
      nodesRelation: {},
      nodesBind: {},
      layouts: [
        { text: 'Mindmap layout', key: 'mindmap' },
        { text: 'Organization layout', key: 'organization' },
        { text: 'Logic layout', key: 'logic' }
      ],
      activeLayout: 'mindmap'
    }
  },

  methods: {
    getInitData() {
      let data = null
      if (this.note && this.note.data)  {
        data = JSON.parse(this.note.data)
      } else {
        data = {
          layout: 'mindmap',
          root: {
            id: `mindmap-${nanoid()}`,
            title: 'New topic',
            children: []
          },
          theme: 'classic'
        }
      }
      console.log(data)
      return data
    },

    selectNode(component) {
      let existed = _.findIndex(this.selectedNodes, (r) => r.node.id == component.node.id) > -1
      if (!existed) {
        this.selectedNodes.forEach(r => {
          r.selected = false
        })
        this.selectedNodes.splice(0, this.selectedNodes.length)
        this.selectedNodes.push(component)
        component.selected = true
      }
    },

    onNewNode(args) {
      let { node, type } = args
      let newNode = {
        id: `mindmap-${nanoid()}`,
        title: 'New topic',
        children: []
      }
      let parentNode = null
      let parentComponent = null
      let index = -1
      if (type == 'child') {
        // insert into the last position
        parentNode = this.nodesMap[node.id]
        parentComponent = this.nodesBind[node.id]
        index = parentNode.children.length - 1
      } else {
        parentNode = this.nodesRelation[node.id]
        if (!parentNode) {
          console.log('Unable to add sibling to root node')
          return
        }
        parentComponent = this.nodesBind[parentNode.id]
        index = _.findIndex(parentNode.children, c => c.id == node.id)
      }
      if (!parentNode || !parentComponent) {
        console.log('No valid parent node')
        return
      }

      index = index < 0 ? 0 : index
      this.activeUndoHistory.push({
        redo: () => {
          parentNode.children.splice(index, 0, newNode)
          this.nodesMap[newNode.id] = newNode
          this.nodesRelation[newNode.id] = parentNode
          this.relayout(parentNode, parentComponent)
        },
        undo: () => {
          parentNode.children.splice(index, 1)
          delete this.nodesMap[newNode.id]
          delete this.nodesRelation[newNode.id]
          this.relayout(parentNode, parentComponent)
        }
      })
      
    },

    onRemoveNode({ node }) {
      let parentNode = _.get(this.nodesRelation, node.id, null) 
      if (!parentNode) {
        console.log('Unable to remove the root node')
        return
      }
      let component = _.get(this.nodesBind, parentNode.id)
      let index = _.findIndex(parentNode.children, c => c.id == node.id)
      if (index < 0 || !component) {
        console.log('Non valid parent node')
        return
      }
      this.activeUndoHistory.push({
        redo: () => {
          parentNode.children.splice(index, 1)
          delete this.nodesMap[node.id]
          delete this.nodesRelation[node.id]
          this.relayout(parentNode, component)
        },

        undo: () => {
          parentNode.children.splice(index, 0, node)
          this.nodesMap[node.id] = node
          this.nodesRelation[node.id] = node
          this.relayout(parentNode, component)
        }
      })
    },

    onNavigationNode(event) {
      console.log(event)
      // let { node, direction, component } = event
      // switch(direction) {
      //   case 'left':
      //   case 'right':
      //   case 'up':
      //   case 'down':
      //   default:
      //     break
      // }
    },

    buildNodesMap() {
      let loopNode = (node) => {
        node.children.forEach(n => {
          loopNode(n)
          this.nodesRelation[n.id] = node
        })
        this.nodesMap[node.id] = node
      }

      loopNode(this.mindmap.root)
    },

    bindNode({ node, component }) {
      this.nodesBind[node.id] = component
    },

    unbindNode(node) {
      if (this.nodesBind[node.id]) {
        delete this.nodesBind[node.id]
      }
    },

    relayout(node, component) {
      this.$nextTick(() => {
        let currentNode = node
        let currentComponent = component
        while (currentNode && currentComponent) {
          currentComponent.doResize()
          // goto prev parent
          currentNode = _.get(this.nodesRelation, currentNode.id, null)
          if (currentNode) {
            currentComponent = _.get(this.nodesBind, currentNode.id, null)
          }
        }
      })
    },

    async save() {
      this.$emit('save', {
        data: JSON.stringify(this.mindmap),
        thumbnail: await this.generateThumbnail()
      })
    },

    async generateThumbnail() {
      try {
        let el = this.$el.querySelector('.mindmap-canvas').firstChild
        return await domtoimage.toPNG(el, {
          width: 300,
          height: 200
        })
      } catch(error) {
        console.error(error)
      }
      return ''
    }
  },

  mounted() {
    let interval = setInterval(() => {
      if (this.$el) {
        let rootEl = this.$el.querySelector('.mindmap-node-root')
        if (rootEl) {
          rootEl.scrollIntoView({
            block: 'center',
            inline: 'center'
          })
        }
        clearInterval(interval)
      }
    }, 50)

    this.buildNodesMap()
    this.activeUndoHistory = new UndoHistory()
    this.activeUndoHistory.on('change', () => { this.$emit('change') })
  }
}
</script>

<style scoped>

.mindmap-canvas {
  background: linear-gradient(90deg, white 21px, transparent 1%) center, linear-gradient(white 21px, transparent 1%) center, black;
  background-size: 22px 22px;
}

</style>
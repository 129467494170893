<template>
  <div
    class="selection-box absolute border border-red-600 border-box bg-transparent" 
    :style="boxStyles"
    @click.stop.prevent="onClick"
    @dblclick.stop.prevent="onDoubleClick"
    @mousedown.stop.prevent="onMouseDown">
    <div v-for="(dragger, index) in draggers"
      :key="index"
      :data-pos="dragger.pos"
      :class="[
        'selection-box-dragger bg-white border absolute border-gray-400 w-2 h-2 hover:bg-blue-400 hover:border-0',
        dragger.cursor,
        `${dragger.pos}`
      ]"
      @mousedown.stop.prevent="onDraggerMouseDown(dragger, $event)">
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// import interact from 'interactjs'

const Draggers = {
  left: { pos: 'left', cursor: 'cursor-ew-resize' },
  right: { pos: 'right', cursor: 'cursor-ew-resize' },
  top: { pos: 'top', cursor: 'cursor-ns-resize' },
  bottom: { pos: 'bottom', cursor: 'cursor-ns-resize' },
  topLeft: { pos: 'topLeft', cursor: 'cursor-nwse-resize' },
  topRight: { pos: 'topRight', cursor: 'cursor-nesw-resize' },
  bottomLeft: { pos: 'bottomLeft', cursor: 'cursor-nesw-resize' },
  bottomRight: { pos: 'bottomRight', cursor: 'cursor-nwse-resize' },
}

const DraggerResizePolicies = {
  topLeft: [-1, -1],
  top: [0, -1],
  topRight: [1, -1],
  left: [-1, 0],
  right: [1, 0],
  bottomLeft: [-1, 1],
  bottom: [0, 1],
  bottomRight: [1, 1]
}

const DraggerMovePolicies = {
  topLeft: [1, 1],
  top: [0, 1],
  topRight: [0, 1],
  left: [1, 0],
  right: [0, 0],
  bottomLeft: [1, 0],
  bottom: [0, 0],
  bottomRight: [0, 0]
}

export default {
  name: 'SelectionBox',

  props: {
    selectedElements: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      show: false,
      moveStartPos: null,
      moveStartThreshold: 16,
      elementsMoveStartPosList: null,
      selectionBoxMoveStartPos: null,
      // dragger
      activeDragger: null,
      resizeStartPos: null,
      elementsResizeStartSizeList: null,

      resize: {
        directions: {
          horizontal: true,
          vertical: true
        }
      },

      resizeStartGeom: {
        top: 0,
        left: 0,
        width: 0,
        height: 0
      },

      geom: {
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    }
  },

  computed: {
    draggers() {
      let directions = this.resize.directions
      if (directions.horizontal && directions.vertical) {
        return Object.values(Draggers)
      } else {
        if (directions.horizontal) {
          return [
            Draggers.left,
            Draggers.right
          ]
        } else if (directions.vertical) {
          return [
            Draggers.top,
            Draggers.bottom
          ]
        } else {
          return []
        }
      }
    },

    boxStyles() {
      return {
        top: `${this.geom.top}px`,
        left: `${this.geom.left}px`,
        width: `${this.geom.width}px`,
        height: `${this.geom.height}px`,
        display: this.selectedElementsIds.length == 0 ? 'none' : 'block'
      }
    },

    selectedElementsIds() {
      return this.selectedElements.map(r => r.element.id)
    }
  },

  methods: {
    relocate() {
      let parentRect = this.$parent.$el.getBoundingClientRect()
      let rects = this.selectedElements.map(v => v.el.$el.getBoundingClientRect())
      let geom = {
        top: Math.min(...(rects.map(r => r.top ))),
        left: Math.min(...(rects.map(r => r.left ))),
        right: Math.max(...(rects.map(r => r.right ))),
        bottom: Math.max(...(rects.map(r => r.bottom )))
      }

      this.geom.top = geom.top - parentRect.top + this.$parent.$el.scrollTop
      this.geom.left = geom.left - parentRect.left + this.$parent.$el.scrollLeft
      this.geom.width = geom.right - geom.left
      this.geom.height = geom.bottom - geom.top
    },

    onMouseDown(event) {
      this.moveStartPos = {
        clientX: event.clientX,
        clientY: event.clientY
      }
      this.elementsMoveStartPosList = this.selectedElements.map(r => {
        return {
          elementId: r.element.id,
          pos: {
            x: r.element.geom.x,
            y: r.element.geom.y
          }
        }
      })

      this.selectionBoxMoveStartPos = {
        left: this.geom.left,
        top: this.geom.top
      }
      this.$emit('start-move-elements')
      document.addEventListener('mousemove', this.handleSelectionMove, true)
      document.addEventListener('mouseup', this.handleSelectionMoveStop, true)
    },

    handleSelectionMove(event) {
      event.preventDefault()
      event.stopPropagation()

      let dx = event.clientX - this.moveStartPos.clientX
      let dy = event.clientY - this.moveStartPos.clientY
      this.$emit('move-elements', this.elementsMoveStartPosList.map(r => {
        return {
          elementId: r.elementId,
          pos: {
            x: r.pos.x + dx,
            y: r.pos.y + dy
          }
        }
      }))
      this.geom.top = this.selectionBoxMoveStartPos.top + dy
      this.geom.left = this.selectionBoxMoveStartPos.left + dx
    },

    handleSelectionMoveStop(event) {
      event.stopPropagation()
      event.preventDefault()
      document.removeEventListener('mousemove', this.handleSelectionMove, true)
      document.removeEventListener('mouseup', this.handleSelectionMoveStop, true)
      this.elementsMoveStartPosList = null
      this.selectionBoxMoveStartPos = null
      this.$emit('stop-move-elements')
    },

    onDraggerMouseDown(dragger, event) {
      this.activeDragger = dragger
      this.resizeStartPos = {
        clientX: event.clientX,
        clientY: event.clientY
      }
      this.elementsResizeStartSizeList = this.selectedElements.map(r => {
        return {
          elementId: r.element.id,
          geom: {
            left: r.element.geom.x,
            top: r.element.geom.y,
            width: r.element.geom.w,
            height: r.element.geom.h
          }
        }
      })
      this.resizeStartGeom = {
        left: this.geom.left,
        top: this.geom.top,
        width: this.geom.width,
        height: this.geom.height
      }

      this.$emit('start-resize-elements')
      document.addEventListener('mousemove', this.handleDraggerMove, true)
      document.addEventListener('mouseup', this.handleDraggerMoveStop, true)
    },

    handleDraggerMove(event) {
      event.preventDefault()
      event.stopPropagation()

      let dx = event.clientX - this.resizeStartPos.clientX
      let dy = event.clientY - this.resizeStartPos.clientY

      let resizePolicy = DraggerResizePolicies[this.activeDragger.pos]
      let dw = dx * resizePolicy[0]
      let dh = dy * resizePolicy[1]

      // let wratio = (this.resizeStartGeom.width + dw) / this.resizeStartGeom.width
      // let hratio = (this.resizeStartGeom.height + dh) / this.resizeStartGeom.height

      let movePolicy = DraggerMovePolicies[this.activeDragger.pos]
      this.$emit('resize-elements', this.elementsResizeStartSizeList.map(record => {
        let { elementId, geom } = record
        return {
          elementId,
          geom: {
            x: geom.left + movePolicy[0] * dx,
            y: geom.top + movePolicy[1] * dy,
            w: geom.width + dw,
            h: geom.height + dh
          }
        }
      }))

      this.geom.left = this.resizeStartGeom.left + movePolicy[0] * dx
      this.geom.top = this.resizeStartGeom.top + movePolicy[1] * dy
      this.geom.width = this.resizeStartGeom.width + dw
      this.geom.height = this.resizeStartGeom.height + dh
    },

    handleDraggerMoveStop(event) {
      event.stopPropagation()
      event.preventDefault()
      document.removeEventListener('mousemove', this.handleDraggerMove, true)
      document.removeEventListener('mouseup', this.handleDraggerMoveStop, true)
      this.activeDragger = null
      this.resizeStartPos = null
      this.resizeStartGeom = null
      this.elementsResizeStartSizeList = []
      this.$emit('stop-resize-elements')
    },

    onClick(event) {
      // first, hide the current selection box, make it invisible to
      // the double click event
      this.$el.style.visibility = 'hidden'

      // simuate the double click event, send it to the underlying
      // dom element
      const el = document.elementFromPoint(event.clientX, event.clientY)
      if (el) {
        this.$dom.triggerEvent(el, 'click')
      }

      // reset the selection box to be visible
      this.$el.style.visibility = 'visible'
    },

    /**
     * Handle double click event
     */
    onDoubleClick: function (event) {
      // first, hide the current selection box, make it invisible to
      // the double click event
      this.$el.style.visibility = 'hidden'

      // simuate the double click event, send it to the underlying
      // dom element
      const el = document.elementFromPoint(event.clientX, event.clientY)
      if (el) {
        this.$dom.triggerEvent(el, 'dblclick')
      }

      // reset the selection box to be visible
      this.$el.style.visibility = 'visible'
    }
  },

  watch: {
    selectedElementsIds: {
      immediate: true,
      handler: function(newValue) {
        if (_.isArray(newValue) && newValue.length > 0) {
          this.show = true
          this.relocate()
        } else {
          this.show = false
        }
      }
    }
  }
}
</script>

<style scoped>

div.selection-box-dragger.topLeft {
  top: -4px;
  left: -4px;
}

div.selection-box-dragger.top {
  top: -4px;
  left: 50%;
  margin-left: -4px;
}

div.selection-box-dragger.topRight {
  top: -4px;
  right: -4px;
}

div.selection-box-dragger.left {
  top: 50%;
  left: -4px;
  margin-top: -4px;
}

div.selection-box-dragger.right {
  top: 50%;
  right: -4px;
  margin-top: -4px;
}

div.selection-box-dragger.bottomLeft {
  bottom: -4px;
  left: -4px;
}

div.selection-box-dragger.bottom {
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
}

div.selection-box-dragger.bottomRight {
  bottom: -4px;
  right: -4px;
}

.cursor-ew-resize	{
  cursor: ew-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

</style>
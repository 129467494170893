<template>
  <div
    class="flex flex-col w-full h-full"
    @click.stop.prevent="focusOnEditor">
    <prism-editor
      ref="codeEditor"
      class="my-codeblock"
      v-model="code"
      :highlight="highlighter"
      line-numbers
      @input="onChange">
    </prism-editor>
  </div>
</template>

<script>
  // import Prism Editor
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

  import domtoimage from "dom-to-image-more";

  export default {
    name: 'CodeBlock',

    props: {
      block: {
        type: Object,
        required: true
      }
    },

    components: {
      PrismEditor
    },
    
    data() {
      return {
        code: this.block.content
      }
    },

    methods: {
      highlighter(code) {
        return highlight(code, languages.js) 
        // languages.<insert language> to return html with markup
      },

      focusOnEditor() {
        let el = this.$el.querySelector('.prism-editor__textarea')
        el && el.focus()
      },

      onChange() {
        this.$emit('change')
      },

      redo() {
        this.$refs.codeEditor._redoEdit()
      },

      undo() {
        this.$refs.codeEditor._undoEdit()
      },

      async save() {
        this.$emit('save', {
          data: this.code,
          thumbnail: await this.generateThumbnail()
        })
      },

      async generateThumbnail() {
        try {
          return await domtoimage.toPNG(this.$el, {
            width: 300,
            height: 200
          })
        } catch(error) {
          console.error(error)
        }
        return ''
      }
    }
  }
</script>

<style>

  /* required class */
  .my-codeblock {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }

</style>
<template>
  <div
    :class="[
      'flex flex-col flex-nowrap shrink place-content-center items-center', 
      level == 1 ? 'mindmap-node-root' : ''
    ]">
    <div
      ref="root"
      :class="[
        'relative flex flex-col shrink rounded-full cursor-pointer hover:shadow-xl',
        level == 1 ? 'text-lg py-2' : '',
        level == 2 ? 'py-1': '',
        level > 2 ? 'text-sm py-0 border-b' : ''
      ]"
      :style="{
        textColor: level == 1 ? styleConfig.primaryTextColor : ( level == 2 ? styleConfig.secondaryTextColor: 'gray' ), 
        backgroundColor: level == 1 ? styleConfig.primaryFillColor : ( level == 2 ? styleConfig.secondaryFillColor: 'transparent' ) 
      }"
      tabindex=0
      @dblclick.stop.prevent="onDoubleClick"
      @click.stop.prevent="onClick"
      @keydown="onKeyDown">
      <div class="flex flex-row space-x-1 items-center">
        <div
          class="font-bold mx-8 py-1">
          {{ node.title }}
        </div>
        <div
          v-if="editing"
          class="absolute w-full h-full outline-none top-0"
          v-click-outside="onDoneEditing">
          <v-text-field
            v-model="node.title"
            dense
            filled
            rounded
            hide-details
            no-hints
            class="bg-white"
            @keydown.enter="onDoneEditing"
            @keydown.esc="onDoneEditing"
          ></v-text-field>
        </div>
      </div>
      <div
        v-if="selected"
        class="absolute border-2 border-blue-400 -top-1 -left-1 rounded"
        style="pointer-events: none;width: calc(100% + 8px); height: calc(100% + 8px)"
        >
      </div>
      <div
        v-if="node.children.length > 0"
        class="absolute"
        :style="{
          left: `calc(50% - ${ anchorSize / 2}px)`,
          top: '100%'
        }">
        <button
          class="rounded-full border bg-white text-gray-500 flex items-center place-content-center hover:bg-gray-200 shadow-md outline-none"
          @click.stop.prevent="collapsed = !collapsed"
          :style="{
            width: `${ anchorSize }px`,
            height: `${ anchorSize }px`,
          }"
          >
          <span v-if="collapsed">+</span>
          <span v-else>-</span>
        </button>
      </div>
    </div>
    <template
      v-if="node.children.length > 0 && !collapsed">
      <svg
        ref="lineSvg"
        preserveaspectratio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="`${ childrenWidth }px`"
        height="48px">
        <template v-for="(seg, index) in segments">
          <path
            :key="`seg-${index}`"
            :d="`M${seg.x1} ${seg.y1} L${seg.x2} ${seg.y2}`"
            stroke="#C5CCD0"
            stroke-width="2px"/>
        </template>
      </svg>
      <div
        ref="childrenHolder"
        class="flex flex-row flex-nowrap shrink-0 space-x-8 items-start">
        <organization-node
          v-for="child in node.children"
          :key="child.id"
          :node="child"
          :level="level+1"
          :style-config="styleConfig"
          @select="$emit('select', $event)"
          @create="$emit('create', $event)"
          @remove="$emit('remove', $event)"
          @navigate="$emit('navigate', $event)"
          @bind="$emit('bind', $event)"
          @unbind="$emit('unbind', $event)"
          ref="childrenNodes"
          >
        </organization-node>
      </div>
    </template>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'OrganizationNode',

  mixins: [ mixin ],

  data() {
    return {
      fromPoint: { x: 0, y: 0 },
      childrenWidth: 0
    }
  },

  computed: {
    segments() {
      let segs = []
      if (this.childAnchorPoints.length > 0) {
        let midpoint = {
          x: this.fromPoint.x,
          y: (this.fromPoint.y + this.childAnchorPoints[0].y) / 2.0
        }

        segs.push({
          x1: this.fromPoint.x,
          y1: this.fromPoint.y,
          x2: midpoint.x,
          y2: midpoint.y
        })

        segs.push({
          x1: this.childAnchorPoints[this.childAnchorPoints.length - 1].x,
          y1: midpoint.y,
          x2: this.childAnchorPoints[0].x,
          y2: midpoint.y
        })

        this.childAnchorPoints.forEach(p => {
          segs.push({
            x1: p.x,
            y1: midpoint.y,
            x2: p.x,
            y2: p.y
          })
        })
      }
      return segs
    }
  },

  methods: {
    getAnchorPoint() {
      let rect = this.$el.getBoundingClientRect()
      return {
        top: rect.top,
        left: rect.left + rect.width / 2.0
      }
    },

    doResize() {
      this.childrenWidth = this.$refs.childrenHolder.getBoundingClientRect().width
      this.$nextTick(() => {
        let svgRect = this.$refs.lineSvg.getBoundingClientRect()

        // the start point
        this.fromPoint.x = svgRect.width / 2.0;
        this.fromPoint.y = 0

        // the child anchor points
        this.childAnchorPoints.splice(0, this.childAnchorPoints.length)
        for (let i = 0; i < this.$refs.childrenNodes.length; ++i) {
          let comp = this.$refs.childrenNodes[i]
          let point = comp.getAnchorPoint()
          this.childAnchorPoints.push({
            x: point.left - svgRect.left,
            y: svgRect.height
          })
        }
      })
    }
  },

  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.childrenHolder && this.$refs.root) {
        this.doResize()
        clearInterval(interval)
      }
    }, 50)
    this.$emit('bind', { node: this.node, component: this })
  },

  destroyed() {
    this.$emit('unbind', this.node)
  }
}
</script>
export default {
  props: {
    node: {
      type: Object,
      required: true
    },

    level: {
      type: Number,
      required: true
    },

    styleConfig: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      selected: false,
      collapsed: false,
      anchorSize: 20,
      childAnchorPoints: [],
      editing: false
    }
  },

  computed: {
    showCollapseButton() {
      if (this.level == 1) {
        return false
      }
      return true
    }
  },

  methods: {
    onClick() {
      this.selected = true
      this.$emit('select', this)
    },

    onDoubleClick() {
      this.editing = true
      this.$nextTick(() => {
        let input = this.$el.querySelector('input')
        if (input) {
          input.focus()
          input.select()
        }
      })
    },

    onDoneEditing() {
      this.editing = false
    },

    onKeyDown(event) {
      if (this.editing) {
        return
      }
      event.preventDefault()
      event.stopPropagation()
      switch(event.code) {
        case 'Delete': 
        case 'Backspace':
          console.log('remove key press')
          this.$emit('remove', { node: this.node, component: this })
          break

        case 'Enter':
          if (event.ctrlKey) {
            this.$emit('create', { node: this.node, type: 'child', component: this })
          } else {
            this.$emit('create', { node: this.node, type: 'sibling', component: this })
          }
          break

        case 'ArrowLeft':
          this.$emit('navigation', { node: this.node, direction: 'left', component: this })
          break

        case 'ArrowRight':
          this.$emit('navigation', { node: this.node, direction: 'right', component: this })
          break

        case 'ArrowUp':
          this.$emit('navigation', { node: this.node, direction: 'up', component: this })
          break

        case 'ArrowDown':
          this.$emit('navigation', { node: this.node, direction: 'down', component: this })
          break

        default:
          break
      }
    }
  }
}
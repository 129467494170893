<template>
  <div
    v-show="rubberBand.active"
    class="absolute bg-blue-400/20"
    :style="boxStyles" />
</template>

<script>

export default {
  name: 'RubberBand',

  props: {
    rubberBand: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  
  computed: {
    boxStyles () {
      let area = this.rubberBand.rect
      return {
        top: `${area.top}px`,
        left: `${area.left}px`,
        width: `${area.width}px`,
        height: `${area.height}px`
      }
    }
  }
}
</script>
